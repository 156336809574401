import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentNode } from 'graphql';
import { useNotificationBanner } from './useNotification';

export const useQueryHook = ({
  errorMessage,
  successMessage,
  queryGql,
  options,
}: {
  queryGql: DocumentNode;
  options?: any;
  successMessage?: string;
  errorMessage?: string;
}): any => {
  const { t } = useTranslation();
  const { dispatchNotificationBanner } = useNotificationBanner();
  const { loading, error, data, startPolling, stopPolling, refetch } = useQuery(
    queryGql,
    options
  );

  useEffect(() => {
    if (error) {
      dispatchNotificationBanner({
        text: t(errorMessage || 'errorGenericMessage'),
        ok: false,
        title: t(errorMessage || 'errorGenericTitle'),
      });
    }
    if (successMessage && data) {
      dispatchNotificationBanner({
        text: t(successMessage),
        ok: true,
        title: t('operationSuccessfully'),
      });
    }
    // eslint-disable-next-line
  }, [error, data]);

  return { data, loading, error, startPolling, stopPolling, refetch };
};
