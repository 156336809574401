import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { KeycloakService } from '../services/KeycloakService';
import { env } from '../index';
import { SentryLink } from 'apollo-link-sentry';

let client: ApolloClient<any> | null = null;

export const apolloClient = () => {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors || networkError) {
      // eslint-disable-next-line no-console
      console.error(graphQLErrors || networkError);
    }
  });

  const httpLink = createHttpLink({
    uri: env('REACT_APP_GQL_HTTP_URL') || `http://localhost:8080/graphql`,
    credentials: 'include',
  });

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${KeycloakService.getToken()}`,
    },
  }));

  const wsLink = new WebSocketLink({
    uri: env('REACT_APP_GQL_WS_URL') || 'ws://localhost:8080/graphql',
    options: {
      reconnect: true,
      connectionParams: () => {
        // if token is expired, refresh it
        return {
          Authorization: `Bearer ${KeycloakService.getToken()}`,
        };
      },
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(httpLink)
  );

  if (!client) {
    client = new ApolloClient({
      link: from([new SentryLink(), errorLink, splitLink]),
      connectToDevTools: env('REACT_APP_DEVTOOLS_APOLLO') || false,
      cache: new InMemoryCache({
        addTypename: false,
      }),
    });
  }
  return client;
};
