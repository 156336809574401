import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NewsPressList } from './List';
import { NewsPressDetails } from './Details';

export const NewsPress: FC = () => (
  <>
    <Routes>
      <Route path="" element={<NewsPressList isHighlight={false} />} />
      <Route path=":newsPressId" element={<NewsPressDetails />} />
    </Routes>
  </>
);
