import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentBookmarkColor } from '../../redux/filterChoices/actions';
import { BookmarkColor } from '@nwa/graphql';
import { GenericSelect } from '../GenericSelect';
import { filterChoicesSelector } from '../../redux/filterChoices/selectors';

export const BookmarkColorSelector: FC = () => {
  const dispatch = useDispatch();
  const { currentBookmarkColor, bookmarksList } = useSelector(
    filterChoicesSelector
  );
  const [currentValue, setCurrentValue] = useState<any>();
  useEffect(() => {
    bookmarksList.forEach((option: any) => {
      if (option.value === currentBookmarkColor) {
        setCurrentValue(option);
      }
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBookmarkColor]);
  return (
    <GenericSelect
      id="Bookmarks"
      onChange={(newValue) =>
        dispatch(
          setCurrentBookmarkColor(newValue?.value || BookmarkColor.ORANGE)
        )
      }
      options={bookmarksList}
      placeholder={''}
      value={currentValue}
      showSelectedOption={true}
      showSelectionCircle={false}
      showSelectedOptionIcon={true}
      showSelectedOptionLabel={false}
      disableBorder={true}
      menuRight={true}
    />
  );
};
