import { FunctionComponent } from 'react';
import { notificationSelector } from '../../redux/notification/selectors';
import { useSelector } from 'react-redux';
import { Notification } from '../../components/Notifications/Notification';

export const Notifications: FunctionComponent = () => {
  const { notifications } = useSelector(notificationSelector);
  return (
    <>
      {notifications.length > 0 && (
        <div
          className="flex flex-col-reverse fixed bottom-5 left-5"
          style={{ zIndex: 320 }}
        >
          {notifications.map((notification, index) => (
            <Notification
              {...notification}
              key={notification.id}
              index={index}
            />
          ))}
        </div>
      )}
    </>
  );
};
