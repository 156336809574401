import { Provider, ProviderMonitor } from '@nwa/graphql';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MONITOR } from '../../graphql/news/queries';
import { addLoading, removeLoading } from '../../redux/utils/actions';
import { Divider } from '../../svg';
import {
  getDateAsString,
  getHoursAndMinutesAsString,
  isToday,
  isYesterday,
} from '../../utils/dateUtils';
import { providers } from '../../utils/iconUtils';
import { firstLowerCaseGqlDefinition } from '../../utils/stringUtils';
import { useQueryHook } from '../hooks/useQueryHook';
import { NavigationBackComponent } from '../NavigationBack';
import { SvgIcon } from '../SvgIcon';
import { Text } from '../Text';

export const Monitor: FC = () => {
  const { data, loading, startPolling, stopPolling } = useQueryHook({
    queryGql: MONITOR,
    options: {
      fetchPolicy: 'network-only',
    },
  });

  useEffect(() => {
    startPolling(10000);

    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const [monitorData, setMonitorData] = useState<ProviderMonitor[]>([]);

  useEffect(() => {
    if (data) {
      let tmp: ProviderMonitor[] = [];
      const callData = data[
        firstLowerCaseGqlDefinition(MONITOR)
      ] as ProviderMonitor[];
      Object.values(Provider).forEach((provider) => {
        if (provider !== 'NONE') {
          const callDataI = callData.find((p) => p.provider === provider);
          if (callDataI) tmp.push(callDataI);
        }
      });
      setMonitorData(tmp);
    }
    // eslint-disable-next-line
  }, [data]);

  const dispatch = useDispatch();
  /*const [hasMore, setHasMore] = useState(false);

  const limit = 15;
  const { data, loading, fetchMore } = useListPress(
    true ? PressType.HIGHLIGHT : PressType.RELEASE,
    limit
  );

  useEffect(() => {
    setHasMore(data.length > 0 && data.length % limit === 0);
  }, [data]);*/

  useEffect(() => {
    if (loading) {
      dispatch(addLoading());
    } else {
      dispatch(removeLoading());
    }

    return () => {
      dispatch(removeLoading());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <>
      <div style={{ backgroundColor: '#FAFAFA' }} className="h-full">
        <div
          style={{ backgroundColor: '#FAFAFA', paddingBottom: '33px' }}
          className="flex flex-col h-screen w-full items-center justify-around"
        >
          <div
            className="h-12 w-full pl-3 py-2 mt-1 flex items-center justify-between bg-white"
            style={{ boxShadow: '2px 0px 8px rgba(0, 0, 0, 0.1)' }}
          >
            <div className="flex items-center">
              <NavigationBackComponent />
              <SvgIcon svg={<Divider className="m-1" />} />
              <Text
                text={'Monitor flussi provider'}
                className="ml-3 font-semibold"
              />
            </div>
          </div>
          <div className="flex w-full pt-2 px-56 mt-0.5 mb-4">
            <div
              className="flex w-full py-4 px-10 flex-row max-w-full rounded justify-between m-1 items-center"
              style={{
                color: '#9C9D9D',
              }}
            >
              <table width="100%">
                <tbody>
                  <tr>
                    <td width="10%" align="center">
                      &nbsp;
                    </td>
                    <td width="30%" align="center">
                      <Text text={'Agenzia'} />
                    </td>
                    <td width="30%" align="center">
                      <Text text={'Data ultima news in stream'} />
                    </td>
                    <td width="30%" align="center">
                      <Text text={'Orario ultima news in stream'} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-full px-48">
            <div
              className="w-full pb-1"
              style={{
                borderTop: '1px solid #DCDCDC',
              }}
            >
              &nbsp;
            </div>
          </div>
          <div className="flex h-full w-full py-1 px-56 rounded-xl flex-col scrollbar overflow-y-auto mt-0.5 mb-4">
            {monitorData.map((providerMonitor, i) => (
              <div
                className="flex py-4 px-10 flex-row max-w-full rounded justify-between m-1 bg-white items-center"
                style={{
                  border: '1px solid white',
                  color: '#9C9D9D',
                }}
              >
                <table width="100%">
                  <tbody>
                    <tr>
                      <td width="10%" align="center">
                        <SvgIcon svg={providers[providerMonitor.provider]} />
                      </td>
                      <td width="30%" align="center">
                        <Text
                          text={providerMonitor.provider}
                          skipTranslation={true}
                          style={{
                            fontSize: '1.125rem',
                            lineHeight: '1.75rem',
                          }}
                          className="italic"
                        />
                      </td>
                      <td width="30%" align="center">
                        <Text
                          text={getDateAsString(
                            providerMonitor.lastUpdate as any
                          )}
                          skipTranslation={true}
                          style={{
                            fontSize: '0.875rem',
                            lineHeight: '1.25rem',
                            color: isToday(providerMonitor.lastUpdate as any)
                              ? 'initial'
                              : isYesterday(providerMonitor.lastUpdate as any)
                              ? 'orange'
                              : 'red',
                          }}
                        />
                      </td>
                      <td width="30%" align="center">
                        <Text
                          text={getHoursAndMinutesAsString(
                            providerMonitor.lastUpdate as any
                          )}
                          skipTranslation={true}
                          style={{
                            fontSize: '0.875rem',
                            lineHeight: '1.25rem',
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
