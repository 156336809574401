import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_PRESSES } from '../../../graphql/news/mutation';
import {
  setHighlightSelection,
  setPressSelection,
} from '../../../redux/newsSelection/actions';
import { newsSelectionSelector } from '../../../redux/newsSelection/selectors';
import { addLoading, removeLoading } from '../../../redux/utils/actions';
import { Button } from '../../Button';
import { useMutationHook } from '../../hooks/useMutationHook';
import { useNotificationBanner } from '../../hooks/useNotification';
import Modal from '../../Modal';
import { Text } from '../../Text';

interface ModalDeletePressesProps {
  isHighlight: boolean;
  showDeleteModal: boolean;
  setShowDeleteModal: (bool: boolean) => void;
  idsToDelete: string[];
  setIdsToDelete: (ids: string[]) => void;
  refetch: (ids: string[]) => void;
}

export const ModalDeletePresses: FC<ModalDeletePressesProps> = ({
  isHighlight,
  showDeleteModal,
  setShowDeleteModal,
  idsToDelete,
  setIdsToDelete,
  refetch,
}) => {
  const dispatch = useDispatch();

  const [deletePressesMutation] = useMutationHook({
    queryGql: DELETE_PRESSES,
  });

  const {
    pressSelection,
    highlightSelection,
    currentHighlight,
    currentPressReview,
  } = useSelector(newsSelectionSelector);

  const { dispatchNotificationBanner } = useNotificationBanner();

  return (
    <>
      {showDeleteModal && (
        <Modal
          title={'Conferma eliminazione'}
          showCloseButton={true}
          onClickCloseButton={() => setShowDeleteModal(false)}
          footer={
            <>
              <Button
                color="secondary"
                onClick={() => setShowDeleteModal(false)}
              >
                <Text text="Annulla" />
              </Button>
              <Button
                color="danger"
                className="ml-4"
                onClick={() => {
                  if (
                    idsToDelete.includes(
                      isHighlight
                        ? currentHighlight!.summary.id
                        : currentPressReview!.summary.id
                    )
                  ) {
                    dispatchNotificationBanner({
                      title: 'Operazione non permessa',
                      text:
                        'Impossibile eliminare la ' +
                        (isHighlight ? 'rassegna' : 'raccolta') +
                        ' corrente',
                      ok: false,
                    });
                    setShowDeleteModal(false);
                    return;
                  }
                  dispatch(addLoading());
                  deletePressesMutation({
                    variables: {
                      ids: idsToDelete,
                    },
                  })
                    .then(() => {
                      refetch(idsToDelete);
                    })
                    .finally(() => {
                      setIdsToDelete([]);
                      isHighlight
                        ? dispatch(
                            setHighlightSelection(
                              highlightSelection.filter(
                                (id) => !idsToDelete.includes(id)
                              )
                            )
                          )
                        : dispatch(
                            setPressSelection(
                              pressSelection.filter(
                                (id) => !idsToDelete.includes(id)
                              )
                            )
                          );
                      setShowDeleteModal(false);
                      dispatch(removeLoading());
                    });
                }}
              >
                <Text text="Elimina" />
              </Button>
            </>
          }
        >
          <div className="flex flex-col" style={{ width: '400px' }}>
            <div className="flex mt-3">
              <Text
                text={
                  'Confermi di voler eliminare ' +
                  (idsToDelete.length > 1 ? 'gli elementi?' : "l'elemento?")
                }
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
