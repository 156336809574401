import { DateRange, Keyword } from '@nwa/graphql';
import { createAction } from '@reduxjs/toolkit';
import { NewsFilterStateInner } from './reducers';

enum Actions {
  SET_FILTER_TEXT = 'SET_FILTER_TEXT',
  SET_FILTER_KEYWORDS = 'SET_FILTER_KEYWORDS',
  SET_FILTER_PROVIDERS = 'SET_FILTER_PROVIDERS',
  SET_FILTER_CONTENT_TYPES = 'SET_FILTER_CONTENT_TYPES',
  SET_FILTER_DATE_RANGE = 'SET_FILTER_DATE_RANGE',
  SET_NEWS_FILTER = 'SET_NEWS_FILTER',
  BACKUP_NEWS_FILTER = 'BACKUP_NEWS_FILTER',
  REMOVE_BACKUP_NEWS_FILTER = 'REMOVE_BACKUP_NEWS_FILTER',
  RESTORE_BACKUP_NEWS_FILTER = 'RESTORE_BACKUP_NEWS_FILTER',
}

export const setFullSearchTextFilter = createAction<string>(
  Actions.SET_FILTER_TEXT
);

export const setExpressionsFilter = createAction<Keyword[]>(
  Actions.SET_FILTER_KEYWORDS
);

export const setProvidersFilter = createAction<string[]>(
  Actions.SET_FILTER_PROVIDERS
);

export const setContentTypesFilter = createAction<string[]>(
  Actions.SET_FILTER_CONTENT_TYPES
);

export const setDateRangeFilter = createAction<DateRange | undefined>(
  Actions.SET_FILTER_DATE_RANGE
);

export const setNewsFilter = createAction<NewsFilterStateInner>(
  Actions.SET_NEWS_FILTER
);

export const backupNewsFilter = createAction<string>(
  Actions.BACKUP_NEWS_FILTER
);

export const restoreBackupNewsFilter = createAction<string>(
  Actions.RESTORE_BACKUP_NEWS_FILTER
);

export const removeBackupNewsFilter = createAction<string>(
  Actions.REMOVE_BACKUP_NEWS_FILTER
);
