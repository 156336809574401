import { FC, useEffect, useMemo, useRef, useState } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { workingAreasSelector } from '../../redux/workingAreas/selectors';
import {
  removeDateRange,
  setWorkingAreaFilter,
  setSelectedWindow,
  setWindowCount,
} from '../../redux/workingAreas/actions';
import { NewsSummaryElement } from '../NewsSummaryElement';
import { SvgIcon } from '../SvgIcon';
import { NewWindowPlaceholder } from '../../svg';
import { Text } from '../Text';
import { useGetNews } from '../hooks/useGetNews';
import { BackToLive } from './BackToLive';
import {
  setNewsFilter,
  setDateRangeFilter,
} from '../../redux/newsFilter/actions';
import { filterChoicesSelector } from '../../redux/filterChoices/selectors';
import { LoadingTab } from './LoadingTab';
import InfiniteScroll from 'react-infinite-scroll-component';
import { WindowType } from '@nwa/graphql';
import { refreshPinState } from '../../redux/pins/actions';
import { newsFilterSelector } from '../../redux/newsFilter/selectors';
import { NewsDetailInnerComponent } from '../NewsDetailWrapper/NewsDetailInnerComponent';

interface TabProps {
  id: string;
}

export const Tab: FC<TabProps> = ({ id }) => {
  const { selectedWindow, dateRange, draftWorkingArea } =
    useSelector(workingAreasSelector);
  const { keywords } = useSelector(filterChoicesSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedWindow === id) {
      if (document.getElementsByClassName('active-panel').length > 0)
        document
          .getElementsByClassName('active-panel')[0]
          .classList.remove('active-panel');
      document
        .getElementById('container' + id)
        ?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.add(
          'active-panel'
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWindow]);

  const selected = useMemo(() => selectedWindow === id, [selectedWindow, id]);

  const windowType: WindowType =
    WindowType[
      draftWorkingArea?.windows.find((window) => window.id === id)?.value
        .type || WindowType.SEARCH
    ];

  const [showBackToTop, setShowBackToTop] = useState(false);
  const [updates, setUpdates] = useState(0);
  const limit = 50;

  const { data, count, loading, fetchMore, hasMore } = useGetNews(
    id,
    limit,
    !showBackToTop
  );

  useEffect(() => {
    if (typeof count != 'undefined') dispatch(setWindowCount({ id, count }));
  }, [count, dispatch, id]);

  const listenScrollEvent = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    setShowBackToTop(event.currentTarget.scrollTop > 0);
  };

  useEffect(() => {
    // mi serve per cambiare la key in modo da forzare un rerender
    // dopo aver inserito una news in rassegna/hl
    setUpdates((currentUpdates) => currentUpdates + 1);
  }, [data]);

  const ref = useRef<HTMLDivElement | null>(null);

  const filterSelector = useSelector(newsFilterSelector);

  const backToLive = (e: React.MouseEvent<HTMLElement>) => {
    if (filterSelector.dateRange) {
      dispatch(setDateRangeFilter(undefined));
      dispatch(removeDateRange());
      dispatch(
        setWorkingAreaFilter({ ...filterSelector, dateRange: undefined })
      );
    }
    ref.current?.scrollTo({ top: 0 });
    e.stopPropagation();
  };

  useEffect(() => {
    dispatch(refreshPinState());

    return () => {
      dispatch(refreshPinState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return windowType.valueOf() === WindowType.SEARCH ||
    windowType.valueOf() === WindowType.BOOKMARK ? (
    <Container
      onClick={() => {
        if (!selected) {
          const newWindow = draftWorkingArea?.windows.filter(
            (w) => w.id === id
          )[0];
          dispatch(
            setNewsFilter({
              dateRange: dateRange.filter((dr) => dr.id === id)[0]?.date,
              expressions: newWindow?.value.search?.keywords,
              providers: newWindow?.value.search?.providers || [],
              contentTypes: newWindow?.value.search?.contentTypes,
              fullSearchText:
                newWindow?.value.search?.fullSearchText || undefined,
              keywords:
                newWindow?.value.search?.keywords?.map(
                  (keywordExpression: string) =>
                    keywords.find((k) => keywordExpression === k.expression)!
                ) || [],
              fullSearchTextError: false,
            })
          );
          dispatch(setSelectedWindow(id));
        }
      }}
      selected={selected}
      className={`${selected ? 'scrollbar' : ''}`}
      onScroll={(event) => listenScrollEvent(event)}
      ref={ref}
      id={'container' + id}
    >
      {data.length > 0 ? (
        <InfiniteScroll
          dataLength={data.length} //This is important field to render the next data
          next={fetchMore}
          hasMore={hasMore}
          loader={
            <p className="text-center">
              <Text text="Caricamento" className="font-bold" />
              ...
            </p>
          }
          endMessage={
            <p className="text-center">
              <Text text="Fine risultati" className="font-bold" />
            </p>
          }
          scrollableTarget={'container' + id}
        >
          <>
            {data.map((news) => (
              <NewsSummaryElement
                key={news.id + id + updates}
                newsItem={news}
                selectedWindow={selected}
                tabId={id}
                html={false}
              />
            ))}
            <BackToLive show={showBackToTop} onClick={backToLive} />
          </>
        </InfiniteScroll>
      ) : (
        <>
          {!loading && (
            <div className="w-full h-full flex items-center justify-center">
              <div className="flex w-auto h-auto items-center justify-center flex-col">
                <SvgIcon
                  svg={
                    <NewWindowPlaceholder className="w-36 h-auto fill-gray-400" />
                  }
                />
                <Text
                  text="Utilizza la barra in alto per iniziare una nuova ricerca"
                  className="mt-2 text-gray-400"
                />
              </div>
            </div>
          )}
        </>
      )}
      <LoadingTab loading={loading} />
    </Container>
  ) : (
    <NewsDetailInnerComponent inPreviewWindow={true} />
  );
};

const Container = styled.div<{ selected: boolean }>`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: auto;
`;
