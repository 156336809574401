import { WindowType } from '@nwa/graphql';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { utilsSelector } from '../../redux/utils/selectors';
import { openNewWindow } from '../../redux/workingAreas/actions';
import { workingAreasSelector } from '../../redux/workingAreas/selectors';
import { Add, Eye } from '../../svg';
import { GenericSelect } from '../GenericSelect';
import { useNotificationBanner } from '../hooks/useNotification';

export const WindowSelector: FC = () => {
  const dispatch = useDispatch();
  const { draftWorkingArea } = useSelector(workingAreasSelector);
  const { inWorkingArea, isAWindowMaximized } = useSelector(utilsSelector);
  const { dispatchNotificationBanner } = useNotificationBanner();

  return (
    <GenericSelect
      id="Window"
      isDisabled={!inWorkingArea}
      options={[
        {
          label: 'Nuova finestra',
          value: '0',
          Icon: <Add className="fill-black" />,
          onClick: () =>
            isAWindowMaximized
              ? dispatchNotificationBanner({
                  title: 'Operazione non permessa',
                  text: 'Impossibile aggiungere finestre mentre una finestra è massimizzata',
                  ok: false,
                })
              : dispatch(openNewWindow(WindowType.SEARCH)),
        },
        {
          label: 'Nuova finestra di visualizzazione',
          value: '1',
          Icon: <Eye />,
          onClick: () =>
            isAWindowMaximized
              ? dispatchNotificationBanner({
                  title: 'Operazione non permessa',
                  text: 'Impossibile aggiungere finestre mentre una finestra è massimizzata',
                  ok: false,
                })
              : dispatch(openNewWindow(WindowType.PREVIEW)),
          isDisabled: !!draftWorkingArea?.windows.find(
            (window) => window.value.type === WindowType.PREVIEW
          ),
        },
      ]}
      showSelectionCircle={false}
      showSelectedOption={false}
      label={'finestre'}
      disableBorder={true}
      menuRight={true}
    />
  );
};
