import 'rc-dock/dist/rc-dock.css';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  setInWorkingArea,
  setShowFilterModalMobile,
} from '../../redux/utils/actions';
import {
  addNewWindow,
  setSelectedWindow,
} from '../../redux/workingAreas/actions';
import { workingAreasSelector } from '../../redux/workingAreas/selectors';
import { NewsSearchWithoutSuggestions } from '../NewsSearchWithoutSuggestions';
import { NewsDetailWrapper } from '../NewsDetailWrapper';
import './index.css';
import { NewsSearchModal } from '../WorkingArea/NewsSearchModal';
import { TabMobile } from './TabMobile';
import { newsFilterSelector } from '../../redux/newsFilter/selectors';
import { Filters } from '../../svg';
import { SvgIcon } from '../SvgIcon';
import { LogoutIcon } from '../Header/LogoutIcon';
import { FilterModalMobile } from './FilterModalMobile';

const id = uuidv4();

export const WorkingAreaMobile: FC = () => {
  const dispatch = useDispatch();
  const { draftWorkingArea } = useSelector(workingAreasSelector);

  useEffect(() => {
    if (!draftWorkingArea || draftWorkingArea.windows.length === 0) {
      dispatch(setSelectedWindow(id));
      dispatch(addNewWindow({ id }));
    }
    dispatch(setInWorkingArea(true));

    return () => {
      dispatch(setInWorkingArea(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const filterSelector = useSelector(newsFilterSelector);

  return (
    <div style={{ backgroundColor: '#FAFAFA' }}>
      {/*<div
      className="w-full h-[7vh] bg-white py-2 px-8 flex justify-between items-center z-10"
      style={{
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      }}
    >
      <SvgIcon svg={<Live />} pointer={true} onClick={() => {}} />
      <NewsSearchWithoutSuggestions
        isDisabled={filterSelector.fullSearchTextError}
      />
      <SvgIcon svg={<Filters />} pointer={true} onClick={() => {}} />
      <LogoutIcon />
    </div>*/}
      <div
        className="w-full h-[7vh] bg-white py-2 px-8 flex justify-between items-center z-10"
        style={{
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
      >
        <SvgIcon
          svg={<Filters />}
          pointer={true}
          onClick={() => {
            dispatch(setShowFilterModalMobile(true));
          }}
        />
        <NewsSearchWithoutSuggestions
          isDisabled={filterSelector.fullSearchTextError}
        />
        <LogoutIcon />
      </div>
      <div className="flex h-[93vh] w-full py-2 px-2 overflow-hidden">
        <TabMobile id={id} key={id} />
      </div>
      <NewsDetailWrapper />
      <NewsSearchModal />
      <FilterModalMobile />
    </div>
  );
};
