import { FC } from 'react';
import { Divider } from '../../svg';
import { SvgIcon } from '../SvgIcon';
import { AmbientSelector } from './AmbientSelector';
import { BookmarkSelector } from './BookmarkSelector';
import { Logo } from './Logo';
import { LogoutIcon } from './LogoutIcon';
import { MonitorIcon } from './MonitorIcon';
import { PressSelector } from './PressSelector';
import { TextSize } from './TextSize';
import { WindowSelector } from './WindowSelector';
import { WorkingAreaSelector } from './WorkingAreaSelector';

export const Header: FC = () => {
  return (
    <div className="h-12 w-full px-4 py-2 mt-1 inline-flex items-center justify-between">
      <div className="flex items-center">
        <Logo />
        <div className="inline-flex items-center space-x-1 ml-4">
          <SvgIcon svg={<Divider />} className="mr-2" />
          <WorkingAreaSelector />
          <SvgIcon svg={<Divider />} className="mr-1" />
          {false && <AmbientSelector />}
          <BookmarkSelector />
          <PressSelector />
          <WindowSelector />
        </div>
      </div>
      <div className="flex items-center space-x-1">
        <MonitorIcon />
        <TextSize />
        <LogoutIcon />
      </div>
    </div>
  );
};
