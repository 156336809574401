import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentNode } from 'graphql';
import { useNotificationBanner } from './useNotification';

export const useLazyQueryHook = ({
  errorMessage,
  successMessage,
  queryGql,
}: {
  queryGql: DocumentNode;
  successMessage?: string;
  errorMessage?: string;
}): any => {
  const { t } = useTranslation();
  const { dispatchNotificationBanner } = useNotificationBanner();
  const [
    lazyFunction,
    { loading, error, data, startPolling, stopPolling, refetch },
  ] = useLazyQuery(queryGql, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (error) {
      dispatchNotificationBanner({
        text: t(errorMessage || 'errorGenericMessage'),
        ok: false,
        title: t(errorMessage || 'errorGenericTitle'),
      });
    }
    if (successMessage && data) {
      dispatchNotificationBanner({
        text: t(successMessage),
        ok: true,
        title: t('operationSuccessfully'),
      });
    }
    // eslint-disable-next-line
  }, [error, data]);

  return [
    lazyFunction,
    { data, loading, error, startPolling, stopPolling, refetch },
  ];
};
