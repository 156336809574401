import { PressType, WindowType } from '@nwa/graphql';
import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { newsSelectionSelector } from '../../redux/newsSelection/selectors';
import { Text } from '../Text';
import { workingAreasSelector } from '../../redux/workingAreas/selectors';
import { NewsDetailInnerComponent } from './NewsDetailInnerComponent';
import Modal from '../Modal';
import { Button } from '../Button';
import {
  addNewsInHighlight,
  addNewsInPress,
  forceRefresh,
  setDetailNews,
} from '../../redux/newsSelection/actions';
import { ADD_NEWS_TO_CURRENT_PRESS } from '../../graphql/news/mutation';
import { useMutationHook } from '../hooks/useMutationHook';
import { utilsSelector } from '../../redux/utils/selectors';

export const NewsDetailWrapper: FC = () => {
  const { draftWorkingArea } = useSelector(workingAreasSelector);
  const { detailNews } = useSelector(newsSelectionSelector);
  const { textSize, inWorkingArea, isMobile } = useSelector(utilsSelector);
  const dispatch = useDispatch();
  const [addNewsToCurrentPressMutation] = useMutationHook({
    queryGql: ADD_NEWS_TO_CURRENT_PRESS,
  });

  const inPreviewWindow = useMemo(
    () =>
      !!draftWorkingArea?.windows.find(
        (window) => window.value.type === WindowType.PREVIEW
      ) && inWorkingArea,
    [draftWorkingArea, inWorkingArea]
  );

  const onClickAdd = (pressType: PressType) => {
    addNewsToCurrentPressMutation({
      variables: {
        to: pressType,
        newsId: [detailNews?.summary.id],
      },
    }).then(() => {
      dispatch(forceRefresh());
      if (pressType === PressType.RELEASE)
        dispatch(addNewsInPress([detailNews!.summary.id]));
      else if (pressType === PressType.HIGHLIGHT)
        dispatch(addNewsInHighlight([detailNews!.summary.id]));
      dispatch(setDetailNews(undefined));
    });
  };

  return detailNews && !inPreviewWindow ? (
    <Modal
      width={
        detailNews.imageLinks.length || detailNews.videoLinks.length
          ? 75
          : Math.min(
              75,
              Math.max(35, (75 * textSize * detailNews.content.length) / 1700)
            )
      }
      title={'Dettaglio News'}
      showCloseButton={true}
      onClickCloseButton={() => dispatch(setDetailNews(undefined))}
      footer={
        !isMobile && (
          <div className="flex items-center justify-end mr-4">
            <div className="flex items-center justify-end space-x-3">
              <Button
                color="secondary"
                onClick={() => onClickAdd(PressType.HIGHLIGHT)}
                isDisabled={detailNews.summary.inHighlight}
              >
                <Text text="Aggiungi alla rassegna" />
              </Button>
              <Button
                color="primary"
                onClick={() => onClickAdd(PressType.RELEASE)}
                isDisabled={detailNews.summary.inPressReview}
              >
                <Text text="Aggiungi alla raccolta" />
              </Button>
            </div>
          </div>
        )
      }
    >
      <NewsDetailInnerComponent inPreviewWindow={inPreviewWindow} />
    </Modal>
  ) : (
    <></>
  );
};
