import { FC } from 'react';
import { Link } from 'react-router-dom';
import { SvgIcon } from '../SvgIcon';
import { NWALogo, NewswireAggregator } from '../../svg';

export const Logo: FC = () => {
  return (
    <Link
      to={'/newswire'}
      style={{
        textDecoration: 'none',
        color: 'inherit',
      }}
    >
      <div className="flex flex-row">
        <SvgIcon svg={<NWALogo />} />
        <SvgIcon svg={<NewswireAggregator />} className="noSmallScreen p-1" />
      </div>
    </Link>
  );
};
