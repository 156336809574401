import { Environment, NewsFilter, Provider, SavedSearch } from '@nwa/graphql';

export const adapterEnvironmentToProviders = (
  savedSearch?: SavedSearch
): NewsFilter => {
  switch (savedSearch?.environment) {
    case Environment.GLOBALE_GENERALE:
      return {
        contentTypes: savedSearch?.contentTypes,
        expressions: savedSearch?.keywords,
        fullSearchText: savedSearch?.fullSearchText,
        providers:
          savedSearch.providers && savedSearch.providers.length > 0
            ? savedSearch.providers
            : [
                Provider.ADNKRONOS,
                Provider.AGI,
                Provider.ANSA,
                Provider.ASKANEWS,
                Provider.DOW_JONES,
                Provider.MF_DOW_JONES,
                Provider.DIRE,
                Provider.RADIOCOR,
                Provider.REUTERS,
                Provider.TELEBORSA,
              ],
      };
    case Environment.GLOBALE_AGENZIA:
      return {
        contentTypes: savedSearch?.contentTypes,
        expressions: savedSearch?.keywords,
        fullSearchText: savedSearch?.fullSearchText,
        providers:
          savedSearch.providers && savedSearch.providers.length > 0
            ? savedSearch.providers
            : [
                Provider.ADNKRONOS,
                Provider.AGI,
                Provider.ANSA,
                Provider.ASKANEWS,
                Provider.DOW_JONES,
                Provider.MF_DOW_JONES,
                Provider.DIRE,
                Provider.RADIOCOR,
                Provider.REUTERS,
                Provider.TELEBORSA,
              ],
      };
    case Environment.TV_RADIO:
      return {
        contentTypes: savedSearch?.contentTypes,
        expressions: savedSearch?.keywords,
        fullSearchText: savedSearch?.fullSearchText,
        providers:
          savedSearch.providers && savedSearch.providers.length > 0
            ? savedSearch.providers
            : [],
      };
    default:
      return {
        contentTypes: savedSearch?.contentTypes,
        expressions: savedSearch?.keywords,
        fullSearchText: savedSearch?.fullSearchText,
        providers:
          savedSearch?.providers && savedSearch.providers.length > 0
            ? savedSearch.providers
            : [],
      };
  }
};
