import { FC } from 'react';
import { useSelector } from 'react-redux';
import { workingAreasSelector } from '../../redux/workingAreas/selectors';
import { Tooltip } from './Tooltip';

export const Tooltips: FC = () => {
  const { draftWorkingArea } = useSelector(workingAreasSelector);

  return (
    <>
      {draftWorkingArea?.windows.map((window) => (
        <Tooltip windowId={window.id} key={'tooltip' + window.id} />
      ))}
    </>
  );
};
