import { FC, useEffect, useMemo, useState } from 'react';
import { GenericSelect } from '../GenericSelect';
import { Text } from '../Text';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteWorkingArea,
  moveWorkingAreaInDraft,
  removeDateRange,
  removeDraftWorkingArea,
  setWorkingAreaFilter,
  setIdDraftWorkingArea,
  setNameDraftWorkingArea,
  setSelectedWindow,
  updateWorkingArea,
} from '../../redux/workingAreas/actions';
import { workingAreasSelector } from '../../redux/workingAreas/selectors';
import Modal from '../Modal';
import { Button } from '../Button';
import { Add, Update, Trash } from '../../svg';
import { Input } from '../Input';
import {
  DELETE_WORKING_AREA,
  NEW_WORKING_AREA,
  UPDATE_WORKING_AREA,
} from '../../graphql/news/mutation';
import { useInitialFillWorkingAreas } from '../hooks/useInitialFillWorkingAreas';
import _ from 'lodash';
import { useNotificationBanner } from '../hooks/useNotification';
import { setDetailNews } from '../../redux/newsSelection/actions';
import { resetPinState } from '../../redux/pins/actions';
import { setNewsFilter } from '../../redux/newsFilter/actions';
import { filterChoicesSelector } from '../../redux/filterChoices/selectors';
import { useMutationHook } from '../hooks/useMutationHook';
import { utilsSelector } from '../../redux/utils/selectors';
import {
  addLoading,
  removeLoading,
  setIsAWindowMaximized,
} from '../../redux/utils/actions';

export const WorkingAreaSelector: FC = () => {
  const { refetch } = useInitialFillWorkingAreas();
  const dispatch = useDispatch();
  const {
    workingAreas,
    draftWorkingArea,
    selectedWorkingArea,
    firstWorkingAreaLoaded,
    dateRange,
  } = useSelector(workingAreasSelector);
  const { keywords } = useSelector(filterChoicesSelector);
  const { inWorkingArea, isAWindowMaximized } = useSelector(utilsSelector);

  const [workingAreasMapped, setWorkingAreasMapped] = useState([
    {
      label: 'Nuova area di lavoro',
      value: '0',
      skipTranslation: false,
    },
  ]);

  useEffect(() => {
    setWorkingAreasMapped([
      { label: 'Nuova area di lavoro', value: '0', skipTranslation: false },
      ...(workingAreas?.map((w, i) => ({
        label: w.name,
        value: w.id,
        skipTranslation: i > 0,
      })) || []),
    ]);
  }, [workingAreas]);

  const [workingArea, setWorkingArea] = useState<
    { value: string; label: string } | undefined
  >({ label: 'Nuova area di lavoro', value: '0' });

  const [first, setFirst] = useState(true);

  useEffect(() => {
    if (
      selectedWorkingArea &&
      firstWorkingAreaLoaded &&
      workingAreasMapped.length > 1 &&
      first
    ) {
      setFirst(false);
      const currentWorkingArea = workingAreas.find(
        (w) => w.id === selectedWorkingArea
      );
      const firstWindow = currentWorkingArea?.windows[0];
      setWorkingArea(
        workingAreasMapped.find((w) => w.value === selectedWorkingArea)
      );
      dispatch(
        setNewsFilter({
          dateRange: undefined,
          expressions: firstWindow?.value.search?.keywords,
          providers: firstWindow?.value.search?.providers || [],
          contentTypes: firstWindow?.value.search?.contentTypes,
          fullSearchText:
            firstWindow?.value.search?.fullSearchText || undefined,
          keywords:
            firstWindow?.value.search?.keywords?.map(
              (keywordExpression: string) =>
                keywords.find((k) => keywordExpression === k.expression)!
            ) || [],
          fullSearchTextError: false,
        })
      );
      dispatch(setSelectedWindow(firstWindow?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedWorkingArea,
    firstWorkingAreaLoaded,
    workingAreasMapped,
    keywords,
  ]);

  const [showSaveNewAreaModal, setShowSaveNewAreaModal] = useState(false);

  const [showDeleteAreaModal, setShowDeleteAreaModal] = useState(false);

  const [nameNewArea, setNameNewArea] = useState('');

  const [newWorkingAreaMutation] = useMutationHook({
    queryGql: NEW_WORKING_AREA,
  });

  const [updateWorkingAreaMutation] = useMutationHook({
    queryGql: UPDATE_WORKING_AREA,
  });

  const [deleteWorkingAreaMutation] = useMutationHook({
    queryGql: DELETE_WORKING_AREA,
  });

  const { dispatchNotificationBanner } = useNotificationBanner();

  const isWorkingAreaModified = useMemo(() => {
    const workingAreaModified = workingAreas.find(
      (w) => w.id === draftWorkingArea?.id
    );
    const nullToUndefined: any = (value: any) => {
      if (_.isPlainObject(value)) {
        return _.mapValues(_.omitBy(value, _.isNil), nullToUndefined);
      }
      if (_.isArray(value)) {
        return value.map(nullToUndefined);
      }
      if (value === null) {
        return undefined;
      }
      return value;
    };
    return (
      workingAreaModified?.id !== draftWorkingArea?.id ||
      !_.isEqual(
        nullToUndefined({
          ...workingAreaModified,
          creationDate: undefined,
          name: '',
        }),
        nullToUndefined({
          ...draftWorkingArea,
          creationDate: undefined,
          name: '',
        })
      )
    );
  }, [workingAreas, draftWorkingArea]);

  const removeWorkingArea = () => {
    dispatch(addLoading());
    setShowDeleteAreaModal(false);
    deleteWorkingAreaMutation({ variables: { id: selectedWorkingArea } })
      .then(() => {
        dispatch(deleteWorkingArea());
        dispatch(removeDraftWorkingArea());
        // sbianco filtri START
        dispatch(
          setNewsFilter({
            keywords: [],
            providers: [],
            contentTypes: [],
            dateRange: undefined,
            expressions: [],
            fullSearchText: '',
            fullSearchTextError: false,
          })
        );
        dispatch(
          setWorkingAreaFilter({
            contentTypes: [],
            fullSearchText: '',
            keywords: [],
            providers: [],
            fullSearchTextError: false,
          })
        );
        dispatch(removeDateRange());
        // sbianco filtri END
        localStorage.setItem('LAST_WORKING_AREA', '0');
        setWorkingArea({ label: 'Nuova area di lavoro', value: '0' });
      })
      .finally(() => dispatch(removeLoading()));
  };

  return (
    <>
      <Text text="Area di lavoro:" style={{ color: '#ccc' }} />
      <GenericSelect
        id="WA"
        isDisabled={!inWorkingArea}
        options={workingAreasMapped}
        onMenuOpen={refetch}
        onChange={(value) => {
          dispatch(setDetailNews(undefined));
          dispatch(resetPinState());
          dispatch(setIsAWindowMaximized(false));
          localStorage.setItem('LAST_WORKING_AREA', value!.value);
          if (value!.value === '0') {
            dispatch(removeDraftWorkingArea());
            // sbianco filtri START
            dispatch(
              setNewsFilter({
                keywords: [],
                providers: [],
                contentTypes: [],
                dateRange: undefined,
                expressions: [],
                fullSearchText: '',
                fullSearchTextError: false,
              })
            );
            dispatch(
              setWorkingAreaFilter({
                contentTypes: [],
                fullSearchText: '',
                keywords: [],
                providers: [],
                fullSearchTextError: false,
              })
            );
            dispatch(removeDateRange());
            // sbianco filtri END
            setWorkingArea({ label: 'Nuova area di lavoro', value: '0' });
          } else {
            dispatch(moveWorkingAreaInDraft(value!.value));
            setWorkingArea(value as any);
            const currentWorkingArea = workingAreas.find(
              (w) => w.id === value!.value
            );
            const firstWindow = currentWorkingArea?.windows[0];
            dispatch(
              setNewsFilter({
                dateRange: dateRange.filter(
                  (dr) => dr.id === firstWindow?.id
                )[0]?.date,
                expressions: firstWindow?.value.search?.keywords,
                providers: firstWindow?.value.search?.providers || [],
                contentTypes: firstWindow?.value.search?.contentTypes,
                fullSearchText:
                  firstWindow?.value.search?.fullSearchText || undefined,
                keywords:
                  firstWindow?.value.search?.keywords?.map(
                    (keywordExpression: string) =>
                      keywords.find((k) => keywordExpression === k.expression)!
                  ) || [],
                fullSearchTextError: false,
              })
            );
            dispatch(setSelectedWindow(firstWindow?.id));
          }
        }}
        actions={[
          {
            label: 'Aggiorna selezionato',
            value: '1',
            onClick: () => {
              if (isAWindowMaximized) {
                dispatchNotificationBanner({
                  title: 'Operazione non permessa',
                  text: 'Impossibile salvare/aggiornare aree di lavoro mentre una finestra è massimizzata',
                  ok: false,
                });
                return;
              }
              dispatch(addLoading());
              updateWorkingAreaMutation({
                variables: {
                  id: draftWorkingArea?.id,
                  workingArea: {
                    name: draftWorkingArea?.name,
                    config: draftWorkingArea?.config,
                    windows: draftWorkingArea?.windows,
                  },
                },
              })
                .then(() => {
                  dispatch(
                    updateWorkingArea({
                      id: draftWorkingArea!.id,
                      workingArea: {
                        name: draftWorkingArea!.name,
                        config: draftWorkingArea!.config,
                        windows: draftWorkingArea!.windows,
                      },
                    })
                  );
                })
                .finally(() => dispatch(removeLoading()));
            },
            Icon: <Update />,
            isDisabled:
              !workingArea ||
              workingArea?.value === '0' ||
              !isWorkingAreaModified,
          },
          {
            label: 'Salva come nuovo',
            value: '2',
            onClick: () =>
              isAWindowMaximized
                ? dispatchNotificationBanner({
                    title: 'Operazione non permessa',
                    text: 'Impossibile salvare/aggiornare aree di lavoro mentre una finestra è massimizzata',
                    ok: false,
                  })
                : setShowSaveNewAreaModal(true),
            Icon: <Add className="fill-black" />,
            isDisabled: false,
          },
          {
            label: 'Elimina corrente',
            value: '3',
            onClick: () => setShowDeleteAreaModal(true),
            Icon: <Trash />,
            isDisabled: !workingArea || workingArea?.value === '0',
          },
        ]}
        defaultValueIndex={0}
        showSelectionCircle={true}
        showSelectedOption={true}
        showSelectedOptionLabel={true}
        disableBorder={true}
        value={workingArea}
      />
      {showSaveNewAreaModal && (
        <Modal
          title={'Salva nuova area di lavoro'}
          showCloseButton={true}
          onClickCloseButton={() => setShowSaveNewAreaModal(false)}
          footer={
            <>
              <Button
                color="secondary"
                onClick={() => setShowSaveNewAreaModal(false)}
              >
                <Text text="Chiudi" />
              </Button>
              <Button
                color="primary"
                className="ml-2"
                onClick={() => {
                  const nameNewAreaTrim = nameNewArea.trim();
                  if (nameNewAreaTrim === '') {
                    dispatchNotificationBanner({
                      title: 'Errore',
                      text: 'Si prega di compilare i campi richiesti (*)',
                      ok: false,
                    });
                    return;
                  }
                  if (
                    workingAreas.some((item) => item.name === nameNewAreaTrim)
                  ) {
                    dispatchNotificationBanner({
                      title: 'Errore',
                      text: 'Nome già utilizzato',
                      ok: false,
                    });
                    return;
                  }
                  newWorkingAreaMutation({
                    variables: {
                      workingArea: {
                        name: nameNewAreaTrim,
                        config: draftWorkingArea!.config,
                        windows: draftWorkingArea!.windows,
                      },
                    },
                  }).then((e: any) => {
                    localStorage.setItem(
                      'LAST_WORKING_AREA',
                      e.data.newWorkingArea
                    );
                    dispatch(setIdDraftWorkingArea(e.data.newWorkingArea));
                    dispatch(setNameDraftWorkingArea(nameNewAreaTrim));
                    setWorkingAreasMapped((prevState) => [
                      ...prevState,
                      {
                        value: e.data.newWorkingArea,
                        label: nameNewAreaTrim,
                        skipTranslation: true,
                      },
                    ]);
                    setWorkingArea({
                      value: e.data.newWorkingArea,
                      label: nameNewAreaTrim,
                    });
                    setNameNewArea('');
                    setShowSaveNewAreaModal(false);
                  });
                }}
              >
                <Text text="Salva" />
              </Button>
            </>
          }
        >
          <div className="flex flex-col" style={{ width: '400px' }}>
            <div>
              <Text text="Nome" />
              <span className="text-xs align-super">*</span>:
            </div>
            <Input
              placeholder="Nome area di lavoro"
              value={nameNewArea}
              required
              onChange={(e) => setNameNewArea(e.currentTarget.value)}
            />
          </div>
        </Modal>
      )}
      {showDeleteAreaModal && (
        <Modal
          title={'Elimina area di lavoro'}
          showCloseButton={true}
          onClickCloseButton={() => setShowDeleteAreaModal(false)}
          footer={
            <>
              <Button
                color="secondary"
                onClick={() => setShowDeleteAreaModal(false)}
              >
                <Text text="No" />
              </Button>
              <Button
                color="primary"
                className="ml-2"
                onClick={() => {
                  removeWorkingArea();
                }}
              >
                <Text text="Si" />
              </Button>
            </>
          }
        >
          <div className="flex flex-col" style={{ width: '400px' }}>
            <div>
              <Text text="Confermi di voler eliminare l'area di lavoro corrente?" />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
