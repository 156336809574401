import { Token } from 'boon-js/lib/types';
import { handleTokenAsPredicate, mapBoonExprToType } from './boon';

export function textHighlight(text: string, expressions: string[]) {
  function not(x: string) {
    if (x.startsWith('!')) {
      return x.substring(1);
    }
    return `!${x}`;
  }

  // for each expression, create a filter
  for (const expr of expressions) {
    const extractToHighlights = (token: Token, filters: string[][]) => {
      switch (token.name) {
        case 'IDENTIFIER':
          let items = [token.value!];
          //console.log('IDENTIFIER', items);
          filters.push(items);
          return;
        case 'OPERATOR':
          switch (token.value) {
            case 'AND': {
              const right = filters.pop();
              const left = filters.pop();
              let andItems = [...left!, ...right!];
              //console.log('AND', andItems);
              filters.push(andItems);
              return;
            }
            case 'OR': {
              const right = filters.pop();
              const left = filters.pop();
              let orItems = [...left!, ...right!];
              //console.log('OR', orItems);
              filters.push(orItems);
              return;
            }
            case 'NOT': {
              const filter = filters.pop();
              let notItems = [...filter!.map(not)];
              //console.log('OR', notItems);
              filters.push(notItems);
              return;
            }
          }
      }
    };

    const matchesExpression = mapBoonExprToType(expr, handleTokenAsPredicate);
    if (matchesExpression(text)) {
      // fetch all identifiers
      const identifiers: string[] = mapBoonExprToType(
        expr,
        extractToHighlights
      );
      // highlight only boon positive identifiers
      for (const identifier of identifiers) {
        if (identifier) {
          if (!identifier.startsWith('!')) {
            const regex = new RegExp(
              '(^|[^\\w])(' + identifier + ')([^\\w]|$)',
              'ig'
            );
            text = text.replace(regex, '$1<span class="highlight">$2</span>$3');
          }
        }
      }
    }
  }
  return text;
}
