import React, {
  FC,
  LegacyRef,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  BaseEditor,
  createEditor,
  Editor,
  Element as SlateElement,
  Transforms,
} from 'slate';
import { withHistory } from 'slate-history';
import { Editable, ReactEditor, Slate, useSlate, withReact } from 'slate-react';
import { Text } from '../../Text';

import { PressNewsDetailInput, Provider } from '@nwa/graphql';
import { useTranslation } from 'react-i18next';
import {
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatBulletList,
  FormatColor,
  FormatItalic,
  FormatNumberList,
  FormatUnderlined,
} from '../../../svg';
import { SvgIcon } from '../../SvgIcon';
import {
  CustomElement,
  CustomText,
  deserialize,
  LIST_TYPES,
  serialize,
  TEXT_ALIGN_TYPES,
  Element,
  emailRegex,
} from './utils';

/*const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  //'mod+`': 'code',
};*/

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

export interface NewsEditorProps {
  onSave: (press: PressNewsDetailInput) => void;
  onCancel: () => void;
}

const cleanNewsTextFromBloomberg: any = (fragment: any[]) => {
  let text = [
    {
      ...fragment[0],
      children: fragment
        .filter((c: any) => c)[0]
        .children.filter((c: any, i: any) => c && i > 0),
    },
  ];
  const tickerIndex = findTicker(text);
  if (tickerIndex.length > 0) {
    text = [
      {
        ...text[0],
        children: text[0].children.filter(
          (c: any, i: any) => i !== tickerIndex[0]
        ),
      },
    ];
  }
  const relatedContentIndex = findRelatedContent(text);
  if (relatedContentIndex.length > 0) {
    const errorIndex = findError(text);
    const readQuickTakeIndex = findReadQuickTake(text);
    const contactTheIndex = findContactThe(text);
    if (
      errorIndex.length > 0 ||
      readQuickTakeIndex.length > 0 ||
      contactTheIndex.length > 0
    ) {
      let indexToUse = errorIndex;
      if (indexToUse.length === 0) indexToUse = readQuickTakeIndex;
      if (indexToUse.length === 0)
        indexToUse = [contactTheIndex[0] - 1, contactTheIndex[1]];
      /*console.log('relatedContentIndex', relatedContentIndex);
      console.log('indexToUse', indexToUse);
      console.log(
        'text[0].children[relatedContentIndex[0]]',
        text[0].children[relatedContentIndex[0]]
      );
      console.log(
        'text[0].children[indexToUse[0]]',
        text[0].children[indexToUse[0]]
      );*/
      text = [
        {
          ...text[0],
          children: text[0].children.filter(
            (c: any, i: any) => i < relatedContentIndex[0] || i >= indexToUse[0]
          ),
        },
      ];
      //console.log('text after relatedContent', text);
    }
  }
  const readMoreOnTheIndex = findReadMoreOnThe(text);
  if (readMoreOnTheIndex.length > 0) {
    const contactTheIndex = findContactThe(text);
    if (contactTheIndex.length > 0) {
      const indexToUse = [contactTheIndex[0] - 1, contactTheIndex[1]];
      /*console.log('readMoreOnTheIndex', readMoreOnTheIndex);
      console.log('indexToUse', indexToUse);
      console.log(
        'text[0].children[readMoreOnTheIndex[0]]',
        text[0].children[readMoreOnTheIndex[0]]
      );
      console.log(
        'text[0].children[indexToUse[0]]',
        text[0].children[indexToUse[0]]
      );*/
      text = [
        {
          ...text[0],
          children: text[0].children.filter(
            (c: any, i: any) => i < readMoreOnTheIndex[0] || i >= indexToUse[0]
          ),
        },
      ];
      //console.log('text after readMoreOnThe', text);
    }
  }
  const editorsIndex = findEditors(text);
  if (editorsIndex.length > 0) {
    const lastChildren = text[0].children[editorsIndex[0]];
    //console.log('lastChildren', lastChildren);
    text = [
      {
        ...text[0],
        children: [
          ...text[0].children.filter((c: any, i: any) => i < editorsIndex[0]),
          {
            ...lastChildren,
            children: [
              ...lastChildren.children.filter(
                (c: any, i: any) => i <= editorsIndex[1]
              ),
            ],
          },
        ],
      },
    ];
  }
  const lastEmailIndex = findLastEmail(text);
  //console.log('lastEmailIndex', lastEmailIndex);
  if (lastEmailIndex.length > 0) {
    const lastChildren = text[0].children[lastEmailIndex[0]];
    //console.log('lastChildren', lastChildren);
    text = [
      {
        ...text[0],
        children: [
          ...text[0].children.filter((c: any, i: any) => i < lastEmailIndex[0]),
          {
            ...lastChildren,
            children: [
              ...lastChildren.children.filter(
                (c: any, i: any) => i <= lastEmailIndex[1]
              ),
            ],
          },
        ],
      },
    ];
    //console.log('text after email', text);
    let stop = false;
    for (let i = lastEmailIndex[0]; i >= 0; i--) {
      if (stop) break;
      if (text[0].children[i] && text[0].children[i].children) {
        for (let j = text[0].children[i].children.length - 1; j >= 0; j--) {
          /*console.log(
            'text[0].children[i].children[j]',
            i,
            j,
            text[0].children[i].children[j]
          );*/
          if (text[0].children[i].children[j].text) {
            if (emailRegex.test(text[0].children[i].children[j].text)) {
              text[0].children[i].children[j].text =
                text[0].children[i].children[j].text.split('\xa0at\xa0')[0];
            } else if (
              text[0].children[i].children[j].text ===
              'To contact the reporter on this story:'
            ) {
              text[0].children[i].children[j].text = 'Reporter on this story:';
              stop = true;
              break;
            } else if (
              text[0].children[i].children[j].text ===
              'To contact the reporters on this story:'
            ) {
              text[0].children[i].children[j].text = 'Reporters on this story:';
              stop = true;
              break;
            }
          }
        }
      }
    }
  }
  text = [
    {
      ...text[0],
      children: text[0].children.filter((c: any) => c && !discardP(c)),
    },
  ];
  //console.log('text', text);
  return text;
};

const discardP: any = (el: any) => {
  if (!el) return true;
  if (el.text) {
    /*console.log(
      'discardP',
      el.text,
      /^(Read More|For more on the|Click here for|See more|Listen to More)/i.test(
        el.text
      )
    );*/
    return /^(Read More|For more on the|Click here |See more|Listen to More)/i.test(
      el.text
    );
  } else if (el.children && el.children.length > 0) {
    //console.log('discardP children', el.children);
    return el.children.some((c: any) => discardP(c));
  }
  return false;
};

const findText: any = (el: any) => {
  if (!el) return '';
  if (el.text) {
    return el.text;
  } else if (el.children && el.children.length > 0) {
    //console.log('findText children', el.children);
    return el.children.map((c: any) => findText(c)).join(' ');
  }
  return '';
};

const findRelatedContent = (fragment: any[]) => {
  let indexes: any[] = [];
  const regex = /^Related Content/i;
  fragment[0].children.forEach((c: any, i: number) => {
    //console.log('i', i, c);
    if (c.type === 'paragraph') {
      c.children.forEach((c2: any, j: any) => {
        if (c2 && c2.text && regex.test(c2.text)) indexes = [i, j];
      });
    }
  });
  return indexes;
};

const findReadMoreOnThe = (fragment: any[]) => {
  let indexes: any[] = [];
  const regex = /^Read More on the/i;
  fragment[0].children.forEach((c: any, i: number) => {
    //console.log('i', i, c);
    if (c.type === 'paragraph') {
      c.children.forEach((c2: any, j: any) => {
        if (c2 && c2.text && regex.test(c2.text)) indexes = [i, j];
      });
    }
  });
  return indexes;
};

const findError = (fragment: any[]) => {
  let indexes: any[] = [];
  const regex = /^Error! Filename not specified/i;
  fragment[0].children.forEach((c: any, i: number) => {
    //console.log('i', i, c);
    if (c.type === 'paragraph') {
      c.children.forEach((c2: any, j: any) => {
        if (c2 && c2.text && regex.test(c2.text)) indexes = [i, j];
      });
    }
  });
  return indexes;
};

const findReadQuickTake = (fragment: any[]) => {
  let indexes: any[] = [];
  const regex = /Read QuickTake/i;
  fragment[0].children.forEach((c: any, i: number) => {
    //console.log('i', i, c);
    if (c.type === 'paragraph') {
      c.children.forEach((c2: any, j: any) => {
        if (c2 && c2.text && regex.test(c2.text)) indexes = [i, j];
      });
    }
  });
  return indexes;
};

const findTicker = (fragment: any[]) => {
  let indexes: any[] = [];
  const regex = /^Related ticker/i;
  fragment[0].children.forEach((c: any, i: number) => {
    //console.log('i', i, c);
    if (c.type === 'paragraph') {
      c.children.forEach((c2: any, j: any) => {
        if (c2 && c2.text && regex.test(c2.text)) indexes = [i, j];
      });
    }
  });
  return indexes;
};

const findContactThe = (fragment: any[]) => {
  let indexes: any[] = [];
  const regex = /^To contact the/i;
  fragment[0].children.forEach((c: any, i: number) => {
    //console.log('i', i, c);
    if (c.type === 'paragraph') {
      c.children.forEach((c2: any, j: any) => {
        if (c2 && c2.text && regex.test(c2.text)) indexes = [i, j];
      });
    }
  });
  return indexes;
};

const findEditors = (fragment: any[]) => {
  let indexes: any[] = [];
  const regex = /^To contact the editor(s*) responsible for this story/i;
  fragment[0].children.forEach((c: any, i: number) => {
    //console.log('i', i, c);
    if (c.type === 'paragraph') {
      c.children.forEach((c2: any, j: any) => {
        if (c2 && c2.text && regex.test(c2.text)) indexes = [i, j];
      });
    }
  });
  return indexes;
};

const findLastEmail = (fragment: any[]) => {
  let indexes: any[] = [];
  fragment[0].children.forEach((c: any, i: number) => {
    //console.log('i', i, c);
    if (c.type === 'paragraph') {
      c.children.forEach((c2: any, j: any) => {
        if (c2 && c2.text && emailRegex.test(c2.text)) indexes = [i, j];
      });
    }
  });
  return indexes;
};

export const NewNewsEditor: FC<NewsEditorProps> = ({ onSave, onCancel }) => {
  const renderElement = useCallback(
    (
      props: JSX.IntrinsicAttributes & {
        attributes: any;
        children: any;
        element: any;
      }
    ) => <Element {...props} />,
    []
  );
  const renderLeaf = useCallback(
    (
      props: JSX.IntrinsicAttributes & {
        attributes: any;
        children: any;
        leaf: any;
      }
    ) => <Leaf {...props} />,
    []
  );

  const withHtml = useCallback((editor: BaseEditor & ReactEditor) => {
    const { insertData, isInline, isVoid } = editor;

    editor.isInline = (element) => {
      return element.type === 'link' ? true : isInline(element);
    };

    editor.isVoid = (element) => {
      return element.type === 'image' ? true : isVoid(element);
    };

    editor.insertData = (data) => {
      if (data.getData('application/x-slate-fragment')) {
        insertData(data);
        return;
      }
      let isHtml = true;
      let html = data.getData('text/html');
      if (!html) {
        html = data.getData('text/plain');
        isHtml = false;
      }

      if (html) {
        try {
          const parsed = new DOMParser().parseFromString(html, 'text/html');
          const fragment = deserialize(parsed.body, {}, true);
          //console.log('fragment', fragment);
          const title = [
            {
              ...fragment[0],
              type: 'heading-two',
              children: [
                {
                  text: findText(
                    fragment
                      .filter((c: any) => c)[0]
                      .children.filter((c: any) => c)[0]
                  ),
                  bold: true,
                },
              ],
            },
          ];
          //console.log('title', title);
          Transforms.insertFragment(
            editor,
            cleanNewsTextFromBloomberg(fragment)
          );
          setInitialValueTitle(title);
          setShowTitle(true);
          return;
        } catch (e) {
          console.error(e);
          if (isHtml) return;
        }
      }

      insertData(data);
    };

    return editor;
  }, []);
  const editorTitle = useMemo(
    () => withHtml(withHistory(withReact(createEditor()))),
    [withHtml]
  );
  const editorHeadline = useMemo(
    () => withHtml(withHistory(withReact(createEditor()))),
    [withHtml]
  );
  const editorEnrichedContent = useMemo(
    () => withHtml(withHistory(withReact(createEditor()))),
    [withHtml]
  );

  const [haveTitle, setHaveTitle] = React.useState(false);
  const [haveHeadline, setHaveHeadline] = React.useState(false);
  const [haveContent, setHaveContent] = React.useState(false);

  const [showTitle, setShowTitle] = React.useState(false);

  const disableSave = useMemo(
    () => !haveTitle || !haveContent,
    [haveTitle, haveContent]
  );

  const onClickSave = () => {
    onSave({
      enrichedContent: serialize(editorEnrichedContent),
      title: serialize(editorTitle),
      headline: haveHeadline ? serialize(editorHeadline) : '',
      provider: Provider.NONE,
      order: 0,
      id: '',
    });
  };

  const { t } = useTranslation();
  const [initialValueTitle, setInitialValueTitle] = React.useState<any>(null);
  let initialValueHeadline = deserialize(
    new DOMParser().parseFromString('<em></em>', 'text/html').body,
    {},
    false
  );
  let initialValueContent = deserialize(
    new DOMParser().parseFromString('<span></span>', 'text/html').body,
    {},
    true
  );

  const [ref, setRef] = React.useState<any>(null);

  useEffect(() => {
    if (ref) {
      ref.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ref]);

  return (
    <div
      className="flex flex-col w-full"
      ref={(el) => {
        setRef(el);
      }}
    >
      {showTitle && (
        <>
          <Slate
            editor={editorTitle}
            value={initialValueTitle}
            onChange={(value) => {
              // eslint-disable-next-line array-callback-return
              const haveText = value.some((n) => {
                if ('children' in n) {
                  return n.children.some((c) => {
                    return c.text !== '';
                  });
                }
              });
              setHaveTitle(haveText);
            }}
          >
            <div className="flex flex-col w-full">
              <div
                className="flex flex-row items-center space-x-8 pb-2 w-fit py-1 px-4 rounded-t-lg"
                style={{ backgroundColor: '#F5F7FE' }}
              >
                <div className="flex flex-row items-center space-x-0.5">
                  <MarkButton format="bold" Icon={<FormatBold />} />
                  <MarkButton format="italic" Icon={<FormatItalic />} />
                  <MarkButton format="underline" Icon={<FormatUnderlined />} />
                </div>
                <div className="flex flex-row items-center space-x-0.5">
                  {/*<MarkButton format="code" Icon={<FormatBold />} />
          <BlockButton format="heading-one" icon="looks_one" />
          <BlockButton format="heading-two" icon="looks_two" />
          <BlockButton format="block-quote" icon="format_quote" />*/}
                  <BlockButton format="left" Icon={<FormatAlignLeft />} />
                  <BlockButton format="center" Icon={<FormatAlignCenter />} />
                  <BlockButton format="right" Icon={<FormatAlignRight />} />
                  <BlockButton format="justify" Icon={<FormatAlignJustify />} />
                </div>
                <div className="flex flex-row items-center space-x-0.5">
                  <BlockButton
                    format="bulleted-list"
                    Icon={<FormatBulletList />}
                  />
                  <BlockButton
                    format="numbered-list"
                    Icon={<FormatNumberList />}
                  />
                </div>
                <div className="flex flex-row items-center space-x-0.5">
                  <MarkButton format="highlight" Icon={<FormatColor />} />
                </div>
              </div>
              <div
                className="flex w-full py-1 px-4 rounded-tr-lg"
                style={{ backgroundColor: '#F5F7FE' }}
              >
                <Editable
                  className="bg-white min-w-full max-w-fit mt-2 p-1"
                  renderElement={renderElement}
                  renderLeaf={renderLeaf}
                  placeholder={t('Inserire il titolo della news qui')}
                  spellCheck
                  autoFocus
                  /*onKeyDown={(event) => {
                for (const hotkey in HOTKEYS) {
                  if (isHotkey(hotkey, event as any)) {
                    event.preventDefault();
                    const mark = HOTKEYS[hotkey];
                    toggleMark(editor, mark);
                  }
                }
              }}*/
                />
              </div>
            </div>
          </Slate>
          <div className="p-1" />
          <Slate
            editor={editorHeadline}
            value={initialValueHeadline}
            onChange={(value) => {
              // eslint-disable-next-line array-callback-return
              const haveHeadline = value.some((n) => {
                if ('children' in n) {
                  return n.children.some((c) => {
                    return c.text !== '';
                  });
                }
              });
              setHaveHeadline(haveHeadline);
            }}
          >
            <div className="flex flex-col w-full">
              <div
                className="flex flex-row items-center space-x-8 pb-2 w-fit py-1 px-4 rounded-t-lg"
                style={{ backgroundColor: '#F5F7FE' }}
              >
                <div className="flex flex-row items-center space-x-0.5">
                  <MarkButton format="bold" Icon={<FormatBold />} />
                  <MarkButton format="italic" Icon={<FormatItalic />} />
                  <MarkButton format="underline" Icon={<FormatUnderlined />} />
                </div>
                <div className="flex flex-row items-center space-x-0.5">
                  {/*<MarkButton format="code" Icon={<FormatBold />} />
          <BlockButton format="heading-one" icon="looks_one" />
          <BlockButton format="heading-two" icon="looks_two" />
          <BlockButton format="block-quote" icon="format_quote" />*/}
                  <BlockButton format="left" Icon={<FormatAlignLeft />} />
                  <BlockButton format="center" Icon={<FormatAlignCenter />} />
                  <BlockButton format="right" Icon={<FormatAlignRight />} />
                  <BlockButton format="justify" Icon={<FormatAlignJustify />} />
                </div>
                <div className="flex flex-row items-center space-x-0.5">
                  <BlockButton
                    format="bulleted-list"
                    Icon={<FormatBulletList />}
                  />
                  <BlockButton
                    format="numbered-list"
                    Icon={<FormatNumberList />}
                  />
                </div>
                <div className="flex flex-row items-center space-x-0.5">
                  <MarkButton format="highlight" Icon={<FormatColor />} />
                </div>
              </div>
              <div
                className="flex w-full py-1 px-4 rounded-tr-lg"
                style={{ backgroundColor: '#F5F7FE' }}
              >
                <Editable
                  className="bg-white min-w-full max-w-fit mt-2 p-1"
                  renderElement={renderElement}
                  renderLeaf={renderLeaf}
                  placeholder={t('Inserire il sottotitolo della news qui')}
                  spellCheck
                  /*onKeyDown={(event) => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event as any)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }
            }}*/
                />
              </div>
            </div>
          </Slate>
          <div className="p-1" />
        </>
      )}
      <Slate
        editor={editorEnrichedContent}
        value={initialValueContent}
        onChange={(value) => {
          // eslint-disable-next-line array-callback-return
          const haveText = value.some((n) => {
            if ('children' in n) {
              return n.children.some((c) => {
                return c.text !== '';
              });
            }
          });
          setHaveContent(haveText);
        }}
      >
        <div className="flex flex-col w-full">
          <div
            className="flex flex-row items-center space-x-8 pb-2 w-fit py-1 px-4 rounded-t-lg"
            style={{ backgroundColor: '#F5F7FE' }}
          >
            <div className="flex flex-row items-center space-x-0.5">
              <MarkButton format="bold" Icon={<FormatBold />} />
              <MarkButton format="italic" Icon={<FormatItalic />} />
              <MarkButton format="underline" Icon={<FormatUnderlined />} />
            </div>
            <div className="flex flex-row items-center space-x-0.5">
              {/*<MarkButton format="code" Icon={<FormatBold />} />
          <BlockButton format="heading-one" icon="looks_one" />
          <BlockButton format="heading-two" icon="looks_two" />
          <BlockButton format="block-quote" icon="format_quote" />*/}
              <BlockButton format="left" Icon={<FormatAlignLeft />} />
              <BlockButton format="center" Icon={<FormatAlignCenter />} />
              <BlockButton format="right" Icon={<FormatAlignRight />} />
              <BlockButton format="justify" Icon={<FormatAlignJustify />} />
            </div>
            <div className="flex flex-row items-center space-x-0.5">
              <BlockButton format="bulleted-list" Icon={<FormatBulletList />} />
              <BlockButton format="numbered-list" Icon={<FormatNumberList />} />
            </div>
            <div className="flex flex-row items-center space-x-0.5">
              <MarkButton format="highlight" Icon={<FormatColor />} />
            </div>
          </div>
          <div
            className="flex w-full py-1 px-4 rounded-tr-lg"
            style={{ backgroundColor: '#F5F7FE' }}
          >
            <Editable
              className="bg-white min-w-full max-w-fit mt-2 p-1"
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder={t('Inserire il testo della news qui')}
              spellCheck
              /*onKeyDown={(event) => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event as any)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }
            }}*/
            />
          </div>
        </div>
      </Slate>
      <div
        className="flex items-center w-full justify-end py-1 px-4 rounded-b-lg"
        style={{ backgroundColor: '#F5F7FE' }}
      >
        <div className="flex cursor-pointer" onClick={onCancel}>
          <Text text="Annulla" />
        </div>
        <div
          className={`flex ${disableSave ? '' : 'cursor-pointer'} ml-4`}
          onClick={() => {
            !disableSave && onClickSave();
          }}
        >
          <Text
            text="Salva"
            style={disableSave ? { color: 'grey' } : { color: '#5B84EF' }}
          />
        </div>
      </div>
    </div>
  );
};

export const toggleBlock = (
  editor: BaseEditor & ReactEditor,
  format: string
) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties: any;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const toggleMark = (
  editor: BaseEditor & ReactEditor,
  format: string
) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const isBlockActive = (
  editor: BaseEditor & ReactEditor,
  format: any,
  blockType = 'type'
) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        // @ts-ignore
        n[blockType] === format,
    })
  );

  return !!match;
};

export const isMarkActive = (
  editor: BaseEditor & ReactEditor,
  format: string | number
) => {
  const marks = Editor.marks(editor);
  // @ts-ignore
  return marks ? marks[format] === true : false;
};

export const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.highlight) {
    children = <span style={{ backgroundColor: '#f8fb63' }}>{children}</span>;
  }

  return <span {...attributes}>{children}</span>;
};

export const BlockButton = ({ format, Icon }: any) => {
  const editor = useSlate();
  return (
    <EditorButton
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      )}
      onMouseDown={(event: { preventDefault: () => void }) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <SvgIcon svg={Icon} />
    </EditorButton>
  );
};

export const MarkButton = ({ format, Icon }: any) => {
  const editor = useSlate();
  return (
    <EditorButton
      active={isMarkActive(editor, format)}
      onMouseDown={(event: { preventDefault: () => void }) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <SvgIcon svg={Icon} />
    </EditorButton>
  );
};

export const EditorButton = React.forwardRef(
  (
    {
      active,
      reversed,
      ...props
    }: PropsWithChildren<{
      active: boolean;
      reversed: boolean;
      [key: string]: unknown;
    }>,
    ref: LegacyRef<HTMLSpanElement> | undefined
  ) => (
    <span
      {...props}
      ref={ref}
      style={{
        cursor: 'pointer',
        fill: reversed
          ? active
            ? 'white'
            : '#aaa'
          : active
          ? 'black'
          : '#ccc',
      }}
    />
  )
);

EditorButton.displayName = 'EditorButton';
