import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_NEWS_FROM_OLD_PRESSES } from '../../../graphql/news/mutation';
import {
  setHighlightSelection,
  setPressSelection,
} from '../../../redux/newsSelection/actions';
import { newsSelectionSelector } from '../../../redux/newsSelection/selectors';
import { addLoading, removeLoading } from '../../../redux/utils/actions';
import { Divider } from '../../../svg';
import { Button } from '../../Button';
import { useMutationHook } from '../../hooks/useMutationHook';
import { SvgIcon } from '../../SvgIcon';
import { Text } from '../../Text';
import { ModalDeletePresses } from './ModalDeletePresses';

interface ContextualMenuPressListProps {
  isHighlight: boolean;
  refetch: (ids: string[]) => void;
}

export const ContextualMenuPressList: FC<ContextualMenuPressListProps> = ({
  isHighlight,
  refetch,
}) => {
  const dispatch = useDispatch();
  const [idsToDelete, setIdsToDelete] = useState<string[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [addNewsFromOldPressesMutation] = useMutationHook({
    queryGql: ADD_NEWS_FROM_OLD_PRESSES,
  });
  const { currentHighlight, pressSelection, highlightSelection } = useSelector(
    newsSelectionSelector
  );

  const onClickAdd = () => {
    dispatch(addLoading());
    addNewsFromOldPressesMutation({
      variables: {
        from: isHighlight ? highlightSelection : pressSelection,
        to: currentHighlight?.summary.id,
      },
    })
      .then(() => {
        isHighlight
          ? dispatch(setHighlightSelection([]))
          : dispatch(setPressSelection([]));
      })
      .finally(() => dispatch(removeLoading()));
  };

  const onClickDelete = () => {
    if (true) {
      setIdsToDelete(isHighlight ? highlightSelection : pressSelection);
      setShowDeleteModal(true);
    }
  };

  return (
    <>
      {(isHighlight
        ? highlightSelection.length > 0
        : pressSelection.length > 0) && (
        <div
          className="flex items-center fixed left-0 right-0 bottom-0 bg-white h-14 justify-between"
          style={{
            boxShadow: '0px -6px 14px rgba(0, 0, 0, 0.12)',
            zIndex: 251,
          }}
        >
          <div className="flex content-start ml-4">
            <Text text="Hai selezionato" />
            &nbsp;
            <span className="font-bold">
              {isHighlight ? highlightSelection.length : pressSelection.length}
            </span>
            &nbsp;
            <Text
              text={isHighlight ? 'rassegne' : 'raccolte'}
              className="font-bold"
            />
          </div>
          <div className="flex items-center justify-end mr-4">
            <div className="flex items-center justify-end space-x-3">
              <SvgIcon svg={<Divider />} />
              <Button color="secondary" onClick={() => onClickDelete()}>
                <Text text="Elimina selezionati" />
              </Button>
              <Button color="primary" onClick={() => onClickAdd()}>
                <Text text="Aggiungi alla rassegna" />
              </Button>
            </div>
          </div>
        </div>
      )}
      <ModalDeletePresses
        idsToDelete={idsToDelete}
        isHighlight={isHighlight}
        refetch={refetch}
        setIdsToDelete={setIdsToDelete}
        setShowDeleteModal={setShowDeleteModal}
        showDeleteModal={showDeleteModal}
      />
    </>
  );
};
