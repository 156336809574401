import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterChoicesSelector } from '../../redux/filterChoices/selectors';
import { backupNewsFilter } from '../../redux/newsFilter/actions';
import { utilsSelector } from '../../redux/utils/selectors';
import { backupWorkingAreaFilterAndSetTypeBookmark } from '../../redux/workingAreas/actions';
import { workingAreasSelector } from '../../redux/workingAreas/selectors';
import { GenericSelect } from '../GenericSelect';

export const BookmarkSelector: FC = () => {
  const dispatch = useDispatch();
  const { selectedWindow } = useSelector(workingAreasSelector);
  const { inWorkingArea } = useSelector(utilsSelector);
  const { bookmarksList } = useSelector(filterChoicesSelector);

  return (
    <GenericSelect
      id="BookmarksSelector"
      onChange={(newValue) => {
        if (selectedWindow) {
          dispatch(backupNewsFilter(selectedWindow));
          dispatch(backupWorkingAreaFilterAndSetTypeBookmark(newValue!.value));
        }
      }}
      options={bookmarksList}
      showSelectionCircle={false}
      showSelectedOption={false}
      label={'contrassegnati'}
      disableBorder={true}
      isDisabled={!inWorkingArea || !selectedWindow}
    />
  );
};
