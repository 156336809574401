import { createAction } from '@reduxjs/toolkit';
import { Category, Keyword, Tag } from '@nwa/graphql';

enum Actions {
  SET_CURRENT_BOOKMARK_COLOR = 'SET_CURRENT_BOOKMARK_COLOR',
  SET_KEYWORDS = 'SET_KEYWORDS',
  REMOVE_KEYWORD = 'REMOVE_KEYWORD',
  SET_CATEGORIES = 'SET_CATEGORIES',
  ADD_NEW_KEYWORD = 'ADD_NEW_KEYWORD',
  ADD_NEW_CATEGORY = 'ADD_NEW_CATEGORY',
  REMOVE_CATEGORY = 'REMOVE_CATEGORY',
  SET_TAGS = 'SET_TAGS',
}

export const setCurrentBookmarkColor = createAction<string>(
  Actions.SET_CURRENT_BOOKMARK_COLOR
);

export const setKeywords = createAction<Keyword[]>(Actions.SET_KEYWORDS);

export const removeKeyword = createAction<string>(Actions.REMOVE_KEYWORD);

export const setCategories = createAction<Category[]>(Actions.SET_CATEGORIES);

export const setTags = createAction<Tag[]>(Actions.SET_TAGS);

export const addNewKeyword = createAction<Keyword>(Actions.ADD_NEW_KEYWORD);

export const addNewCategory = createAction<Category>(Actions.ADD_NEW_CATEGORY);

export const removeCategory = createAction<string>(Actions.REMOVE_CATEGORY);
