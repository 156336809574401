import { useEffect } from 'react';
import {
  LIST_CATEGORIES,
  LIST_KEYWORDS,
  SEARCH_TAGS,
} from '../../graphql/news/queries';
import { useDispatch } from 'react-redux';
import {
  setCategories,
  setKeywords,
  setTags,
} from '../../redux/filterChoices/actions';
import { firstLowerCaseGqlDefinition } from '../../utils/stringUtils';
import { Keyword, Sorting } from '@nwa/graphql';
import { useQueryHook } from './useQueryHook';
import { useSelector } from 'react-redux';
import { workingAreasSelector } from '../../redux/workingAreas/selectors';
import { filterWorkingAreas } from '../../redux/workingAreas/actions';

export const useInitialFillKeywords = (): {
  refetch: any;
} => {
  const dispatch = useDispatch();
  const { firstWorkingAreaLoaded } = useSelector(workingAreasSelector);

  const { data, refetch } = useQueryHook({ queryGql: LIST_KEYWORDS });
  useEffect(() => {
    if (data && data[firstLowerCaseGqlDefinition(LIST_KEYWORDS)]) {
      let tmp: Keyword[] = [
        ...data[firstLowerCaseGqlDefinition(LIST_KEYWORDS)],
      ];
      tmp.sort((k1: Keyword, k2: Keyword) => k1.position - k2.position);
      dispatch(setKeywords(tmp));
      if (firstWorkingAreaLoaded) dispatch(filterWorkingAreas(tmp));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch]);

  return { refetch };
};

export const useInitialFillCategories = (): {
  refetch: any;
} => {
  const dispatch = useDispatch();

  const { data, refetch } = useQueryHook({
    queryGql: LIST_CATEGORIES,
    options: {
      variables: {
        sorting: Sorting.ASC,
      },
      fetchPolicy: 'network-only',
    },
  });
  useEffect(() => {
    if (data && data[firstLowerCaseGqlDefinition(LIST_CATEGORIES)]) {
      dispatch(
        setCategories(data[firstLowerCaseGqlDefinition(LIST_CATEGORIES)])
      );
    }
  }, [data, dispatch]);

  return { refetch };
};

export const useInitialFillTags = (): {
  refetch: any;
} => {
  const dispatch = useDispatch();

  const { data, refetch } = useQueryHook({
    queryGql: SEARCH_TAGS,
    options: {
      variables: {
        sorting: Sorting.ASC,
      },
      fetchPolicy: 'network-only',
    },
  });
  useEffect(() => {
    if (
      data &&
      data[firstLowerCaseGqlDefinition(SEARCH_TAGS)] &&
      data[firstLowerCaseGqlDefinition(SEARCH_TAGS)].results
    ) {
      dispatch(setTags(data[firstLowerCaseGqlDefinition(SEARCH_TAGS)].results));
    }
  }, [data, dispatch]);

  return { refetch };
};
