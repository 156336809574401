import { NewsSummary } from '@nwa/graphql';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LIST_DAILY_PRESS_NEWS } from '../../../graphql/news/queries';
import { setNewsSelectionHighlight } from '../../../redux/newsSelection/actions';
import { newsSelectionSelector } from '../../../redux/newsSelection/selectors';
import { addLoading, removeLoading } from '../../../redux/utils/actions';
import { CheckboxSelected, CheckboxUnselected, Divider } from '../../../svg';
import { firstLowerCaseGqlDefinition } from '../../../utils/stringUtils';
import { ContextualMenu } from '../../ContextualMenu';
import { useQueryHook } from '../../hooks/useQueryHook';
import { NavigationBackComponent } from '../../NavigationBack';
import { NewsDetailWrapper } from '../../NewsDetailWrapper';
import { NewsSummaryElement } from '../../NewsSummaryElement';
import { SvgIcon } from '../../SvgIcon';
import { Text } from '../../Text';

export const HighlightsNewsList: FC = () => {
  const dispatch = useDispatch();
  const { newsSelectionHighlight } = useSelector(newsSelectionSelector);
  const [newsList, setNewsList] = useState<NewsSummary[]>([]);

  const { data, loading } = useQueryHook({
    queryGql: LIST_DAILY_PRESS_NEWS,
    options: {
      fetchPolicy: 'network-only',
    },
  });

  useEffect(() => {
    if (data && data[firstLowerCaseGqlDefinition(LIST_DAILY_PRESS_NEWS)])
      setNewsList(data[firstLowerCaseGqlDefinition(LIST_DAILY_PRESS_NEWS)]);
    else setNewsList([]);
  }, [data]);

  useEffect(() => {
    if (loading) {
      dispatch(addLoading());
    } else {
      dispatch(removeLoading());
    }

    return () => {
      dispatch(removeLoading());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const selectedAll = useMemo(
    () => newsSelectionHighlight.length === newsList.length,
    [newsSelectionHighlight, newsList]
  );

  const selectAllClick = useCallback(() => {
    if (selectedAll) {
      dispatch(setNewsSelectionHighlight([]));
    } else {
      dispatch(setNewsSelectionHighlight(newsList.map((news) => news.id)));
    }
  }, [selectedAll, newsList, dispatch]);

  return (
    <>
      <div style={{ backgroundColor: '#FAFAFA' }} className="h-full">
        <div
          style={{ backgroundColor: '#FAFAFA' }}
          className="flex flex-col h-screen w-full items-center justify-around"
        >
          <div
            className="h-12 w-full pl-3 py-2 mt-1 flex items-center justify-between bg-white"
            style={{ boxShadow: '2px 0px 8px rgba(0, 0, 0, 0.1)' }}
          >
            <div className="flex items-center">
              <NavigationBackComponent />
              <SvgIcon svg={<Divider className="m-1" />} />
              <Text text="Elenco notizie" className="ml-3 font-semibold" />
            </div>
            <div className="flex items-center justify-end space-x-3 mr-5">
              {/*filtri?*/}
            </div>
          </div>
          <div className="flex h-full w-full py-10 px-20 rounded-xl flex-col scrollbar overflow-y-auto mt-0.5 mb-8">
            {newsList.length > 0 && (
              <div className="flex h-fit py-3 px-5">
                <div
                  className="flex items-center my-0 mx-1 cursor-pointer"
                  onClick={selectAllClick}
                >
                  <SvgIcon
                    svg={
                      selectedAll ? (
                        <CheckboxSelected />
                      ) : (
                        <CheckboxUnselected />
                      )
                    }
                    className="w-6 h-6"
                  />
                  <Text text="Seleziona tutti" />
                </div>
              </div>
            )}
            <div className="flex h-fit w-full rounded-xl flex-col pb-4">
              {newsList.map((news) => (
                <NewsSummaryElement
                  key={news.id}
                  newsItem={news}
                  selectedWindow={true}
                  html={true}
                />
              ))}
              {newsList.length === 0 && !loading && (
                <div className="flex h-full w-full rounded-xl flex-col justify-center items-center">
                  <Text
                    text="Qui appariranno le notizie che vengono aggiunte alle raccolte durante la giornata"
                    style={{
                      fontSize: '1.25rem',
                      lineHeight: '1.75rem',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <NewsDetailWrapper />
      <ContextualMenu inHighlightNewsList={true} />
    </>
  );
};
