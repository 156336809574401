import {
  ContentType,
  NewsDetail,
  NewsSummary,
  PressType,
  Provider,
} from '@nwa/graphql';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { newsSelectionSelector } from '../../redux/newsSelection/selectors';
import { filterChoicesSelector } from '../../redux/filterChoices/selectors';
import { pinsSelector } from '../../redux/pins/selectors';
import {
  forceRefresh,
  removeNewsInHighlight,
  removeNewsInPress,
  setDetailNews,
  setNewsSelection,
  setNewsSelectionHighlight,
} from '../../redux/newsSelection/actions';
import { setPinnedIds } from '../../redux/pins/actions';
import {
  CheckboxSelected,
  CheckboxUnselected,
  ComposedNews,
  NewRecord,
  Divider,
  PinOff,
  PinOn,
  InHighlightCheck,
  Uncheck,
  InPressReviewCheck,
  InPressReviewIcon,
  InHighlightIcon,
} from '../../svg';
import { SvgIcon } from '../SvgIcon';
import { Text } from '../Text';
import chroma from 'chroma-js';
import { ContentTypeObject } from '../../redux/filterChoices/costant';
import {
  MARK_NEWS_READ,
  REMOVE_NEWS_FROM_CURRENT_PRESS,
} from '../../graphql/news/mutation';
import { GET_NEWS_DETAIL } from '../../graphql/news/queries';
import { NewsLogoAndDate } from '../NewsLogoAndDate';
import {
  addLoading,
  removeLoading,
  setTranslationsIds,
} from '../../redux/utils/actions';
import { NewsBookmark } from '../NewsBookmark';
import { useMutationHook } from '../hooks/useMutationHook';
import { newsFilterSelector } from '../../redux/newsFilter/selectors';
import { textHighlight } from '../../utils/highlight';
import { utilsSelector } from '../../redux/utils/selectors';
import { DivWithHtml } from '../DivWithHtml';
import { ReactComponent as TextOnly } from '../../svg/icons/Attachment/Text.svg';
import { apolloClient } from '../../graphql';

export interface NewsSummaryElementProps {
  newsItem: NewsSummary;
  selectedWindow: boolean;
  tabId?: string; //se non ho il tabId vuol dire che non sono in ambito WorkingArea
  html: boolean;
}

export const NewsSummaryElement: FC<NewsSummaryElementProps> = ({
  newsItem,
  selectedWindow,
  tabId,
  html,
}) => {
  const dispatch = useDispatch();
  const cts = useSelector(filterChoicesSelector);
  const {
    newsSelection,
    newsSelectionHighlight,
    newsInHighlight,
    newsInPress,
  } = useSelector(newsSelectionSelector);
  const { pinnedIds } = useSelector(pinsSelector);
  const { textSize, isMobile, translationsIds } = useSelector(utilsSelector);
  const {
    id,
    date,
    title,
    /*headline,*/
    contentType,
    provider,
    bookmark,
    keywords,
    inPressReview,
    inHighlight,
    recomposed,
    read,
    category,
  } = newsItem;

  const [readState, setReadState] = useState(read);

  const [markNewsReadMutation] = useMutationHook({
    queryGql: MARK_NEWS_READ,
    disableSuccessMessage: true,
  });

  const [removeNewsFromCurrentPressMutation] = useMutationHook({
    queryGql: REMOVE_NEWS_FROM_CURRENT_PRESS,
  });

  const selected = useMemo(
    () =>
      tabId
        ? newsSelection.findIndex((newsId) => id === newsId) !== -1
        : newsSelectionHighlight.findIndex((newsId) => id === newsId) !== -1,
    [tabId, newsSelection, newsSelectionHighlight, id]
  );
  const pinnedItem = useMemo(
    () =>
      pinnedIds.findIndex((pin) => id === pin.newsId && tabId === pin.tabId) !==
      -1,
    [pinnedIds, id, tabId]
  );
  const pinnedTab = useMemo(
    () => pinnedIds.findIndex((pin) => tabId === pin.tabId) !== -1,
    [pinnedIds, tabId]
  );

  const onClickCheckbox = () => {
    if (tabId) {
      let newsSelectionTmp = [...newsSelection];
      if (selected) {
        newsSelectionTmp.splice(
          newsSelection.findIndex((newsId) => id === newsId),
          1
        );
      } else {
        newsSelectionTmp.push(id);
      }
      dispatch(setNewsSelection(newsSelectionTmp));
    } else {
      let newsSelectionTmp = [...newsSelectionHighlight];
      if (selected) {
        newsSelectionTmp.splice(
          newsSelectionHighlight.findIndex((newsId) => id === newsId),
          1
        );
      } else {
        newsSelectionTmp.push(id);
      }
      dispatch(setNewsSelectionHighlight(newsSelectionTmp));
    }
  };

  const [selectedPressReview, setSelectedPressReview] = useState(
    inPressReview || !!newsInPress.find((item) => item === id)
  );

  useEffect(() => {
    setSelectedPressReview(!!newsInPress.find((item) => item === id));
  }, [newsInPress, id]);

  const onClickPressReview = () => {
    removeNewsFromCurrentPressMutation({
      variables: {
        to: PressType.RELEASE,
        newsId: [id],
      },
    }).then(() => {
      dispatch(forceRefresh());
      dispatch(removeNewsInPress(id));
    });
  };

  const [selectedHighlight, setSelectedHighlight] = useState(
    inHighlight || !!newsInHighlight.find((item) => item === id)
  );

  useEffect(() => {
    setSelectedHighlight(!!newsInHighlight.find((item) => item === id));
  }, [newsInHighlight, id]);

  const onClickHighlight = () => {
    removeNewsFromCurrentPressMutation({
      variables: {
        to: PressType.HIGHLIGHT,
        newsId: [id],
      },
    }).then(() => {
      dispatch(forceRefresh());
      dispatch(removeNewsInHighlight(id));
    });
  };

  const contentTypeObj: ContentTypeObject = useMemo(
    () =>
      cts.contentTypes.find(
        (contentTypeI) => contentType === contentTypeI.value
      ) || {
        label: 'Loading',
        value: ContentType.TEXT_ONLY,
        Icon: <TextOnly />,
      },
    [cts.contentTypes, contentType]
  );

  const clickedPin = () =>
    pinnedItem
      ? dispatch(
          setPinnedIds(
            pinnedIds.filter((pin) => pin.newsId !== id && pin.tabId !== tabId)
          )
        )
      : dispatch(setPinnedIds(pinnedIds.concat({ newsId: id, tabId: tabId! })));

  const newsFilter = useSelector(newsFilterSelector);

  function highlightText(text: string): string {
    const expressions = newsFilter.keywords
      .filter((keyword) => keyword && keyword.expression)
      .map((keyword) => keyword.expression);
    if (newsFilter.fullSearchText) {
      expressions.push(newsFilter.fullSearchText);
    }
    return textHighlight(text, expressions);
  }

  function wrapWithHighlights(
    newsDetail: NewsDetail | undefined
  ): NewsDetail | undefined {
    if (newsDetail) {
      if (newsDetail.summary) {
        newsDetail.summary = {
          ...newsDetail.summary,
          title: highlightText(newsDetail.summary.title),
        };
        if (newsDetail.summary.headline) {
          newsDetail.summary.headline = highlightText(
            newsDetail.summary.headline
          );
        }
      }
      newsDetail.content = highlightText(newsDetail.content);
    }
    return newsDetail;
  }

  const detailFetchPolicy = (id: string, provider: Provider) => {
    if (provider === Provider.REUTERS) return 'network-only';
    if (!translationsIds.includes(id)) {
      return 'cache-first';
    }
    dispatch(setTranslationsIds(translationsIds.filter((item) => item !== id)));
    return 'network-only';
  };

  return (
    <div
      className="flex py-2 px-2 flex-row max-w-full rounded justify-between"
      style={{
        margin: '0.25rem',
        backgroundColor: readState ? '#F2F2F2' : 'white',
        border: selectedHighlight
          ? '1px solid #0E53B7'
          : selectedPressReview
          ? '1px solid #49B149'
          : '1px solid white',
      }}
      id={id + tabId}
    >
      <div
        className="flex min-w-0"
        style={{ lineHeight: `${1.25 * textSize}rem` }}
      >
        {/* il min-w-0 serve per l'ellipsis */}
        {!isMobile &&
          selectedWindow &&
          (!selectedPressReview || !tabId) &&
          !selectedHighlight && (
            <div className="flex items-center my-0 mx-1">
              <SvgIcon
                svg={selected ? <CheckboxSelected /> : <CheckboxUnselected />}
                className="w-6 h-6"
                pointer={true}
                onClick={() => onClickCheckbox()}
              />
            </div>
          )}
        {!isMobile &&
          selectedWindow &&
          (selectedHighlight || (selectedPressReview && tabId)) && (
            <div className="flex items-center my-0 mx-1 flex-col justify-around">
              {selectedHighlight && (
                <SvgIcon
                  svg={<InHighlightCheck />}
                  svgHover={<Uncheck />}
                  className="w-6 h-6"
                  pointer={true}
                  onClick={() => onClickHighlight()}
                />
              )}
              {selectedPressReview && tabId && (
                <SvgIcon
                  svg={<InPressReviewCheck />}
                  svgHover={<Uncheck />}
                  className="w-6 h-6"
                  pointer={true}
                  onClick={() => onClickPressReview()}
                />
              )}
            </div>
          )}
        <div
          className={
            `${
              'flex flex-col min-w-0' +
              (selectedWindow ? ' cursor-pointer' : '')
            }`
            /* il min-w-0 serve per l'ellipsis */
          }
          onClick={() => {
            if (selectedWindow) {
              dispatch(addLoading());
              if (!readState)
                markNewsReadMutation({
                  variables: {
                    id,
                  },
                }).then(() => {
                  setReadState(true);
                });
              apolloClient()
                .query({
                  query: GET_NEWS_DETAIL,
                  variables: {
                    id,
                  },
                  fetchPolicy: detailFetchPolicy(id, provider),
                })
                .then((res: any) => {
                  dispatch(
                    setDetailNews(
                      wrapWithHighlights({ ...res.data.getNewsDetail })
                    )
                  );
                })
                .finally(() => dispatch(removeLoading()));
            }
          }}
        >
          <div className="flex">
            <NewsLogoAndDate date={date} provider={provider} />
          </div>
          <div className="flex items-center">
            {!readState && (
              <SvgIcon
                svg={<NewRecord />}
                className="w-5 h-5 mr-1 items-center"
              />
            )}
            <DivWithHtml
              html={title}
              className={`overflow-ellipsis overflow-hidden whitespace-nowrap ${
                readState && !html ? '' : 'font-bold'
              }`}
            />
          </div>
          <div className="flex items-center">
            {keywords?.map((keyword) => (
              <div className="flex" key={keyword.id + id}>
                <div
                  style={{
                    backgroundColor: chroma(keyword.color).alpha(0.1).css(),
                  }}
                  className="flex m-1"
                >
                  <div className="mx-1" style={{ color: keyword.color }}>
                    <span>
                      <Text
                        text={keyword.name}
                        skipTranslation={true}
                        style={{
                          fontSize: '0.75rem',
                          lineHeight: '1rem',
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            ))}
            <div className="flex" key={contentTypeObj + id}>
              <div className="flex m-1 items-center">
                <SvgIcon svg={contentTypeObj.Icon} className="w-4 h-4 pt-0.5" />
                <div>
                  <Text
                    text={contentTypeObj.label}
                    style={{
                      color: '#838383',
                      fontSize: '0.75rem',
                      lineHeight: '1rem',
                    }}
                  />
                </div>
              </div>
            </div>
            {category && (
              <div className="flex">
                <div
                  style={{
                    background: 'rgba(255, 255, 255, 0.2)',
                    border: '1px solid #CACACA',
                    borderRadius: '2px',
                  }}
                  className="flex m-1 items-center"
                >
                  <Text
                    className="p-0.5"
                    text={category.name}
                    skipTranslation={true}
                    style={{
                      fontSize: '0.75rem',
                      lineHeight: '1rem',
                    }}
                  />
                </div>
              </div>
            )}
            {recomposed && (
              <SvgIcon
                svg={<ComposedNews />}
                className="w-4 h-4 ml-1 items-center"
              />
            )}
          </div>
        </div>
      </div>
      {!isMobile && selectedWindow && (
        <div className="flex items-center">
          {selectedPressReview && (
            <div className="flex items-center">
              <div className="flex items-center mx-1">
                <div
                  style={{
                    backgroundColor: chroma('#49B149').alpha(0.1).css(),
                  }}
                  className="flex ml-0.5 items-center p-0.5"
                >
                  <div className="ml-0.5">
                    <SvgIcon svg={<InPressReviewIcon />} className="w-4 h-4" />
                  </div>
                  <div className="mx-0.5" style={{ color: '#49B149' }}>
                    <Text
                      className="flex flex-nowrap whitespace-nowrap"
                      text="In raccolta"
                      style={{
                        fontSize: '0.625rem',
                        lineHeight: '0.75rem',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedHighlight && (
            <div className="flex items-center">
              <div className="flex items-center mx-1">
                <div
                  style={{
                    backgroundColor: chroma('#0E53B7').alpha(0.1).css(),
                  }}
                  className="flex ml-0.5 items-center p-0.5"
                >
                  <div className="ml-0.5">
                    <SvgIcon svg={<InHighlightIcon />} className="w-4 h-4" />
                  </div>
                  <div className="mx-0.5" style={{ color: '#0E53B7' }}>
                    <Text
                      className="flex flex-nowrap whitespace-nowrap"
                      text="In rassegna"
                      style={{
                        fontSize: '0.625rem',
                        lineHeight: '0.75rem',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {tabId && (
            <>
              <div className="flex items-center">
                <div className="flex items-center mx-1">
                  <SvgIcon svg={<Divider />} />
                </div>
              </div>
              <div className="flex items-center">
                <div className="flex flex-col items-center space-y-1">
                  <NewsBookmark bookmark={bookmark} id={id} />

                  <SvgIcon
                    svg={pinnedItem ? <PinOn /> : <PinOff />}
                    onClick={clickedPin}
                    pointer={true}
                    style={{
                      visibility:
                        !pinnedItem && pinnedTab ? 'hidden' : 'visible',
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
