import { PressDetail, PressType } from '@nwa/graphql';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { GET_PRESS_DETAIL } from '../../../graphql/news/queries';
import {
  setHighlightSelection,
  setPressSelection,
} from '../../../redux/newsSelection/actions';
import { newsSelectionSelector } from '../../../redux/newsSelection/selectors';
import { addLoading, removeLoading } from '../../../redux/utils/actions';
import {
  CheckboxSelected,
  CheckboxUnselected,
  Copy,
  Divider,
  Download,
  //Download,
  TrashNoTranslate,
} from '../../../svg';
import {
  getDateAsString,
  getHoursAndMinutesAsString,
  isToday,
  isYesterday,
} from '../../../utils/dateUtils';
import { Button } from '../../Button';
import { useLazyQueryHook } from '../../hooks/useLazyQueryHook';
import { useListPress } from '../../hooks/useListPress';
import { useNotificationBanner } from '../../hooks/useNotification';
import Modal from '../../Modal';
import { NavigationBackComponent } from '../../NavigationBack';
import { SvgIcon } from '../../SvgIcon';
import { Text } from '../../Text';
import {
  createHtmlEmailFromPress,
  createHtmlPDFFromPress,
} from '../Details/utils';
import { ContextualMenuPressList } from './ContextualMenuPressList';
import { ModalDeletePresses } from './ModalDeletePresses';
import { jsPDF } from 'jspdf';
import { callAddFont } from '../Details/font';
import { firstLowerCaseGqlDefinition } from '../../../utils/stringUtils';

interface NewsPressListProps {
  isHighlight: boolean;
}

export const NewsPressList: FC<NewsPressListProps> = ({ isHighlight }) => {
  const dispatch = useDispatch();
  const [hasMore, setHasMore] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const { pressSelection, highlightSelection } = useSelector(
    newsSelectionSelector
  );

  const limit = 15;
  const { data, loading, fetchMore, refetch } = useListPress(
    isHighlight ? PressType.HIGHLIGHT : PressType.RELEASE,
    limit
  );

  useEffect(() => {
    setHasMore(data.length > 0 && data.length % limit === 0);
  }, [data]);

  useEffect(() => {
    if (loading) {
      dispatch(addLoading());
    } else {
      dispatch(removeLoading());
    }

    return () => {
      dispatch(removeLoading());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idsToDelete, setIdsToDelete] = useState<string[]>([]);

  const isSelected = useCallback(
    (id: string) =>
      isHighlight
        ? highlightSelection.some((idI) => idI === id)
        : pressSelection.some((idI) => idI === id),
    [pressSelection, highlightSelection, isHighlight]
  );

  const { dispatchNotificationBanner } = useNotificationBanner();

  const [getPressDetail] = useLazyQueryHook({ queryGql: GET_PRESS_DETAIL });
  const [currentPressDetail, setCurrentPressDetail] =
    useState<PressDetail | null>(null);

  const copy = (id: string) => {
    dispatch(addLoading());
    getPressDetail({
      variables: {
        id,
      },
    })
      .then((res: any) => {
        setCurrentPressDetail(
          res.data[firstLowerCaseGqlDefinition(GET_PRESS_DETAIL)]
        );
      })
      .finally(() => dispatch(removeLoading()));
    setShowCopyModal(true);
  };

  const pdf = (id: string) => {
    dispatch(addLoading());
    getPressDetail({
      variables: {
        id,
      },
    }).then((res: any) => {
      const pressDetail: PressDetail =
        res.data[firstLowerCaseGqlDefinition(GET_PRESS_DETAIL)];
      const doc = new jsPDF({
        unit: 'pt',
        putOnlyUsedFonts: true,
        compress: true,
      });
      //console.log(doc.getFontList()['poppins']);
      doc.setFont('poppins');
      let cats: string[] = [];
      pressDetail.news?.forEach((news) => {
        const cat = news.category ? news.category : t('Nessuna categoria');
        if (!cats.includes(cat)) cats.push(cat);
      });
      doc.html(createHtmlPDFFromPress(pressDetail, cats, t), {
        async callback(doc) {
          await doc.save(pressDetail.summary.title);
          dispatch(removeLoading());
        },
        width: 550,
        windowWidth: 900,
        margin: [20, 20, 20, 20],
      });
    });
  };

  const { t } = useTranslation();

  const categories = useMemo((): string[] => {
    let ret: string[] = [];
    currentPressDetail?.news?.forEach((news) => {
      const cat = news.category ? news.category : t('Nessuna categoria');
      if (!ret.includes(cat)) ret.push(cat);
    });
    return ret;
  }, [currentPressDetail?.news, t]);
  jsPDF.API.events.push(['addFonts', callAddFont]);

  return (
    <>
      <div style={{ backgroundColor: '#FAFAFA' }} className="h-full">
        <div
          style={{ backgroundColor: '#FAFAFA' }}
          className="flex flex-col h-screen w-full items-center justify-around"
        >
          <div
            className="h-12 w-full pl-3 py-2 mt-1 flex items-center justify-between bg-white"
            style={{ boxShadow: '2px 0px 8px rgba(0, 0, 0, 0.1)' }}
          >
            <div className="flex items-center">
              <NavigationBackComponent />
              <SvgIcon svg={<Divider className="m-1" />} />
              <Text
                text={isHighlight ? 'Rassegne salvate' : 'Raccolte salvate'}
                className="ml-3 font-semibold"
              />
            </div>
            <div className="flex items-center justify-end space-x-3 mr-5">
              {/*filtri?*/}
            </div>
          </div>
          <div
            className="flex h-full w-full py-10 px-20 rounded-xl flex-col scrollbar overflow-y-auto mt-0.5 mb-4"
            id="containerPress"
          >
            {data.length > 0 && (
              <InfiniteScroll
                dataLength={data.length} //This is important field to render the next data
                next={fetchMore}
                hasMore={hasMore}
                loader={
                  <p className="text-center">
                    <Text text="Caricamento" className="font-bold" />
                    ...
                  </p>
                }
                endMessage={
                  <p className="text-center">
                    <Text text="Fine risultati" className="font-bold" />
                  </p>
                }
                scrollableTarget={'containerPress'}
              >
                {data.map((press) => (
                  <div
                    className="flex py-4 px-4 flex-row max-w-full rounded justify-between m-1 bg-white items-center"
                    style={{
                      border: '1px solid white',
                    }}
                    key={press.id}
                  >
                    <div className="flex items-center my-0 mx-1">
                      <div className="flex items-center my-0 mx-1">
                        <SvgIcon
                          svg={
                            isSelected(press.id) ? (
                              <CheckboxSelected />
                            ) : (
                              <CheckboxUnselected />
                            )
                          }
                          className="w-6 h-6"
                          pointer={true}
                          onClick={() => {
                            if (isSelected(press.id)) {
                              isHighlight
                                ? dispatch(
                                    setHighlightSelection(
                                      highlightSelection.filter(
                                        (id) => id !== press.id
                                      )
                                    )
                                  )
                                : dispatch(
                                    setPressSelection(
                                      pressSelection.filter(
                                        (id) => id !== press.id
                                      )
                                    )
                                  );
                            } else {
                              isHighlight
                                ? dispatch(
                                    setHighlightSelection([
                                      ...highlightSelection,
                                      press.id,
                                    ])
                                  )
                                : dispatch(
                                    setPressSelection([
                                      ...pressSelection,
                                      press.id,
                                    ])
                                  );
                            }
                          }}
                        />
                      </div>
                      <div className="flex flex-col cursor-pointer pl-2">
                        <Link
                          to={
                            '/newswire/' +
                            (press.type === PressType.HIGHLIGHT
                              ? 'highlights/'
                              : 'newspress/') +
                            press.id
                          }
                          style={{
                            textDecoration: 'none',
                            color: 'inherit',
                            cursor: 'pointer',
                          }}
                          key={'link' + press.id}
                        >
                          <Text
                            text={press.title}
                            skipTranslation={true}
                            style={{
                              fontSize: '1.125rem',
                              lineHeight: '1.75rem',
                            }}
                            className="font-semibold"
                          />
                          <div style={{ color: '#9C9D9D' }}>
                            <Text
                              text={press.draft ? 'Bozza' : 'Salvato'}
                              style={{
                                fontSize: '0.875rem',
                                lineHeight: '1.25rem',
                                color: press.draft ? 'red' : 'green',
                              }}
                            />
                            &nbsp;
                            {isToday(
                              press.draft
                                ? (press.draftDate as any)
                                : (press.savedDate as any)
                            ) ? (
                              <Text
                                text={'oggi'}
                                style={{
                                  fontSize: '0.875rem',
                                  lineHeight: '1.25rem',
                                }}
                              />
                            ) : isYesterday(
                                press.draft
                                  ? (press.draftDate as any)
                                  : (press.savedDate as any)
                              ) ? (
                              <Text
                                text={'ieri'}
                                style={{
                                  fontSize: '0.875rem',
                                  lineHeight: '1.25rem',
                                }}
                              />
                            ) : (
                              <span>
                                <Text
                                  text={'il'}
                                  style={{
                                    fontSize: '0.875rem',
                                    lineHeight: '1.25rem',
                                  }}
                                />
                                &nbsp;
                                <Text
                                  text={getDateAsString(
                                    press.draft
                                      ? (press.draftDate as any)
                                      : (press.savedDate as any)
                                  )}
                                  skipTranslation={true}
                                  style={{
                                    fontSize: '0.875rem',
                                    lineHeight: '1.25rem',
                                  }}
                                />
                              </span>
                            )}
                            &nbsp;
                            <Text
                              text={'alle'}
                              style={{
                                fontSize: '0.875rem',
                                lineHeight: '1.25rem',
                              }}
                            />
                            &nbsp;
                            <span>
                              <Text
                                text={getHoursAndMinutesAsString(
                                  press.draft
                                    ? (press.draftDate as any)
                                    : (press.savedDate as any)
                                )}
                                skipTranslation={true}
                                style={{
                                  fontSize: '0.875rem',
                                  lineHeight: '1.25rem',
                                }}
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="flex flex-row space-x-4">
                      <SvgIcon
                        svg={
                          <TrashNoTranslate
                            style={{ fill: '#5B84EF' }}
                            className="h-5 w-5"
                          />
                        }
                        pointer={true}
                        onClick={() => {
                          setIdsToDelete([press.id]);
                          setShowDeleteModal(true);
                        }}
                      />
                      <SvgIcon
                        svg={<Download />}
                        pointer={true}
                        onClick={() => pdf(press.id)}
                      />
                      <SvgIcon
                        svg={
                          <Copy
                            style={{ fill: press.draft ? '#ccc' : '#5B84EF' }}
                          />
                        }
                        pointer={true}
                        onClick={() => (press.draft ? {} : copy(press.id))}
                      />
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            )}
            {data.length === 0 && !loading && (
              <div className="flex h-full w-11/12 rounded-xl flex-col justify-center items-center">
                <Text
                  text="Qui appariranno gli elementi salvati"
                  style={{
                    fontSize: '1.25rem',
                    lineHeight: '1.75rem',
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {showCopyModal && (
        <Modal
          title={'Copia negli appunti'}
          showCloseButton={true}
          onClickCloseButton={() => setShowCopyModal(false)}
          footer={
            <>
              <Button color="secondary" onClick={() => setShowCopyModal(false)}>
                <Text text="Annulla" />
              </Button>
              <CopyToClipboard
                text={
                  currentPressDetail
                    ? createHtmlEmailFromPress(
                        currentPressDetail,
                        categories,
                        t
                      )
                    : ''
                }
                onCopy={() => {
                  dispatchNotificationBanner({
                    title: 'Operazione completata',
                    text: 'Contenuto copiato negli appunti',
                    ok: true,
                  });
                  setShowCopyModal(false);
                }}
                options={{ format: 'text/html' }}
              >
                <Button color="primary" className="ml-4">
                  <Text text="Copia" />
                </Button>
              </CopyToClipboard>
            </>
          }
        >
          <div className="flex flex-col" style={{ width: '400px' }}>
            <div className="flex mt-3">
              <Text text="Confermi di voler copiare?" />
            </div>
          </div>
        </Modal>
      )}
      <ModalDeletePresses
        idsToDelete={idsToDelete}
        isHighlight={isHighlight}
        refetch={refetch}
        setIdsToDelete={setIdsToDelete}
        setShowDeleteModal={setShowDeleteModal}
        showDeleteModal={showDeleteModal}
      />
      <ContextualMenuPressList isHighlight={isHighlight} refetch={refetch} />
    </>
  );
};
