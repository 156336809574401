import { PressDetail, PressType } from '@nwa/graphql';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GET_CURRENT_PRESS_DETAIL } from '../../graphql/news/queries';
import {
  addNewsInHighlight,
  addNewsInPress,
  setCurrentHighlight,
  setCurrentPressReview,
} from '../../redux/newsSelection/actions';
import { firstLowerCaseGqlDefinition } from '../../utils/stringUtils';
import { useQuery } from '@apollo/client';

export const useGetCurrentPressDetail = (
  pressType: PressType
): {
  data: any;
  refetch: any;
  loading: boolean;
} => {
  const dispatch = useDispatch();

  const { data, refetch, loading } = useQuery(GET_CURRENT_PRESS_DETAIL, {
    variables: {
      type: pressType,
    },
  });
  useEffect(() => {
    if (data && data[firstLowerCaseGqlDefinition(GET_CURRENT_PRESS_DETAIL)]) {
      const newPress = data[
        firstLowerCaseGqlDefinition(GET_CURRENT_PRESS_DETAIL)
      ] as PressDetail;
      if (pressType === PressType.RELEASE) {
        dispatch(setCurrentPressReview(newPress));
        dispatch(
          addNewsInPress(
            newPress.news
              ?.filter((news) => news.newsId)
              .map((news) => news.newsId!) || []
          )
        );
      } else if (pressType === PressType.HIGHLIGHT) {
        dispatch(setCurrentHighlight(newPress));
        dispatch(
          addNewsInHighlight(
            newPress.news
              ?.filter((news) => news.newsId)
              .map((news) => news.newsId!) || []
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch]);

  return { data, refetch, loading };
};
