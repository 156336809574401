// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper';
import { FC } from 'react';

interface SliderComponentProps {
  images: string[];
}
export const SliderComponent: FC<SliderComponentProps> = ({ images }) => {
  return (
    <>
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        {images.map((image) => (
          <>
            <SwiperSlide key={image}>
              <a href={image} target="_blank" rel="noreferrer">
                <img
                  className="object-fill w-full h-auto"
                  src={image}
                  alt={image}
                />
              </a>
            </SwiperSlide>
          </>
        ))}
      </Swiper>
    </>
  );
};
