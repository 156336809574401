import { FunctionComponent, HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';
import { utilsSelector } from '../redux/utils/selectors';
import { handleTextSize } from '../utils/stringUtils';

interface DivWithHtmlProps extends HTMLAttributes<HTMLDivElement> {
  html: string;
}

export const DivWithHtml: FunctionComponent<DivWithHtmlProps> = ({
  html,
  ...props
}) => {
  const { textSize } = useSelector(utilsSelector);

  if (!props.style) props.style = {};
  if (!props.style.lineHeight) props.style.lineHeight = '1.5rem';

  return (
    <div
      {...props}
      style={handleTextSize(props.style, textSize)}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};
