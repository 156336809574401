import { forwardRef } from 'react';

export const Button = forwardRef(
  ({ color, children, className, onClick, isDisabled, ...props }: any, ref) => (
    <button
      ref={ref}
      className={`${style.default} ${style.color[color]} ${className}`}
      onClick={() => (isDisabled ? {} : onClick())}
      disabled={isDisabled}
      {...props}
    >
      {children}
    </button>
  )
);

Button.displayName = 'Button';

/* You can replace those colors with your own*/
const style: any = {
  default: `text-white focus:outline-none shadow rounded px-5 py-1.5 font-medium transition ease-in duration-200 disabled:cursor-not-allowed`,
  color: {
    primary: `bg-blue-500 ring-1 ring-blue-500 disabled:bg-blue-200 disabled:ring-blue-100`,
    secondary: `bg-white text-blue-500 ring-1 ring-blue-500 disabled:bg-gray-100 disabled:ring-blue-300 disabled:text-gray-300` /*
    primary: `bg-blue-500 focus:ring-2 focus:ring-offset-2 focus:ring-blue-700 focus:ring-offset-blue-100`,
    secondary: `bg-white focus:ring-2 focus:ring-offset-2 focus:ring-blue-700 focus:ring-offset-blue-100 text-blue-500 ring-1 
    ring-blue-500`,*/,
    success: `bg-green-700 focus:ring-2 focus:ring-offset-2 focus:ring-green-700 focus:ring-offset-green-100 disabled:opacity-75`,
    danger: `bg-red-600 focus:ring-2 focus:ring-offset-2 focus:ring-red-600 focus:ring-offset-red-100 disabled:opacity-75`,
    dark: `bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 focus:ring-offset-gray-100 disabled:opacity-75`,
    warning: `bg-yellow-500 focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 focus:ring-offset-yellow-100 disabled:opacity-75`,
    indigo: `bg-indigo-900 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-900 focus:ring-offset-indigo-100 disabled:opacity-75`,
  },
};
