import { ReactComponent as ADNKRONOS } from '../svg/icons/Logo/ADN.svg';
import { ReactComponent as AGI } from '../svg/icons/Logo/Agi.svg';
import { ReactComponent as ANSA } from '../svg/icons/Logo/Ansa.svg';
import { ReactComponent as ASKANEWS } from '../svg/icons/Logo/Askanews.svg';
import { ReactComponent as DIRE } from '../svg/icons/Logo/Dire.svg';
import { ReactComponent as DOW_JONES } from '../svg/icons/Logo/DowJones.svg';
import { ReactComponent as TELEBORSA } from '../svg/icons/Logo/Teleborsa.svg';
import { ReactComponent as RADIOCOR } from '../svg/icons/Logo/Radiocor.svg';
import { ReactComponent as REUTERS } from '../svg/icons/Logo/Reuters.svg';
import { ReactComponent as MF } from '../svg/icons/Logo/MF.svg';
import { ReactComponent as NOICON } from '../svg/icons/Logo/Placeholder.svg';

export const providers = {
  ADNKRONOS: <ADNKRONOS className="w-5 h-5" />,
  AGI: <AGI className="w-5 h-5" />,
  ANSA: <ANSA className="w-5 h-5" />,
  ASKANEWS: <ASKANEWS className="w-5 h-5" />,
  DIRE: <DIRE className="w-5 h-5" />,
  DOW_JONES: <DOW_JONES className="w-5 h-5" />,
  RADIOCOR: <RADIOCOR className="w-5 h-5" />,
  REUTERS: <REUTERS className="w-5 h-5" />,
  MF_DOW_JONES: <MF className="w-5 h-5" />,
  TELEBORSA: <TELEBORSA className="w-5 h-5" />,
  NONE: <NOICON className="w-5 h-5" />,
};
