import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentNode } from 'graphql';
import { useNotificationBanner } from './useNotification';

export const useMutationHook = ({
  errorMessage,
  successMessage,
  queryGql,
  disableSuccessMessage,
}: {
  queryGql: DocumentNode;
  successMessage?: string;
  errorMessage?: string;
  disableSuccessMessage?: boolean;
}): any => {
  const { t } = useTranslation();
  const { dispatchNotificationBanner } = useNotificationBanner();
  const [mutationFunction, { loading, error, data }] = useMutation(queryGql, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (error) {
      dispatchNotificationBanner({
        text: t(errorMessage || 'errorGenericMessage'),
        ok: false,
        title: t('errorGenericTitle'),
      });
    }
    if (data && !disableSuccessMessage) {
      dispatchNotificationBanner({
        text: successMessage ? t(successMessage) : undefined,
        ok: true,
        title: t('operationSuccessfully'),
      });
    }
    // eslint-disable-next-line
  }, [error, data]);

  return [mutationFunction, { data, loading, error }];
};
