import chroma from 'chroma-js';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, {
  ActionMeta,
  components,
  MenuPlacement,
  MultiValue,
  StylesConfig,
} from 'react-select';
import {
  setClickAfterForceUnfocus,
  setCurrentFilterFocused,
} from '../redux/utils/actions';
import { utilsSelector } from '../redux/utils/selectors';
import { workingAreasSelector } from '../redux/workingAreas/selectors';
import { SvgIcon } from './SvgIcon';
import { Text } from './Text';
import { Check } from '../svg';
//import { Input } from './Input';

interface GenericMultiSelectProps {
  id: string;
  options: GenericMultiSelectPropsOptions[];
  onChange: (
    newValue: MultiValue<GenericMultiSelectPropsOptions>,
    actionMeta: ActionMeta<GenericMultiSelectPropsOptions>
  ) => void;
  defaultValueIds?: string[];
  actions?: GenericMultiSelectPropsOptions[];
  //inputs?: GenericMultiSelectPropsOptions[];
  placeholder?: string;
  isDisabled?: boolean;
  onMenuOpen?: () => void;
  allowOnlyOneValue?: boolean;
  menuRight?: boolean;
  showSelectedOption: boolean;
  menuPlacement?: MenuPlacement;
}

interface GenericMultiSelectPropsOptions {
  label: string;
  value: string;
  count?: number;
  Icon?: any;
  onClick?: (...args: any[]) => void;
  isDisabled?: boolean;
  color?: string;
}

const ActionOption: FC<GenericMultiSelectPropsOptions> = ({
  value,
  label,
  count,
  Icon,
  onClick,
  isDisabled,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        cursor: isDisabled ? 'default' : 'pointer',
        padding: '8px 12px',
        color: isDisabled ? '#ccc' : 'black',
      }}
      onClick={isDisabled ? () => {} : onClick}
    >
      {Icon && (
        <SvgIcon
          svg={Icon}
          style={{
            fill: isDisabled ? '#ccc' : 'black',
          }}
        />
      )}
      <div
        style={{
          marginLeft: Icon ? '10px' : '0px',
        }}
      >
        <Text
          text={label}
          style={{
            color: isDisabled ? '#ccc' : 'black',
            fontStyle: 'italic',
          }}
        />
      </div>
      {typeof count !== 'undefined' && (
        <div className="text-white bg-red-500 h-3 w-3 ml-1 leading-3 text-center rounded">
          <Text
            text={`${count}`}
            skipTranslation={true}
            style={{
              fontSize: '0.75rem',
              lineHeight: '1rem',
            }}
          />
        </div>
      )}
    </div>
  );
};

// Tentativo di creare una input sotto alle option in cui si potesse inserire
// testo e fare un'azione, ma non si riesce a dargli il focus e se premo backspace
// elimino il valore della select
/*const InputOption: FC<GenericMultiSelectPropsOptions> = ({
  value,
  label,
  Icon,
  onClick,
  isDisabled,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        padding: '8px 12px',
        color: isDisabled ? '#ccc' : 'black',
      }}
    >
      <div
        style={{
          marginLeft: Icon ? '10px' : '0px',
        }}
      >
        <Input
          placeholder={label}
          Icon={Icon}
          onClick={
            isDisabled
              ? () => {}
              : (...args: any[]) => onClick && onClick(...args)
          }
        />
      </div>
    </div>
  );
};*/

export const FormatOptionLabel: FC<GenericMultiSelectPropsOptions> = ({
  value,
  label,
  count,
  Icon,
  isDisabled,
  onClick,
  color,
}) => {
  return (
    <div
      className="flex items-center"
      style={{
        cursor: isDisabled ? 'default' : 'pointer',
      }}
      onClick={isDisabled ? () => {} : onClick}
    >
      {Icon && (
        <SvgIcon
          svg={Icon}
          style={{
            fill: isDisabled ? '#ccc' : 'black',
          }}
        />
      )}
      {color ? (
        <div className="flex w-0.5 h-6" style={{ backgroundColor: color }}>
          &nbsp;
        </div>
      ) : (
        ''
      )}
      <div
        style={{
          cursor: isDisabled ? 'default' : 'pointer',
          color: isDisabled ? '#ccc' : 'black',
          marginLeft: Icon || color ? '10px' : '0px',
        }}
      >
        <div
          style={{
            textDecoration: 'none',
            color: 'inherit',
            cursor: isDisabled ? 'default' : 'pointer',
          }}
        >
          <Text
            text={label}
            style={{
              color: isDisabled ? '#ccc' : 'black',
            }}
          />
        </div>
      </div>
      {typeof count !== 'undefined' && (
        <div className="text-white bg-red-500 h-3 w-3 ml-1 leading-3 text-center rounded">
          <Text
            text={`${count}`}
            skipTranslation={true}
            style={{
              fontSize: '0.75rem',
              lineHeight: '1rem',
            }}
          />
        </div>
      )}
    </div>
  );
};

const ValueContainer = ({ children, ...props }: any) => {
  const length = (children && children[0] && children[0].length) || 0;
  let tmpChildren = [...children];
  if (length > 2) {
    tmpChildren[0] = [];
    tmpChildren[0][0] = children[0][0];
    tmpChildren[0][1] = children[0][1];
  }
  return (
    <components.ValueContainer {...props}>
      {tmpChildren}
      {length > 2 && (
        <>
          <div className="text-gray-500">
            <Text
              text="..."
              skipTranslation={true}
              style={{
                fontSize: '0.625rem',
                lineHeight: '0.75rem',
              }}
            />
          </div>
          <div className="text-white bg-gray-500 h-3 w-3 ml-1 leading-3 text-center rounded">
            <Text
              text={`${length - 2}`}
              skipTranslation={true}
              style={{
                fontSize: '0.75rem',
                lineHeight: '1rem',
              }}
            />
          </div>
        </>
      )}
    </components.ValueContainer>
  );
};

const MultiValueContainerOneValue = (
  { children, placeholder }: any,
  showSelectedOption: boolean
) => (
  <div>{showSelectedOption ? children[0].props.data.label : placeholder}</div>
);

const InputOneValue = (props: any) =>
  props.selectProps.value.length === 1 ? (
    <></>
  ) : (
    <components.Input {...props} />
  );

const MenuList = (
  props: any,
  actions: GenericMultiSelectPropsOptions[] | undefined
  //inputs: GenericMultiSelectPropsOptions[] | undefined
) => {
  return (
    <components.MenuList {...props}>
      {props.children}
      {actions && (
        <>
          <hr />
          {actions.map((action) => (
            <ActionOption {...action} key={action.value} />
          ))}
        </>
      )}
      {/*inputs && (
        <>
          <hr />
          {inputs.map((input) => (
            <InputOption {...input} key={input.value} />
          ))}
        </>
          )*/}
    </components.MenuList>
  );
};

export const GenericMultiSelect: FC<GenericMultiSelectProps> = ({
  id,
  onChange,
  options,
  defaultValueIds,
  actions,
  //inputs,
  placeholder,
  isDisabled,
  onMenuOpen,
  allowOnlyOneValue,
  menuRight,
  showSelectedOption,
  menuPlacement,
}) => {
  const [currentValue, setCurrentValue] = useState<
    MultiValue<GenericMultiSelectPropsOptions>
  >([]);
  useEffect(() => {
    if (defaultValueIds)
      setCurrentValue(
        options.filter(
          (option) =>
            defaultValueIds.filter((id) => option.value === id).length > 0
        )
      );
  }, [defaultValueIds, options]);

  const colourStyles: StylesConfig<GenericMultiSelectPropsOptions, true> = {
    menuList: (styles) => ({
      ...styles,
      height: '100%',
      overflowY: 'auto',
      scrollBehavior: 'smooth',

      '::-webkit-scrollbar': {
        width: '0.5rem',
        height: '0px',
        display: 'block',
      },
      '::-webkit-scrollbar-track': {
        background: 'rgba(177, 180, 187, 0.5)',
        borderRadius: '5px',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgb(175, 179, 185)',
        borderRadius: '14px',
        border: '3px solid rgba(177, 180, 187, 0.5)',
      },
    }),
    control: (styles, { isFocused }) => ({
      ...styles,
      border: '1px solid #cccccc',
      '&:active': {
        border: '1px solid #3B82F6 ',
      },
      '&:hover': {
        border: '1px solid #AAA8A8',
      },
      boxShadow:
        isFocused && !forceUnfocus && currentFilterFocused === id
          ? '0 0 0 1px #2684ff'
          : 'none',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      if (data.color) {
        const color = chroma(data.color);
        return {
          ...styles,
          backgroundColor: isDisabled
            ? undefined
            : isSelected
            ? data.color
            : isFocused
            ? color.alpha(0.1).css()
            : undefined,
          color: isDisabled
            ? '#ccc'
            : isSelected
            ? chroma.contrast(color, 'white') > 2
              ? 'white'
              : 'black'
            : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default',

          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled
              ? isSelected
                ? '#FFE2E8'
                : color.alpha(0.3).css()
              : undefined,
          },
        };
      } else
        return {
          ...styles,
          backgroundColor: isDisabled
            ? undefined
            : isFocused
            ? '#FFE2E8'
            : isSelected
            ? 'transparent'
            : undefined,
          color: isDisabled ? '#ccc' : isSelected ? 'inherit' : 'inherit',
          cursor: isDisabled ? 'not-allowed' : 'default',

          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled
              ? isSelected
                ? 'inherit'
                : 'inherit'
              : undefined,
          },
        };
    },
    multiValue: (styles, { data }) => {
      if (data.color) {
        const color = chroma(data.color);
        return {
          ...styles,
          backgroundColor: color.alpha(0.1).css(),
        };
      } else {
        return styles;
      }
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
    menu: (styles) => ({
      ...styles,
      width: 'max-content',
      minWidth: '100%',
      right: menuRight ? 0 : 'initial',
      zIndex: 270,
    }),
    dropdownIndicator: (styles) => ({ ...styles, paddingLeft: '2px' }),
    placeholder: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? '#ccc' : '#454343',
    }),
    indicatorSeparator: () => ({}),
  };
  const { draftWorkingArea } = useSelector(workingAreasSelector);
  const { clickAfterForceUnfocus, currentFilterFocused, isMobile } =
    useSelector(utilsSelector);
  const dispatch = useDispatch();
  const [forceUnfocus, setForceUnfocus] = useState(false);
  useEffect(() => {
    setForceUnfocus(true);
    dispatch(setClickAfterForceUnfocus(true));
    dispatch(setCurrentFilterFocused(undefined));
  }, [draftWorkingArea, dispatch]);
  useEffect(() => {
    if (!clickAfterForceUnfocus) {
      setForceUnfocus(false);
    }
  }, [clickAfterForceUnfocus]);

  //mobile

  return isMobile ? (
    <div className="flex flex-col space-y-1">
      {options.map(({ value, label, color, Icon }) => (
        <div
          key={value + id}
          className="flex items-center px-4 py-1"
          style={{
            cursor: isDisabled ? 'default' : 'pointer',
            borderRadius: '0.3125rem',
            background: currentValue.find((el) => el.value === value)
              ? 'rgba(255, 223, 223, 0.50)'
              : '',
          }}
          onClick={() => {
            let newValue = [...currentValue];
            const index = newValue.findIndex((el) => el.value === value);
            if (index > -1) {
              newValue = newValue.filter((el) => el.value !== value);
            } else {
              newValue = newValue.concat([{ value, label }]);
            }
            setCurrentValue(newValue);
            onChange(newValue, null as any);
          }}
        >
          {Icon && (
            <SvgIcon
              svg={Icon}
              style={{
                fill: isDisabled ? '#ccc' : 'black',
              }}
            />
          )}
          {color ? (
            <div className="flex w-0.5 h-6" style={{ backgroundColor: color }}>
              &nbsp;
            </div>
          ) : (
            ''
          )}
          <div
            style={{
              cursor: isDisabled ? 'default' : 'pointer',
              color: isDisabled ? '#ccc' : 'black',
              marginLeft: Icon || color ? '10px' : '0px',
            }}
            className="w-full"
          >
            <div
              style={{
                textDecoration: 'none',
                color: 'inherit',
                cursor: isDisabled ? 'default' : 'pointer',
              }}
              className="flex items-center justify-between w-full"
            >
              <Text
                text={label}
                style={{
                  color: isDisabled ? '#ccc' : 'black',
                }}
              />
              {currentValue.find((el) => el.value === value) && (
                <SvgIcon
                  svg={<Check />}
                  style={{
                    fill: isDisabled ? '#ccc' : 'black',
                  }}
                />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <Select
      options={options}
      menuPlacement={menuPlacement || 'auto'}
      components={{
        MenuList: (props) => MenuList(props, actions), //, inputs),
        ValueContainer: (props) => ValueContainer(props),
        MultiValueContainer: (props) =>
          allowOnlyOneValue
            ? MultiValueContainerOneValue(props, showSelectedOption)
            : components.MultiValueContainer(props),
        Input: (props) =>
          allowOnlyOneValue ? InputOneValue(props) : components.Input(props),
        ClearIndicator: (props) =>
          showSelectedOption ? components.ClearIndicator(props) : null,
      }}
      formatOptionLabel={(data, formatOptionLabelMeta) =>
        FormatOptionLabel({ ...data }, formatOptionLabelMeta)
      }
      onChange={(
        newValue: MultiValue<GenericMultiSelectPropsOptions>,
        actionMeta: ActionMeta<GenericMultiSelectPropsOptions>
      ) => {
        if (!allowOnlyOneValue || newValue.length < 2)
          setCurrentValue(newValue);
        onChange(newValue, actionMeta);
      }}
      value={currentValue}
      isSearchable={true}
      placeholder={placeholder ? placeholder : ''}
      isMulti={true}
      styles={colourStyles}
      isDisabled={isDisabled}
      onMenuOpen={() => {
        onMenuOpen && onMenuOpen();
        dispatch(setClickAfterForceUnfocus(false));
        dispatch(setCurrentFilterFocused(id));
      }}
    />
  );
};
