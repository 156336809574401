import { createReducer, current } from '@reduxjs/toolkit';
import {
  addLoading,
  decreaseTextSize,
  increaseTextSize,
  removeLoading,
  setClickAfterForceUnfocus,
  setCurrentFilterFocused,
  setDockSizes,
  setInWorkingArea,
  setIsAWindowMaximized,
  setIsMobile,
  setShowFilterModalMobile,
  setShowNewsSearchModal,
  setTranslationsIds,
} from './actions';
import { useMediaQuery } from '@react-hook/media-query';

export interface UtilsState {
  loading: number;
  inWorkingArea: boolean;
  showNewsSearchModal: boolean;
  showFilterModalMobile: boolean;
  textSize: number;
  isTextSizeMax: boolean;
  isTextSizeMin: boolean;
  isAWindowMaximized: boolean;
  dockSizes: { [key: string]: number };
  clickAfterForceUnfocus: boolean;
  currentFilterFocused: string | undefined;
  isMobile: boolean;
  translationsIds: string[];
}

const textDelta = 0.02;
const maxTextSize = 1.32;
const minTextSize = 0.8;

const defaultState: UtilsState = {
  loading: 0,
  inWorkingArea: false,
  showNewsSearchModal: false,
  showFilterModalMobile: false,
  textSize: localStorage.getItem('textSize')
    ? Number.parseFloat(localStorage.getItem('textSize')!)
    : 1,
  isTextSizeMax: localStorage.getItem('textSize')
    ? Number.parseFloat(localStorage.getItem('textSize')!) >= maxTextSize
    : false,
  isTextSizeMin: localStorage.getItem('textSize')
    ? Number.parseFloat(localStorage.getItem('textSize')!) <= minTextSize
    : false,
  isAWindowMaximized: false,
  dockSizes: {},
  clickAfterForceUnfocus: false,
  currentFilterFocused: undefined,
  isMobile: false,
  translationsIds: [],
};

export const utilsReducer = createReducer<UtilsState>(defaultState, {
  [setTranslationsIds.type]: (state, action): UtilsState => ({
    ...state,
    translationsIds: action.payload,
  }),
  [setShowFilterModalMobile.type]: (state, action): UtilsState => ({
    ...state,
    showFilterModalMobile: action.payload,
  }),
  [setIsMobile.type]: (state): UtilsState => ({
    ...state,
    isMobile: useMediaQuery('only screen and (max-width: 440px)'),
  }),
  [addLoading.type]: (state): UtilsState => ({
    ...state,
    loading: state.loading + 1,
  }),
  [removeLoading.type]: (state): UtilsState => ({
    ...state,
    loading: Math.max(state.loading - 1, 0),
  }),
  [setCurrentFilterFocused.type]: (state, action): UtilsState => ({
    ...state,
    currentFilterFocused: action.payload,
  }),
  [setClickAfterForceUnfocus.type]: (state, action): UtilsState => ({
    ...state,
    clickAfterForceUnfocus: action.payload,
  }),
  [setInWorkingArea.type]: (state, action): UtilsState => ({
    ...state,
    inWorkingArea: action.payload,
  }),
  [setShowNewsSearchModal.type]: (state, action): UtilsState => ({
    ...state,
    showNewsSearchModal: action.payload,
  }),
  [setIsAWindowMaximized.type]: (state, action): UtilsState => ({
    ...state,
    isAWindowMaximized: action.payload,
  }),
  [setDockSizes.type]: (state, action): UtilsState => ({
    ...state,
    dockSizes: action.payload,
  }),
  [increaseTextSize.type]: (state): UtilsState => {
    const newTextSize = Math.min(
      Number.parseFloat((current(state).textSize + textDelta).toFixed(2)),
      maxTextSize
    );
    localStorage.setItem('textSize', newTextSize.toString());
    console.log('newTextSize', newTextSize);
    return {
      ...state,
      textSize: newTextSize,
      isTextSizeMax: newTextSize === maxTextSize,
      isTextSizeMin: false,
    };
  },
  [decreaseTextSize.type]: (state): UtilsState => {
    const newTextSize = Math.max(
      Number.parseFloat((current(state).textSize - textDelta).toFixed(2)),
      minTextSize
    );
    localStorage.setItem('textSize', newTextSize.toString());
    console.log('newTextSize', newTextSize);
    return {
      ...state,
      textSize: newTextSize,
      isTextSizeMax: false,
      isTextSizeMin: newTextSize === minTextSize,
    };
  },
});
