import { PressType } from '@nwa/graphql';
import { FC, useEffect } from 'react';
import { NEW_CURRENT_PRESS } from '../../graphql/news/mutation';
import { Edit, ListCheck, Saved } from '../../svg';
import { GenericSelect } from '../GenericSelect';
import { useGetCurrentPressDetail } from '../hooks/useInitialPressData';
import { useTranslation } from 'react-i18next';
import { newsSelectionSelector } from '../../redux/newsSelection/selectors';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';

export const PressSelector: FC = () => {
  const {
    data: dataRelease,
    refetch: refetchRelease,
    loading: loadingRelease,
  } = useGetCurrentPressDetail(PressType.RELEASE);
  const {
    data: dataHighlight,
    refetch: refetchHighlight,
    loading: loadingHighlight,
  } = useGetCurrentPressDetail(PressType.HIGHLIGHT);
  const [newPressMutation] = useMutation(NEW_CURRENT_PRESS);
  const { t } = useTranslation();
  const { currentPressReview, currentHighlight, newsInHighlight, newsInPress } =
    useSelector(newsSelectionSelector);

  useEffect(() => {
    refetchRelease();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsInPress]);

  useEffect(() => {
    refetchHighlight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsInHighlight]);

  useEffect(() => {
    if (!loadingRelease && !dataRelease) {
      // devo controllare che il loading sia false perchè vuol dire che ha effettivamente fatto la chiamata
      // altrimenti viene eseguito prima che arrivi il risultato falsando il controllo
      newPressMutation({
        variables: {
          type: PressType.RELEASE,
          detail: {
            title: t('Raccolta'),
            indexEnabled: localStorage.getItem('PRESS_SHOW_INDEX') === '1',
            imagesEnabled: localStorage.getItem('PRESS_SHOW_IMAGES') === '1',
            previewVideoEnabled: false,
            backgroundEnabled:
              localStorage.getItem('PRESS_SHOW_BACKGROUND') === '1',
            news: [],
          },
        },
      }).then(() => {
        refetchRelease();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRelease, loadingRelease]);

  useEffect(() => {
    if (!loadingHighlight && !dataHighlight) {
      // devo controllare che il loading sia false perchè vuol dire che ha effettivamente fatto la chiamata
      // altrimenti viene eseguito prima che arrivi il risultato falsando il controllo
      newPressMutation({
        variables: {
          type: PressType.HIGHLIGHT,
          detail: {
            title: t('Rassegna'),
            indexEnabled: localStorage.getItem('PRESS_SHOW_INDEX') === '1',
            imagesEnabled: localStorage.getItem('PRESS_SHOW_IMAGES') === '1',
            previewVideoEnabled: false,
            backgroundEnabled:
              localStorage.getItem('PRESS_SHOW_BACKGROUND') === '1',
            news: [],
          },
        },
      }).then(() => {
        refetchHighlight();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataHighlight, loadingHighlight]);

  return (
    <>
      <GenericSelect
        id="Press"
        onMenuOpen={refetchRelease}
        options={[
          {
            label: 'In creazione',
            value: '0',
            Icon: <Edit className="-translate-y-0.5 fill-black" />,
            count: currentPressReview?.news?.length,
            to: 'newswire/newspress/' + currentPressReview?.summary.id,
          },
          {
            label: 'Storico',
            value: '1',
            Icon: <Saved className="-translate-y-0.5" />,
            to: 'newswire/newspress',
          },
        ]}
        showSelectionCircle={false}
        showSelectedOption={false}
        label={'raccolta'}
        disableBorder={true}
      />
      <GenericSelect
        id="HL"
        onMenuOpen={refetchHighlight}
        options={[
          {
            label: 'Elenco notizie',
            value: '0',
            Icon: <ListCheck className="translate-y-0.5" />,
            to: 'newswire/highlights/newsList',
          },
          {
            label: 'In creazione',
            value: '1',
            Icon: <Edit className="-translate-y-0.5 fill-black" />,
            count: currentHighlight?.news?.length,
            to: 'newswire/highlights/' + currentHighlight?.summary.id,
          },
          {
            label: 'Storico',
            value: '2',
            Icon: <Saved className="-translate-y-0.5" />,
            to: 'newswire/highlights',
          },
        ]}
        showSelectionCircle={false}
        showSelectedOption={false}
        label={'rassegna'}
        disableBorder={true}
      />
    </>
  );
};
