import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GenericMultiSelect } from '../GenericMultiSelect';
import { setProvidersFilter } from '../../redux/newsFilter/actions';
import { newsFilterSelector } from '../../redux/newsFilter/selectors';
import { filterChoicesSelector } from '../../redux/filterChoices/selectors';
import { FilterBoxChildrenProps } from '../FilterBox';
import { setWorkingAreaFilter } from '../../redux/workingAreas/actions';
import { Provider } from '@nwa/graphql';

export const ProvidersFilter: FC<FilterBoxChildrenProps> = ({ isDisabled }) => {
  const { providers } = useSelector(filterChoicesSelector);
  const dispatch = useDispatch();
  const newsFilter = useSelector(newsFilterSelector);
  const [defaultValueIds, setDefaultValueIds] = useState<string[]>([]);
  useEffect(() => {
    let tmp: string[] = [];
    if (newsFilter.providers && newsFilter.providers.length > 0) {
      providers.forEach((provider) => {
        newsFilter.providers?.forEach((providerFilter) => {
          if (provider.value === providerFilter) {
            tmp.push(provider.value);
          }
        });
      });
    }
    setDefaultValueIds(tmp);
  }, [newsFilter.providers, providers]);
  return (
    <GenericMultiSelect
      id="Providers"
      showSelectedOption={true}
      onChange={(newValue) => {
        const newProvidersMap = newValue.map((provider) => provider.value);
        dispatch(setProvidersFilter(newProvidersMap));
        dispatch(
          setWorkingAreaFilter({
            ...newsFilter,
            providers: newProvidersMap as Provider[],
          })
        );
      }}
      options={providers}
      placeholder={'Tutte le fonti'}
      defaultValueIds={defaultValueIds}
      isDisabled={isDisabled}
    />
  );
};
