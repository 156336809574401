import { gql } from '@apollo/client/core';
import {
  gqlNewsSummaryShape,
  gqlPressDetailShape,
  gqlPressSummaryShape,
  gqlWorkingAreaShape,
} from './shapes';

export const SEARCH_NEWS = gql`
  query SearchNews($filter: NewsFilter, $pagination: Pagination) {
    searchNews(filter: $filter, pagination: $pagination,) {
        ${gqlNewsSummaryShape}
    }
  }
`;

export const COUNT_CURRENT_PRESS_NEWS = gql`
  query CountCurrentPressNews($type: PressType) {
    countCurrentPressNews(type: $type)
  }
`;

export const COUNT_DAILY_PRESS_NEWS = gql`
  query CountDailyPressNews {
    countDailyPressNews
  }
`;

export const COUNT_NEWS = gql`
  query CountNews($filter: NewsFilter) {
    countNews(filter: $filter)
  }
`;

export const GET_CURRENT_PRESS_DETAIL = gql`
  query GetCurrentPressDetail($type: PressType!) {
    getCurrentPressDetail(type: $type) {
      ${gqlPressDetailShape}
    }
  }
`;

export const GET_NEWS_DETAIL = gql`
  query GetNewsDetail($id: ID!) {
    getNewsDetail(id: $id) {
      summary {
        ${gqlNewsSummaryShape}
      }
      content
      imageLinks
      audioLinks
      videoLinks
      videoPreviewLinks
    }
  }
`;

export const TRANSLATE_NEWS = gql`
  query TranslateNews($id: ID!) {
    translateNews(id: $id) {
      summary {
        ${gqlNewsSummaryShape}
      }
      content
      imageLinks
      audioLinks
      videoLinks
      videoPreviewLinks
    }
  }
`;

export const GET_PRESS_DETAIL = gql`
    query GetPressDetail($id: ID!) {
        getPressDetail(id: $id) {
            ${gqlPressDetailShape}
        }
    }
`;

export const LIST_BOOKMARKED_NEWS = gql`
  query ListBookmarkedNews($color: BookmarkColor) {
    listBookmarkedNews(color: $color) {
      ${gqlNewsSummaryShape}
    }
  }
`;

export const LIST_CATEGORIES = gql`
  query ListCategories($sorting: Sorting) {
    listCategories(sorting: $sorting) {
      id
      name
      position
      fallback
      tags {
        id
        description
      }
    }
  }
`;

export const SEARCH_TAGS = gql`
  query SearchTags(
    $filter: String
    $pagination: Pagination
    $sorting: Sorting
  ) {
    searchTags(filter: $filter, pagination: $pagination, sorting: $sorting) {
      results {
        id
        description
      }
      totalCount
    }
  }
`;

export const LIST_DAILY_PRESS_NEWS = gql`
  query ListDailyPressNews {
    listDailyPressNews {
      ${gqlNewsSummaryShape}
    }
  }
`;

export const LIST_KEYWORDS = gql`
  query ListKeywords {
    listKeywords {
      id
      name
      color
      expression
      position
      notification
    }
  }
`;

export const LIST_PRESS = gql`
  query ListPress($type: PressType, $pagination: Pagination) {
    listPress(type: $type, pagination: $pagination) {
      ${gqlPressSummaryShape}
    }
  }
`;

export const LIST_WORKING_AREAS = gql`
  query ListWorkingAreas {
    listWorkingAreas {
      ${gqlWorkingAreaShape}
    }
  }
`;

export const SUGGESTIONS = gql`
  query Suggestions($filter: NewsSuggestionFilter) {
    suggestions(filter: $filter)
  }
`;

export const MONITOR = gql`
  query Monitor {
    monitor {
      provider
      lastUpdate
    }
  }
`;
