import { NewsDetail, PressDetail } from '@nwa/graphql';
import { createAction } from '@reduxjs/toolkit';

enum Actions {
  SET_NEWS_SELECTION = 'SET_NEWS_SELECTION',
  SET_DETAIL_NEWS = 'SET_DETAIL_NEWS',
  SET_CURRENT_PRESS_REVIEW = 'SET_CURRENT_PRESS_REVIEW',
  SET_CURRENT_HIGHLIGHT = 'SET_CURRENT_HIGHLIGHT',
  FORCE_REFRESH = 'FORCE_REFRESH',
  ADD_NEWS_IN_PRESS = 'ADD_NEWS_IN_PRESS',
  ADD_NEWS_IN_HIGHLIGHT = 'ADD_NEWS_IN_HIGHLIGHT',
  REMOVE_NEWS_IN_PRESS = 'REMOVE_NEWS_IN_PRESS',
  REMOVE_NEWS_IN_HIGHLIGHT = 'REMOVE_NEWS_IN_HIGHLIGHT',
  REMOVE_ALL_NEWS_IN_PRESS = 'REMOVE_ALL_NEWS_IN_PRESS',
  REMOVE_ALL_NEWS_IN_HIGHLIGHT = 'REMOVE_ALL_NEWS_IN_HIGHLIGHT',
  SET_NEWS_SELECTION_HIGHLIGHT = 'SET_NEWS_SELECTION_HIGHLIGHT',
  SET_PRESS_SELECTION = 'SET_PRESS_SELECTION',
  SET_HIGHLIGHT_SELECTION = 'SET_HIGHLIGHT_SELECTION',
}

export const setPressSelection = createAction<string[]>(
  Actions.SET_PRESS_SELECTION
);

export const setHighlightSelection = createAction<string[]>(
  Actions.SET_HIGHLIGHT_SELECTION
);

export const setNewsSelection = createAction<string[]>(
  Actions.SET_NEWS_SELECTION
);

export const setNewsSelectionHighlight = createAction<string[]>(
  Actions.SET_NEWS_SELECTION_HIGHLIGHT
);

export const addNewsInPress = createAction<string[]>(Actions.ADD_NEWS_IN_PRESS);

export const removeNewsInPress = createAction<string>(
  Actions.REMOVE_NEWS_IN_PRESS
);

export const removeAllNewsInPress = createAction(
  Actions.REMOVE_ALL_NEWS_IN_PRESS
);

export const addNewsInHighlight = createAction<string[]>(
  Actions.ADD_NEWS_IN_HIGHLIGHT
);

export const removeNewsInHighlight = createAction<string>(
  Actions.REMOVE_NEWS_IN_HIGHLIGHT
);

export const removeAllNewsInHighlight = createAction(
  Actions.REMOVE_ALL_NEWS_IN_HIGHLIGHT
);

export const setDetailNews = createAction<NewsDetail | undefined>(
  Actions.SET_DETAIL_NEWS
);

export const setCurrentPressReview = createAction<PressDetail>(
  Actions.SET_CURRENT_PRESS_REVIEW
);

export const setCurrentHighlight = createAction<PressDetail>(
  Actions.SET_CURRENT_HIGHLIGHT
);

export const forceRefresh = createAction(Actions.FORCE_REFRESH);
