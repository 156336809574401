import type { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { Divider, Hamburger, Remove } from '../../../svg';
import { DivWithHtml } from '../../DivWithHtml';
import { SvgIcon } from '../../SvgIcon';

export interface NewsCardProps {
  id: string;
  newsId: string;
  title: string;
  index: number;
  onClickTitle: (id: string) => void;
  onClickRemove: (id: string) => void;
}

export const DraggableItemList: FC<NewsCardProps> = ({
  id,
  newsId,
  title,
  index,
  onClickRemove,
  onClickTitle,
}) => (
  <Draggable draggableId={id} index={index}>
    {(provided) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className="flex w-full items-center justify-between rounded-lg mt-1 p-1 bg-white max-w-full min-w-0"
      >
        <div className="flex items-center bg-white w-11/12">
          <DivWithHtml
            className="flex ml-1 cursor-pointer"
            onClick={() => onClickTitle(newsId)}
            html={title}
          />
        </div>
        <div className="flex items-center justify-between w-1/12">
          <SvgIcon
            svg={<Remove className="h-5 w-5" />}
            pointer={true}
            onClick={() => onClickRemove(id)}
          />
          <SvgIcon svg={<Divider />} />
          <SvgIcon svg={<Hamburger className="h-4 w-4 mr-1" />} />
        </div>
      </div>
    )}
  </Draggable>
);
