import { combineReducers, legacy_createStore as createStore } from 'redux';
import { filterChoicesReducer } from './filterChoices/reducers';
import { newsFilterReducer } from './newsFilter/reducers';
import { newsSelectionReducer } from './newsSelection/reducers';
import { notificationReducer } from './notification/reducers';
import { pinsReducer } from './pins/reducers';
import { utilsReducer } from './utils/reducers';
import { workingAreasReducer } from './workingAreas/reducers';

const rootReducer = () =>
  combineReducers({
    workingAreasReducer,
    newsFilterReducer,
    filterChoicesReducer,
    pinsReducer,
    newsSelectionReducer,
    notificationReducer,
    utilsReducer,
  });

export const store = createStore(
  rootReducer(),
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export type ReduxState = ReturnType<ReturnType<typeof rootReducer>>;
