//import { Pin } from '@nwa/graphql';
import { createReducer, current } from '@reduxjs/toolkit';
import { setPinnedIds, resetPinState, refreshPinState } from './actions';

export interface Pin {
  newsId: string;
  tabId: string;
}
export interface PinsState {
  pinnedIds: Pin[];
}

const defaultState: PinsState = {
  pinnedIds: [],
};

export const pinsReducer = createReducer<PinsState>(defaultState, {
  [setPinnedIds.type]: (state, action): PinsState => ({
    ...state,
    pinnedIds: action.payload,
  }),
  [resetPinState.type]: (): PinsState => ({
    ...defaultState,
  }),
  [refreshPinState.type]: (state): PinsState => {
    let tmp: Pin[] = [];
    current(state).pinnedIds.forEach(
      (pinnedId) =>
        document.getElementById(pinnedId.newsId + pinnedId.tabId) &&
        tmp.push(pinnedId)
    );
    return {
      ...state,
      pinnedIds: tmp,
    };
  },
});
