import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Table } from '../../svg';
import { SvgIcon } from '../SvgIcon';

export const MonitorIcon: FC = () => {
  return (
    <Link
      to={'/newswire/monitor'}
      style={{
        textDecoration: 'none',
        color: 'inherit',
      }}
    >
      <SvgIcon svg={<Table />} />
    </Link>
  );
};
