import { Pin } from './reducers';
import { createAction } from '@reduxjs/toolkit';

enum Actions {
  SET_PINNED_IDS = 'SET_PINNED_IDS',
  RESET_STATE = 'RESET_STATE',
  REFRESH_STATE = 'REFRESH_STATE',
}

export const setPinnedIds = createAction<Pin[]>(Actions.SET_PINNED_IDS);
export const resetPinState = createAction(Actions.RESET_STATE);
export const refreshPinState = createAction(Actions.REFRESH_STATE);
