import { useEffect } from 'react';
import { LIST_WORKING_AREAS } from '../../graphql/news/queries';
import { useDispatch, useSelector } from 'react-redux';
import { firstLowerCaseGqlDefinition } from '../../utils/stringUtils';
import {
  setWorkingAreas,
  setFirstWorkingAreaLoaded,
  moveWorkingAreaInDraft,
  filterWorkingAreas,
} from '../../redux/workingAreas/actions';
import { workingAreasSelector } from '../../redux/workingAreas/selectors';
import { useQueryHook } from './useQueryHook';
import { filterChoicesSelector } from '../../redux/filterChoices/selectors';

export const useInitialFillWorkingAreas = (): {
  refetch: any;
} => {
  const dispatch = useDispatch();
  const { firstWorkingAreaLoaded } = useSelector(workingAreasSelector);
  const { keywordsLoaded, keywords } = useSelector(filterChoicesSelector);

  const { data, refetch } = useQueryHook({ queryGql: LIST_WORKING_AREAS });
  useEffect(() => {
    if (data && data[firstLowerCaseGqlDefinition(LIST_WORKING_AREAS)]) {
      dispatch(
        setWorkingAreas(data[firstLowerCaseGqlDefinition(LIST_WORKING_AREAS)])
      );
      if (keywordsLoaded) dispatch(filterWorkingAreas(keywords));
      if (!firstWorkingAreaLoaded) {
        dispatch(setFirstWorkingAreaLoaded(true));
        const id = localStorage.getItem('LAST_WORKING_AREA');
        if (id && id !== '0') {
          dispatch(moveWorkingAreaInDraft(id));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch]);

  return { refetch };
};
