import { createAction } from '@reduxjs/toolkit';
import { NotificationObj } from '../../components/Notifications/Notification';

enum Actions {
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
  REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
}

export const addNotification = createAction<NotificationObj>(
  Actions.ADD_NOTIFICATION
);

export const removeNotification = createAction<string>(
  Actions.REMOVE_NOTIFICATION
);
