import { FC } from 'react';
import * as React from 'react';
import { SvgIcon } from '../SvgIcon';
import { Reload } from '../../svg';
import { Text } from '../Text';
import { utilsSelector } from '../../redux/utils/selectors';
import { useSelector } from 'react-redux';

interface BackToLiveProps {
  show: boolean;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

export const BackToLive: FC<BackToLiveProps> = ({ show, onClick }) => {
  const { isMobile } = useSelector(utilsSelector);
  return show ? (
    <div
      className={`flex w-full min-w-full absolute justify-center ${
        isMobile ? 'top-[8vh]' : 'top-0'
      }`}
    >
      <div
        onClick={onClick}
        className="absolute h-10 w-auto bg-white top-2 px-5 items-center inline-flex cursor-pointer"
        style={{
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.12)',
          borderRadius: '50px',
        }}
      >
        <SvgIcon svg={<Reload />} />
        <Text text="Torna alla diretta" className="ml-1" />
      </div>
    </div>
  ) : (
    <></>
  );
};
