import Modal from '../Modal';
import { addNewCategory } from '../../redux/filterChoices/actions';
import { Button } from '../Button';
import { Text } from '../Text';
import { Input } from '../Input';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNotificationBanner } from '../hooks/useNotification';
import { NEW_CATEGORY } from '../../graphql/news/mutation';
import { useMutationHook } from '../hooks/useMutationHook';
import { filterChoicesSelector } from '../../redux/filterChoices/selectors';

interface CategoryModalNewProps {
  setShowAddModal: (newValue: boolean) => void;
}

export const CategoryModalNew: FC<CategoryModalNewProps> = ({
  setShowAddModal,
}) => {
  const dispatch = useDispatch();

  const [newCategoryMutation] = useMutationHook({ queryGql: NEW_CATEGORY });
  const [newCategoryName, setNewCategoryName] = useState('');

  const { dispatchNotificationBanner } = useNotificationBanner();

  const { categories } = useSelector(filterChoicesSelector);
  return (
    <Modal
      title={'Nuova categoria'}
      showCloseButton={true}
      onClickCloseButton={() => setShowAddModal(false)}
      footer={
        <>
          <Button color="secondary" onClick={() => setShowAddModal(false)}>
            <Text text="Annulla" />
          </Button>
          <Button
            color="primary"
            className="ml-4"
            onClick={() => {
              newCategoryMutation({
                variables: {
                  category: {
                    name: newCategoryName,
                    position: categories.length,
                    fallback: false,
                    tags: [],
                  },
                },
              }).then((res: any) => {
                dispatch(
                  addNewCategory({
                    name: newCategoryName,
                    id: res.data.newCategory,
                    position: categories.length,
                    fallback: false,
                    tags: [],
                  })
                );
                setShowAddModal(false);
                dispatchNotificationBanner({
                  title: 'Operazione completata',
                  text: 'Categoria assegnata',
                  ok: true,
                });
              });
            }}
          >
            <Text text="Salva" />
          </Button>
        </>
      }
    >
      <div className="flex flex-col" style={{ width: '400px' }}>
        <div className="flex mt-3">
          <Text text="Nome categoria" />
          <span className="text-xs align-super">*</span>:
        </div>
        <div className="flex w-11/12">
          <Input
            placeholder="Nome nuova categoria"
            value={newCategoryName}
            onChange={(e) => {
              setNewCategoryName(e.target.value);
            }}
            required
          />
        </div>
      </div>
    </Modal>
  );
};
