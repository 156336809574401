import type { Identifier, XYCoord } from 'dnd-core';
import type { FC } from 'react';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { DragTypes } from '../../types/DragTypes';
import { SvgIcon } from '../SvgIcon';
import { Divider, Edit, Fallback, Hamburger, Remove } from '../../svg';

export interface CategoryCardProps {
  id: any;
  text: string;
  fallback: boolean;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  removeCard: (id: string) => void;
  editCard: (id: string) => void;
  fallbackOn: (id: string) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const CategoryDraggableCard: FC<CategoryCardProps> = ({
  id,
  text,
  fallback,
  index,
  moveCard,
  editCard,
  removeCard,
  fallbackOn,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: DragTypes.CATEGORY,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: DragTypes.CATEGORY,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));
  return (
    <div
      className="flex items-center justify-between min-w-max"
      ref={preview}
      style={{ opacity }}
    >
      <div className="flex items-center h-11">
        <div className="flex ml-1">{text}</div>
      </div>
      <div className="flex items-center">
        {fallback ? (
          <SvgIcon
            svg={<Fallback className="h-5 w-5 text-black cursor-not-allowed" />}
            pointer={false}
            className="ml-20 cursor-not-allowed"
          />
        ) : (
          <SvgIcon
            svg={<Fallback className="h-5 w-5 text-[#ccc]" />}
            pointer={true}
            onClick={() => fallbackOn(id)}
            className="ml-20"
          />
        )}
        <SvgIcon
          svg={<Edit className="h-5 w-5 fill-black" />}
          pointer={true}
          onClick={() => editCard(id)}
          className="ml-2"
        />
        <SvgIcon
          svg={<Remove className="h-5 w-5" />}
          pointer={true}
          onClick={() => removeCard(id)}
          className="ml-2"
        />
        <SvgIcon svg={<Divider className="h-6" />} className="ml-4" />
        <div ref={ref} data-handler-id={handlerId} className="cursor-move ml-4">
          <SvgIcon svg={<Hamburger className="h-4 w-4" />} />
        </div>
      </div>
    </div>
  );
};
