import { BookmarkColor } from '@nwa/graphql';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterChoicesSelector } from '../../redux/filterChoices/selectors';
import { AddBookmark } from '../../svg';
import { SvgIcon } from '../SvgIcon';
import { ADD_BOOKMARK, DELETE_BOOKMARK } from '../../graphql/news/mutation';
import { forceRefresh } from '../../redux/newsSelection/actions';
import { useMutationHook } from '../hooks/useMutationHook';

export interface NewsBookmarkProps {
  id: string;
  bookmark: BookmarkColor | undefined;
}

export const NewsBookmark: FC<NewsBookmarkProps> = ({ id, bookmark }) => {
  const dispatch = useDispatch();
  const [addBookmarkMutation] = useMutationHook({ queryGql: ADD_BOOKMARK });

  const [deleteBookmarkMutation] = useMutationHook({
    queryGql: DELETE_BOOKMARK,
  });

  const { currentBookmarkColor, bookmarksList } = useSelector(
    filterChoicesSelector
  );

  const [bookmarkIcon, setBookmarkIcon] = useState(
    bookmarksList.filter((bookmarkItem) => bookmarkItem.value === bookmark)
      .length > 0 ? (
      bookmarksList.filter((bookmarkItem) => bookmarkItem.value === bookmark)[0]
        .Icon
    ) : (
      <AddBookmark />
    )
  );

  const [bookmarkTMP, setBookmarkTMP] = useState(bookmark);
  const clickedBookmark = () => {
    if (bookmarkTMP) {
      deleteBookmarkMutation({
        variables: {
          id,
        },
      }).then(() => {
        dispatch(forceRefresh());
        setBookmarkIcon(<AddBookmark />);
        setBookmarkTMP(undefined);
      });
    } else {
      addBookmarkMutation({
        variables: {
          id,
          color: currentBookmarkColor,
        },
      }).then(() => {
        dispatch(forceRefresh());
        setBookmarkIcon(
          bookmarksList.filter(
            (bookmarkItem) => bookmarkItem.value === currentBookmarkColor
          )[0].Icon
        );
        setBookmarkTMP(currentBookmarkColor as BookmarkColor);
      });
    }
  };

  return (
    <SvgIcon svg={bookmarkIcon} onClick={clickedBookmark} pointer={true} />
  );
};
