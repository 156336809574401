import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GenericMultiSelect } from '../GenericMultiSelect';
import { setExpressionsFilter } from '../../redux/newsFilter/actions';
import { newsFilterSelector } from '../../redux/newsFilter/selectors';
import { filterChoicesSelector } from '../../redux/filterChoices/selectors';
import { FilterBoxChildrenProps } from '../FilterBox';
import Modal from '../Modal';
import { Keyword } from '@nwa/graphql';
import { removeKeyword, setKeywords } from '../../redux/filterChoices/actions';
import update from 'immutability-helper';
import { KeywordDraggableCard } from './KeywordDraggableCard';
import { Button } from '../Button';
import { Text } from '../Text';
import { Input } from '../Input';
import { SketchPicker } from 'react-color';
import { KeywordsMgmt, NewKeyword } from '../../svg';
import { useNotificationBanner } from '../hooks/useNotification';
import { useInitialFillKeywords } from '../hooks/useInitialFillTheFilterChoices';
import {
  DELETE_KEYWORD,
  NEW_KEYWORD,
  UPDATE_KEYWORD,
} from '../../graphql/news/mutation';
import { handleTokenAsPredicate, mapBoonExprToType } from '../../utils/boon';
import { setWorkingAreaFilter } from '../../redux/workingAreas/actions';
import { useMutationHook } from '../hooks/useMutationHook';

export const KeywordsFilter: FC<FilterBoxChildrenProps> = ({ isDisabled }) => {
  const { refetch } = useInitialFillKeywords();

  const [newKeywordMutation] = useMutationHook({ queryGql: NEW_KEYWORD });
  const [updateKeywordMutation] = useMutationHook({ queryGql: UPDATE_KEYWORD });
  const [deleteKeywordMutation] = useMutationHook({ queryGql: DELETE_KEYWORD });

  const { keywords } = useSelector(filterChoicesSelector);
  const keywordsMapped = useMemo<any[]>(
    () =>
      keywords.map((keyword) => {
        return {
          value: keyword.id,
          label: keyword.name,
          color: keyword.color,
          expression: keyword.expression,
          notification: keyword.notification,
        };
      }),
    [keywords]
  );
  const dispatch = useDispatch();
  const newsFilter = useSelector(newsFilterSelector);
  const [defaultValueIds, setDefaultValueIds] = useState<string[]>([]);
  useEffect(() => {
    let tmp: string[] = [];
    if (newsFilter.keywords) {
      tmp = newsFilter.keywords
        .map((keyword) => keyword && keyword.id)
        .filter((keyword) => typeof keyword !== 'undefined');
    }
    setDefaultValueIds(tmp);
  }, [newsFilter.keywords, keywords]);
  const { dispatchNotificationBanner } = useNotificationBanner();

  const [expressionError, setExpressionError] = useState(false);

  const [showMgmtModal, setShowMgmtModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const emptyKeyword: Keyword = {
    id: '',
    name: '',
    expression: '',
    color: '#FF0000',
    position: 0,
    notification: false,
  };
  const [currentKeyword, setCurrentKeyword] = useState<Keyword | null>(null);
  const [keywordsDnd, setKeywordsDnd] = useState(keywords);
  const [keywordsDndBkp, setKeywordsDndBkp] = useState(keywords);
  useEffect(() => {
    setKeywordsDnd(keywords);
    setKeywordsDndBkp(keywords);
  }, [keywords]);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setKeywordsDnd((prevKeywords: Keyword[]) =>
      update(prevKeywords, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevKeywords[dragIndex] as Keyword],
        ],
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveAllPosition = useCallback(async () => {
    await keywordsDnd.forEach((keyword, index) => {
      updateKeywordMutation({
        variables: {
          id: keyword.id,
          keyword: {
            name: keyword.name,
            color: keyword.color,
            expression: keyword.expression,
            position: index,
            notification: keyword.notification,
          },
        },
      });
    });
    dispatch(setKeywords(keywordsDnd));
    setKeywordsDndBkp(keywordsDnd);
    setShowMgmtModal(false);
  }, [dispatch, keywordsDnd, updateKeywordMutation]);

  const saveKeywords = () => {
    const _saveKeywordsDnd = (
      newCurrentKeyword: Keyword | undefined,
      updateKeyword: boolean
    ) => {
      if (newCurrentKeyword) {
        setKeywordsDnd((keywordsDnd) => {
          const newKeywordsDnd = [...keywordsDnd, newCurrentKeyword];
          if (updateKeyword) {
            updateKeywordMutation({
              variables: {
                id: currentKeyword!.id,
                keyword: {
                  name: currentKeyword!.name,
                  expression: currentKeyword!.expression,
                  color: currentKeyword!.color,
                  position: currentKeyword!.position,
                  notification: currentKeyword!.notification,
                },
              },
            }).then(() => {
              _saveKeywords(newKeywordsDnd);
            });
          } else {
            _saveKeywords(newKeywordsDnd);
          }
          return newKeywordsDnd;
        });
      } else {
        setKeywordsDnd((prevKeywords: Keyword[]) => {
          const index = keywordsDnd.findIndex(
            (keyword) => keyword.id === currentKeyword!.id
          );
          const newKeywordsDnd = update(prevKeywords, {
            $splice: [[index, 1, currentKeyword as Keyword]],
          });
          if (updateKeyword) {
            updateKeywordMutation({
              variables: {
                id: currentKeyword!.id,
                keyword: {
                  name: currentKeyword!.name,
                  expression: currentKeyword!.expression,
                  color: currentKeyword!.color,
                  position: currentKeyword!.position,
                  notification: currentKeyword!.notification,
                },
              },
            }).then(() => {
              _saveKeywords(newKeywordsDnd);
            });
          } else {
            _saveKeywords(newKeywordsDnd);
          }
          return newKeywordsDnd;
        });
      }
    };

    const _saveKeywords = (newKeywordsDnd: Keyword[] | undefined) => {
      dispatch(setKeywords(newKeywordsDnd || keywordsDnd || []));
      setKeywordsDndBkp(newKeywordsDnd || keywordsDnd || []);
      setShowMgmtModal(false);
      setShowEditModal(false);
      setCurrentKeyword(null);
    };

    if (currentKeyword) {
      if (
        currentKeyword.name.trim() === '' ||
        currentKeyword.expression.trim() === ''
      ) {
        dispatchNotificationBanner({
          title: 'Errore',
          text: 'Si prega di compilare i campi richiesti (*)',
          ok: false,
        });
        return;
      }
      if (currentKeyword.id === '') {
        newKeywordMutation({
          variables: {
            keyword: {
              name: currentKeyword.name,
              expression: currentKeyword.expression,
              color: currentKeyword.color,
              position: keywordsDnd.length,
              notification: false,
            },
          },
        }).then((res: any) => {
          setCurrentKeyword((currentKeyword) => {
            const newCurrentKeyword = {
              ...currentKeyword!,
              id: res.data.newKeyword,
            };
            _saveKeywordsDnd(newCurrentKeyword, false);
            return newCurrentKeyword;
          });
        });
      } else _saveKeywordsDnd(undefined, true);
    } else _saveKeywords(undefined);
  };

  const removeKeywordFc = (id: string) => {
    deleteKeywordMutation({
      variables: {
        id,
      },
    }).then(() => {
      dispatch(removeKeyword(id));
    });
  };

  const editKeyword = (id: string) => {
    setCurrentKeyword(keywords.find((k) => k.id === id) || emptyKeyword);
    setShowMgmtModal(false);
    setShowEditModal(true);
  };

  const notificationOnOff = (id: string) => {
    const keywordIndex = keywordsDnd.findIndex((k) => k.id === id);
    const keyword = {
      ...keywordsDnd[keywordIndex],
      notification: !keywordsDnd[keywordIndex].notification,
    };
    updateKeywordMutation({
      variables: {
        id: id,
        keyword: {
          name: keyword!.name,
          expression: keyword!.expression,
          color: keyword!.color,
          position: keyword!.position,
          notification: keyword!.notification,
        },
      },
    }).then(() => {
      let tmp = [...keywordsDnd];
      tmp[keywordIndex] = keyword;
      dispatch(setKeywords(tmp));
      setKeywordsDndBkp(tmp);
    });
  };

  const undo = () => {
    setKeywordsDnd(keywordsDndBkp);
    setShowMgmtModal(false);
    setShowEditModal(false);
  };

  const addExpression = (button: string) => {
    let expression = currentKeyword!.expression.trim();
    if (!expression) expression = button;
    else expression = expression + ' ' + button;
    setCurrentKeyword({
      ...currentKeyword!,
      expression,
    });
  };

  const wrapQuotes = () => {
    setCurrentKeyword({
      ...currentKeyword!,
      expression: '"' + currentKeyword!.expression.trim() + '"',
    });
  };

  const addKeyword = () => {
    setShowEditModal(true);
    setCurrentKeyword(emptyKeyword);
  };

  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  useEffect(() => {
    if (currentKeyword?.expression) {
      try {
        mapBoonExprToType(currentKeyword.expression, handleTokenAsPredicate);
        setExpressionError(false);
      } catch {
        setExpressionError(true);
      }
    }
  }, [currentKeyword?.expression]);

  return (
    <>
      <GenericMultiSelect
        id="Keyword"
        showSelectedOption={true}
        onMenuOpen={() => refetch()}
        onChange={(newValue) => {
          const newKeywords = keywords.filter(
            (keyword) =>
              newValue.filter((value) => value.value === keyword.id).length > 0
          );
          dispatch(setExpressionsFilter(newKeywords));
          dispatch(
            setWorkingAreaFilter({
              ...newsFilter,
              expressions:
                newKeywords.map((keyword: Keyword) => keyword.expression) || [],
              keywords: newKeywords as Keyword[],
            })
          );
        }}
        options={keywordsMapped}
        actions={[
          {
            label: 'Gestisci keywords',
            Icon: <KeywordsMgmt className="h-6 w-6" />,
            value: '1',
            onClick: () => setShowMgmtModal(true),
          },
          {
            label: 'Crea nuova keyword',
            Icon: <NewKeyword />,
            value: '2',
            onClick: () => addKeyword(),
            isDisabled: false,
          },
        ]}
        placeholder={'Filtra per keyword'}
        defaultValueIds={defaultValueIds}
        isDisabled={isDisabled}
      />
      {showMgmtModal && (
        <Modal
          title={'Gestisci keywords'}
          showCloseButton={true}
          onClickCloseButton={() => setShowMgmtModal(false)}
          footer={
            <>
              <Button color="secondary" onClick={() => undo()}>
                <Text text="Annulla" />
              </Button>
              <Button
                color="primary"
                className="ml-4"
                onClick={() => saveAllPosition()}
              >
                <Text text="Salva" />
              </Button>
            </>
          }
        >
          <div>
            {keywordsDnd.map((keyword, i) => (
              <KeywordDraggableCard
                key={keyword.id}
                index={i}
                id={keyword.id}
                text={keyword.name}
                color={keyword.color}
                expression={keyword.expression}
                notification={keyword.notification}
                moveCard={moveCard}
                editCard={editKeyword}
                removeCard={removeKeywordFc}
                notificationOnOff={notificationOnOff}
              />
            ))}
          </div>
        </Modal>
      )}
      {showEditModal && (
        <Modal
          title={currentKeyword ? 'Modifica keyword' : 'Nuova keyword'}
          showCloseButton={true}
          onClickCloseButton={() => setShowEditModal(false)}
          footer={
            <>
              <Button color="secondary" onClick={() => undo()}>
                <Text text="Annulla" />
              </Button>
              <Button
                color="primary"
                className="ml-4"
                onClick={() => saveKeywords()}
                disabled={expressionError}
              >
                <Text text="Salva" />
              </Button>
            </>
          }
        >
          <div className="flex flex-col" style={{ width: '400px' }}>
            <div className="flex">
              <Text text="Nome etichetta" />
              <span className="text-xs align-super">*</span>:
            </div>
            <div className="inline-flex w-11/12">
              <Input
                placeholder="Attribuisci un nome alla ricerca"
                value={currentKeyword?.name}
                onChange={(e) =>
                  setCurrentKeyword({
                    ...currentKeyword!,
                    name: e.target.value,
                  })
                }
                className="mr-2 w-auto"
                required
              />
              <div className="">
                <div
                  style={{
                    padding: '5px',
                    background: '#fff',
                    cursor: 'pointer',
                  }}
                  className="shadow rounded ring-1 focus:ring-gray-400 ring-gray-300 mt-1"
                  onClick={() => setDisplayColorPicker(!displayColorPicker)}
                >
                  <div
                    style={{
                      width: '36px',
                      height: '14px',
                      borderRadius: '2px',
                      background: `${currentKeyword?.color}`,
                    }}
                  />
                </div>
                {displayColorPicker ? (
                  <div style={{ position: 'absolute', zIndex: '280' }}>
                    <div
                      style={{
                        position: 'fixed',
                        top: '0px',
                        right: '0px',
                        bottom: '0px',
                        left: '0px',
                      }}
                      onClick={() => setDisplayColorPicker(false)}
                    />
                    <SketchPicker
                      color={currentKeyword?.color}
                      onChange={(c) =>
                        setCurrentKeyword({ ...currentKeyword!, color: c.hex })
                      }
                      className="mt-0.5"
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex mt-3">
              <Text text="Keyword" />
              <span className="text-xs align-super">*</span>:
            </div>
            <div className="flex w-11/12">
              <Input
                placeholder="Digita una parola che vuoi trovare nel testo"
                value={currentKeyword?.expression}
                onChange={(e) => {
                  setCurrentKeyword({
                    ...currentKeyword!,
                    expression: e.target.value,
                  });
                }}
                required
              />
            </div>
            {expressionError && (
              <Text
                text="Espressione non valida"
                className="text-red-500"
                style={{
                  fontSize: '0.875rem',
                  lineHeight: '1.25rem',
                }}
              />
            )}

            <div className="inline-flex mt-5 m-1">
              <Button
                color="secondary"
                className="mr-2"
                onClick={() => addExpression('AND ')}
              >
                AND
              </Button>
              <Button
                color="secondary"
                className="mr-2"
                onClick={() => addExpression('OR ')}
              >
                OR
              </Button>
              <Button
                color="secondary"
                className="mr-2"
                onClick={() => addExpression('NOT ')}
              >
                NOT
              </Button>
              <Button
                color="secondary"
                className="mr-2"
                onClick={() => addExpression('(')}
              >
                (
              </Button>
              <Button
                color="secondary"
                className="mr-2"
                onClick={() => addExpression(') ')}
              >
                )
              </Button>
              <Button
                color="secondary"
                className="mr-2"
                onClick={() => wrapQuotes()}
              >
                "..."
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
