import { Environment } from '@nwa/graphql';
import { Agenzie, GlobaleGenerale, TVRadio } from '../svg';

export const upperFirstTwo = (s: string) => {
  const tr = s.trim();
  if (!tr) {
    return tr;
  }
  const [hd, ...tl] = tr.split(' ').filter((str) => !!str);
  if (tl.length === 0) {
    return hd.slice(0, 2).toUpperCase();
  }
  return hd.charAt(0).toUpperCase() + tl[0].charAt(0).toUpperCase();
};

export const environmentMapper = (env: string) => {
  switch (env) {
    case Environment.GLOBALE_GENERALE:
      return {
        enum: Environment.GLOBALE_GENERALE,
        value: 'Globale Generale',
        Icon: <GlobaleGenerale className="-translate-y-1" />,
      };
    case Environment.GLOBALE_AGENZIA:
      return {
        enum: Environment.GLOBALE_AGENZIA,
        value: 'Globale Agenzie',
        Icon: <Agenzie className="-translate-y-1" />,
      };
    case Environment.TV_RADIO:
      return {
        enum: Environment.TV_RADIO,
        value: 'TV e Radio',
        Icon: <TVRadio className="h-6 w-6 -translate-y-1" />,
      };
  }
};

export const firstLowerCaseGqlDefinition = (gqlDocument: any) => {
  const nameString = gqlDocument.definitions[0].name.value;
  return nameString.charAt(0).toLowerCase() + nameString.slice(1);
};

export const removeTagsFromHtml = (html: string) => {
  return html.replace(/<[^>]*>/g, '');
};

export const handleTextSize = (style: any, textSize: number) => {
  let fontSize = `${textSize}rem`;
  let lineHeight = `${1.5 * textSize}rem`;
  let styleObj = { ...style };
  if (styleObj.fontSize) {
    let numberSize = Number.parseFloat(styleObj.fontSize);
    const unitSize = styleObj.fontSize.substr(
      numberSize.toString().length,
      styleObj.fontSize.length
    );
    fontSize = `${numberSize * textSize}${unitSize}`;
  }
  if (styleObj.lineHeight) {
    let numberLine = Number.parseFloat(styleObj.lineHeight);
    const unitLine = styleObj.lineHeight.substr(
      numberLine.toString().length,
      styleObj.lineHeight.length
    );
    lineHeight = `${numberLine * textSize}${unitLine}`;
  }
  styleObj.fontSize = fontSize;
  styleObj.lineHeight = lineHeight;
  return styleObj;
};
