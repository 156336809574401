import { ApolloError } from '@apollo/client';
import { PressSummary, PressType } from '@nwa/graphql';
import { useEffect, useState } from 'react';
import { LIST_PRESS } from '../../graphql/news/queries';
import { firstLowerCaseGqlDefinition } from '../../utils/stringUtils';
import { useLazyQueryHook } from './useLazyQueryHook';

export const useListPress = (
  type: PressType,
  limit: number
): {
  data: PressSummary[];
  error?: ApolloError;
  loading: boolean;
  fetchMore: () => Promise<any>;
  refetch: any;
} => {
  const [data, setData] = useState<PressSummary[]>([]);

  const [
    listPress,
    {
      data: dataListPress,
      error: errorData,
      loading: loadingData,
      refetch: refetchFunction,
    },
  ] = useLazyQueryHook({
    queryGql: LIST_PRESS,
  });

  useEffect(() => {
    listPress({
      variables: {
        type,
        pagination: {
          offset: 0,
          limit,
        },
      },
      fetchPolicy: 'network-only',
    });
  }, [limit, listPress, type]);

  useEffect(() => {
    if (dataListPress) {
      setData(
        mergeAndSort(
          data,
          dataListPress[firstLowerCaseGqlDefinition(LIST_PRESS)]
        )
      );
    }
    // eslint-disable-next-line
  }, [dataListPress]);

  return {
    data: data,
    error: errorData,
    loading: loadingData,
    fetchMore: () => {
      return listPress({
        variables: {
          type,
          pagination: {
            offset: data.length || 0,
            limit,
          },
        },
        fetchPolicy: 'network-only',
      });
    },
    refetch: (ids: string[]) => {
      setData(data.filter((d) => !ids.includes(d.id)));
      refetchFunction();
    },
  };
};

const mergeAndSort = (a: any, b: any) => {
  var reduced = a.filter(
    (aitem: any) => !b.find((bitem: any) => aitem.id === bitem.id)
  );
  return reduced.concat(b).sort((a: any, b: any) => b.date - a.date);
};
