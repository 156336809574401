import { FunctionComponent, useEffect, useState } from 'react';
import { SwitchOff, SwitchOn } from '../svg';
import { SvgIcon } from './SvgIcon';

interface SwitchProps {
  onClick: (value: boolean) => void;
  value: boolean;
}

export const Switch: FunctionComponent<SwitchProps> = ({ onClick, value }) => {
  const [currentValue, setCurrentValue] = useState(value);
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <SvgIcon
      svg={currentValue ? <SwitchOn /> : <SwitchOff />}
      pointer={true}
      onClick={() => {
        onClick(!currentValue);
        setCurrentValue(!currentValue);
      }}
    />
  );
};
