import { FunctionComponent } from 'react';

interface LoadingTabProps {
  loading: boolean;
}

export const LoadingTab: FunctionComponent<LoadingTabProps> = ({ loading }) => {
  return (
    <>
      {loading && (
        <div
          className="flex opacity-40 absolute inset-0 bg-black items-center justify-center w-full h-full"
          style={{ zIndex: 10 }}
        >
          <div className="flex items-center justify-center">
            <div className="w-24 h-24 border-l-4 border-red-500 rounded-full animate-twSpin animate-infinite"></div>
          </div>
        </div>
      )}
    </>
  );
};
