import type { FC } from 'react';

import { Remove } from '../../../svg';
import { DivWithHtml } from '../../DivWithHtml';
import { SvgIcon } from '../../SvgIcon';

export interface NewsCardProps {
  id: string;
  title: string;
  removeCard?: (id: string) => void;
}

export const NewsCard: FC<NewsCardProps> = ({ id, title, removeCard }) => {
  return (
    <div
      className="flex items-center justify-between rounded-lg px-4 mt-1 min-w-0"
      style={{ backgroundColor: '#FAFAFA' }}
    >
      <div className="flex items-center h-11 overflow-ellipsis">
        <DivWithHtml className="flex ml-1" html={title} />
      </div>
      <div className="flex items-center">
        {removeCard && (
          <SvgIcon
            svg={<Remove className="h-5 w-5" />}
            pointer={true}
            onClick={() => removeCard(id)}
            className="ml-2"
          />
        )}
      </div>
    </div>
  );
};
