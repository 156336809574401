import { PressNewsDetail } from '@nwa/graphql';
import { t } from 'i18next';
import { FC, useState } from 'react';

import { Edit } from '../../../svg';
import { DivWithHtml } from '../../DivWithHtml';
import { SvgIcon } from '../../SvgIcon';
import { NewsEditor, NewsEditorType } from './NewsEditor';

export interface EditableNewsProps {
  news: PressNewsDetail;
  onSave: (press: PressNewsDetail) => Promise<any>;
}

export const EditableNews: FC<EditableNewsProps> = ({ news, onSave }) => {
  const [inEdit, setInEdit] = useState<NewsEditorType | undefined>(undefined);

  if (inEdit)
    return (
      <div className="flex flex-col items-center min-w-full mt-1 w-full">
        <div className="flex w-full">
          <NewsEditor
            news={news}
            onSave={(press: PressNewsDetail) => {
              return onSave(press).then(() => setInEdit(undefined));
            }}
            onCancel={() => setInEdit(undefined)}
            type={inEdit}
          />
        </div>
      </div>
    );
  else
    return (
      <div
        className="flex flex-col items-center justify-between min-w-full rounded-lg mt-1 w-full"
        style={{ backgroundColor: '#FAFAFA' }}
      >
        <div className="flex w-full items-center">
          <DivWithHtml className="py-2 px-4" html={news.title} />
          <SvgIcon
            onClick={() => setInEdit(NewsEditorType.TITLE)}
            pointer={true}
            svg={<Edit className="m-1 h-5 w-5" style={{ fill: '#5B84EF' }} />}
          />
        </div>
        <div className="flex w-full items-center">
          <DivWithHtml
            className="py-2 px-4"
            html={
              news.headline
                ? news.headline
                : '<em style="color:#ccc">' + t('Sottotitolo assente') + '</em>'
            }
          />
          <SvgIcon
            onClick={() => setInEdit(NewsEditorType.SUBTITLE)}
            pointer={true}
            svg={<Edit className="m-1 h-5 w-5" style={{ fill: '#5B84EF' }} />}
          />
        </div>
        <div className="flex w-full items-end justify-between">
          <DivWithHtml
            className="py-2 max-w-fit px-4"
            html={news.enrichedContent}
          />
          <SvgIcon
            onClick={() => setInEdit(NewsEditorType.CONTENT)}
            pointer={true}
            svg={<Edit className="m-1 h-5 w-5" style={{ fill: '#5B84EF' }} />}
          />
        </div>
      </div>
    );
};
