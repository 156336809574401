import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFullSearchTextFilter } from '../../redux/newsFilter/actions';
import { newsFilterSelector } from '../../redux/newsFilter/selectors';
import { setShowNewsSearchModal } from '../../redux/utils/actions';
import { utilsSelector } from '../../redux/utils/selectors';
import { setWorkingAreaFilter } from '../../redux/workingAreas/actions';
import { handleTokenAsPredicate, mapBoonExprToType } from '../../utils/boon';
import { Button } from '../Button';
import { Input } from '../Input';
import Modal from '../Modal';
import { Text } from '../Text';

export const NewsSearchModal: FC = () => {
  const { showNewsSearchModal } = useSelector(utilsSelector);
  const newsFilter = useSelector(newsFilterSelector);

  const dispatch = useDispatch();
  const [currentTerm, setCurrentTerm] = useState(newsFilter.fullSearchText);

  useEffect(() => {
    setCurrentTerm(newsFilter.fullSearchText);
  }, [newsFilter.fullSearchText]);

  const [expressionError, setExpressionError] = useState(true);

  useEffect(() => {
    if (currentTerm) {
      try {
        mapBoonExprToType(currentTerm, handleTokenAsPredicate);
        setExpressionError(false);
      } catch {
        setExpressionError(true);
      }
    } else {
      setExpressionError(false);
    }
  }, [currentTerm]);

  const addExpression = (button: string) => {
    let expression = currentTerm?.trim();
    if (!expression) expression = button;
    else expression = expression + ' ' + button;
    setCurrentTerm(expression);
  };

  const wrapQuotes = () => {
    setCurrentTerm('"' + currentTerm + '"');
  };

  return showNewsSearchModal && newsFilter.fullSearchText ? (
    <Modal
      title={'Espressione non valida'}
      showCloseButton={false}
      onClickCloseButton={() => dispatch(setShowNewsSearchModal(false))}
      footer={
        <div className="flex items-center justify-end mr-4">
          <div className="flex items-center justify-end space-x-3">
            <Button
              color="primary"
              onClick={() => {
                dispatch(setFullSearchTextFilter(currentTerm || ''));
                dispatch(setShowNewsSearchModal(false));
                dispatch(
                  setWorkingAreaFilter({
                    ...newsFilter,
                    fullSearchText: currentTerm || '',
                  })
                );
              }}
              isDisabled={expressionError}
            >
              <Text text="Chiudi" />
            </Button>
          </div>
        </div>
      }
    >
      <div>
        <div className="flex flex-col">
          <Text text="L'espressione inserita non è valida. Utilizza il campo qui sotto per renderla valida." />
          <Text text="Alcuni suggerimenti nel caso di termini di ricerca multipli:" />
          <Text text="Se si desidera trovare più parole in ordine sparso nel testo della news, dividere ogni termine con AND/OR (es: moneta AND scambio)" />
          <Text
            text={
              'Se al contrario si desidera trovare una serie di parole nell\'ordine esatto utilizzare le virgolette (es: "moneta di scambio")'
            }
          />

          <div className="flex mt-8">
            <Text text="Termine di ricerca" />:
          </div>
          <div className="flex w-11/12">
            <Input
              placeholder="Digita una parola che vuoi trovare nel testo"
              value={currentTerm}
              onChange={(e) => {
                setCurrentTerm(e.target.value);
              }}
            />
          </div>
          {expressionError && (
            <Text
              text="Espressione non valida"
              className="text-red-500"
              style={{
                fontSize: '0.875rem',
                lineHeight: '1.25rem',
              }}
            />
          )}

          <div className="inline-flex mt-5 m-1">
            <Button
              color="secondary"
              className="mr-2"
              onClick={() => addExpression('AND ')}
            >
              AND
            </Button>
            <Button
              color="secondary"
              className="mr-2"
              onClick={() => addExpression('OR ')}
            >
              OR
            </Button>
            <Button
              color="secondary"
              className="mr-2"
              onClick={() => addExpression('NOT ')}
            >
              NOT
            </Button>
            <Button
              color="secondary"
              className="mr-2"
              onClick={() => addExpression('(')}
            >
              (
            </Button>
            <Button
              color="secondary"
              className="mr-2"
              onClick={() => addExpression(') ')}
            >
              )
            </Button>
            <Button
              color="secondary"
              className="mr-2"
              onClick={() => wrapQuotes()}
            >
              "..."
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  ) : (
    <></>
  );
};
