import { Keyword, NewsFilter } from '@nwa/graphql';
import { createReducer, current } from '@reduxjs/toolkit';
import { handleTokenAsPredicate, mapBoonExprToType } from '../../utils/boon';
import {
  setExpressionsFilter,
  setFullSearchTextFilter,
  setContentTypesFilter,
  setDateRangeFilter,
  setProvidersFilter,
  setNewsFilter,
  backupNewsFilter,
  removeBackupNewsFilter,
  restoreBackupNewsFilter,
} from './actions';

export interface NewsFilterStateInner extends NewsFilter {
  keywords: Keyword[];
  fullSearchTextError: boolean;
}

interface NewsFilterState extends NewsFilterStateInner {
  backups: { [key: string]: NewsFilterStateInner };
}

const defaultState: NewsFilterState = {
  fullSearchText: undefined,
  expressions: undefined,
  providers: [],
  contentTypes: [],
  dateRange: undefined,
  keywords: [],
  backups: {},
  fullSearchTextError: false,
};

export const newsFilterReducer = createReducer<NewsFilterState>(defaultState, {
  [backupNewsFilter.type]: (state, action) => {
    let backups: { [key: string]: NewsFilterStateInner } = {
      ...current(state).backups,
    };
    backups[action.payload] = {
      fullSearchText: current(state).fullSearchText,
      expressions: current(state).expressions,
      providers: current(state).providers,
      contentTypes: current(state).contentTypes,
      dateRange: current(state).dateRange,
      keywords: current(state).keywords,
      fullSearchTextError: current(state).fullSearchTextError,
    };
    return {
      ...defaultState,
      backups,
    };
  },
  [restoreBackupNewsFilter.type]: (state, action) => {
    let backup = current(state).backups[action.payload];
    return {
      fullSearchText: backup.fullSearchText,
      expressions: backup.expressions,
      providers: backup.providers,
      contentTypes: backup.contentTypes,
      dateRange: backup.dateRange,
      keywords: backup.keywords,
      backups: Object.keys(state.backups)
        .filter((key) => key !== action.payload)
        .reduce((cur, key) => {
          return Object.assign(cur, { [key]: state.backups[key] });
        }, {}),
    };
  },
  [removeBackupNewsFilter.type]: (state, action) => ({
    ...state,
    backups: Object.keys(state.backups)
      .filter((key) => key !== action.payload)
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: state.backups[key] });
      }, {}),
  }),
  [setFullSearchTextFilter.type]: (state, action) => {
    if (action.payload) {
      try {
        mapBoonExprToType(action.payload, handleTokenAsPredicate);
        return {
          ...state,
          fullSearchText: action.payload,
          fullSearchTextError: false,
        };
      } catch {
        return {
          ...state,
          fullSearchText: action.payload,
          fullSearchTextError: true,
        };
      }
    }
    return {
      ...state,
      fullSearchText: action.payload,
      fullSearchTextError: false,
    };
  },
  [setExpressionsFilter.type]: (state, action) => ({
    ...state,
    expressions:
      action.payload.map((keyword: Keyword) => keyword.expression) || [],
    keywords: action.payload as Keyword[],
  }),
  [setContentTypesFilter.type]: (state, action) => ({
    ...state,
    contentTypes: action.payload,
  }),
  [setDateRangeFilter.type]: (state, action) => ({
    ...state,
    dateRange: action.payload,
  }),
  [setProvidersFilter.type]: (state, action) => ({
    ...state,
    providers: action.payload,
  }),
  [setNewsFilter.type]: (state, action) => ({ ...state, ...action.payload }),
});
