/* eslint-disable no-plusplus */
import chroma from 'chroma-js';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { filterChoicesSelector } from '../../redux/filterChoices/selectors';
import {
  numberOfFilter,
  workingAreasSelector,
} from '../../redux/workingAreas/selectors';
import { getDateAsString } from '../../utils/dateUtils';
import { SvgIcon } from '../SvgIcon';
import { Text } from '../Text';

interface TooltipProps {
  windowId: string;
}

export const Tooltip: FC<TooltipProps> = ({ windowId: id }) => {
  const { draftWorkingArea, dateRange: dateRangeWorkingArea } =
    useSelector(workingAreasSelector);

  const numberFilter = useSelector((state: any) => numberOfFilter(state, id));
  const { keywords, contentTypes } = useSelector(filterChoicesSelector);

  const searchValue = draftWorkingArea?.windows.filter((w) => w.id === id)[0]
    ?.value;

  return (
    <ReactTooltip
      id={'tooltip-bar' + id}
      key={'tooltip-bar' + id}
      place="bottom"
      effect="solid"
      type="light"
      border={true}
      borderColor="black"
      className="tooltip-bar-class"
    >
      {numberFilter > 0 && (
        <span
          className="inline-flex p-1 items-start flex-col space-y-1"
          style={{
            fontSize: '0.625rem',
            lineHeight: '0.75rem',
          }}
        >
          {searchValue?.search?.keywords?.map((keywordExp) => {
            const keyword = keywords.find(
              (k) => k.expression === keywordExp
            ) || { id: '-1', name: 'Not Found', color: 'red' };
            return keyword.id !== '-1' ? (
              <div
                style={{
                  backgroundColor: chroma(keyword.color).alpha(0.1).css(),
                  color: keyword.color,
                }}
                className="flex p-1 rounded"
                key={'key' + keyword.id + id}
              >
                <Text
                  text={keyword.name}
                  skipTranslation={true}
                  style={{
                    fontSize: '0.625rem',
                    lineHeight: '0.75rem',
                  }}
                />
              </div>
            ) : (
              <></>
            );
          })}
          {searchValue?.search?.providers?.map((provider) => (
            <div
              style={{
                background: 'rgba(121, 114, 114, 0.1)',
                color: '#1F1F1F',
              }}
              className="flex p-1 rounded"
              key={provider + id}
            >
              <Text
                text={provider}
                skipTranslation={true}
                style={{
                  fontSize: '0.625rem',
                  lineHeight: '0.75rem',
                }}
              />
            </div>
          ))}
          {dateRangeWorkingArea
            .filter((dateRange) => dateRange.id === id)
            .map((dateRange) => (
              <span
                className="flex p-1 rounded"
                key={'tooltipdaterage' + dateRange.id}
              >
                <Text
                  text={`${getDateAsString(
                    dateRange.date.from as unknown as number
                  )}
              - 
              ${getDateAsString(dateRange.date.to as unknown as number)}`}
                  skipTranslation={true}
                  style={{
                    fontSize: '0.625rem',
                    lineHeight: '0.75rem',
                  }}
                />
              </span>
            ))}
          {searchValue?.search?.contentTypes?.map((contentType) => {
            const contentTypeObj = contentTypes.find(
              (c) => c.value === contentType
            ) || { value: '-1', label: 'Not Found', Icon: null };
            return contentTypeObj.value !== '-1' ? (
              <div
                className="flex"
                key={contentType + id}
                style={{
                  fontSize: '0.625rem',
                  lineHeight: '0.75rem',
                }}
              >
                <SvgIcon svg={contentTypeObj.Icon} className="w-4 h-4 pt-0.5" />
                <div>
                  <Text
                    text={contentTypeObj.label}
                    style={{
                      color: '#838383',
                      fontSize: '0.625rem',
                      lineHeight: '0.75rem',
                    }}
                  />
                </div>
              </div>
            ) : (
              <></>
            );
          })}
        </span>
      )}
    </ReactTooltip>
  );
};
