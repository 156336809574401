import { FC } from 'react';
import { KeycloakService } from '../../services/KeycloakService';
import { Logout } from '../../svg';
import { SvgIcon } from '../SvgIcon';
import { utilsSelector } from '../../redux/utils/selectors';
import { useSelector } from 'react-redux';

export const LogoutIcon: FC = () => {
  const { isMobile } = useSelector(utilsSelector);
  return (
    <SvgIcon
      svg={
        isMobile ? (
          <Logout className="w-6 h-6" />
        ) : (
          <Logout className="w-6 h-5" />
        )
      }
      pointer={true}
      onClick={() => KeycloakService.doLogout()}
    />
  );
};
