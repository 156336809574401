import { NewsDetail, PressDetail } from '@nwa/graphql';
import { createReducer } from '@reduxjs/toolkit';
import {
  setCurrentPressReview,
  setDetailNews,
  setNewsSelection,
  setCurrentHighlight,
  forceRefresh,
  addNewsInHighlight,
  addNewsInPress,
  removeNewsInHighlight,
  removeNewsInPress,
  removeAllNewsInPress,
  removeAllNewsInHighlight,
  setNewsSelectionHighlight,
  setPressSelection,
  setHighlightSelection,
} from './actions';

export interface NewsSelectionState {
  newsSelection: string[];
  pressSelection: string[];
  highlightSelection: string[];
  newsSelectionHighlight: string[];
  newsInPress: string[];
  newsInHighlight: string[];
  detailNews?: NewsDetail;
  currentPressReview?: PressDetail;
  currentHighlight?: PressDetail;
  forceRefresh: boolean;
}

const defaultState: NewsSelectionState = {
  newsSelection: [],
  pressSelection: [],
  highlightSelection: [],
  newsSelectionHighlight: [],
  forceRefresh: false,
  newsInPress: [],
  newsInHighlight: [],
};

export const newsSelectionReducer = createReducer<NewsSelectionState>(
  defaultState,
  {
    [setPressSelection.type]: (state, action): NewsSelectionState => ({
      ...state,
      pressSelection: action.payload,
    }),
    [setHighlightSelection.type]: (state, action): NewsSelectionState => ({
      ...state,
      highlightSelection: action.payload,
    }),
    [setNewsSelection.type]: (state, action): NewsSelectionState => ({
      ...state,
      newsSelection: action.payload,
    }),
    [setNewsSelectionHighlight.type]: (state, action): NewsSelectionState => ({
      ...state,
      newsSelectionHighlight: action.payload,
    }),
    [addNewsInPress.type]: (state, action): NewsSelectionState => ({
      ...state,
      newsInPress: state.newsInPress.concat(action.payload),
    }),
    [removeNewsInPress.type]: (state, action): NewsSelectionState => ({
      ...state,
      newsInPress: state.newsInPress.filter((id) => id !== action.payload),
    }),
    [removeAllNewsInPress.type]: (state): NewsSelectionState => ({
      ...state,
      newsInPress: [],
    }),
    [addNewsInHighlight.type]: (state, action): NewsSelectionState => ({
      ...state,
      newsInHighlight: state.newsInHighlight.concat(action.payload),
    }),
    [removeNewsInHighlight.type]: (state, action): NewsSelectionState => ({
      ...state,
      newsInHighlight: state.newsInHighlight.filter(
        (id) => id !== action.payload
      ),
    }),
    [removeAllNewsInHighlight.type]: (state): NewsSelectionState => ({
      ...state,
      newsInHighlight: [],
    }),
    [setDetailNews.type]: (state, action): NewsSelectionState => ({
      ...state,
      detailNews: action.payload,
    }),
    [setCurrentPressReview.type]: (state, action): NewsSelectionState => ({
      ...state,
      currentPressReview: action.payload,
    }),
    [setCurrentHighlight.type]: (state, action): NewsSelectionState => ({
      ...state,
      currentHighlight: action.payload,
    }),
    [forceRefresh.type]: (state): NewsSelectionState => ({
      ...state,
      forceRefresh: !state.forceRefresh,
    }),
  }
);
