import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigationBack } from '../../svg';
import { SvgIcon } from '../SvgIcon';

export const NavigationBackComponent: FC = () => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center">
      <SvgIcon
        svg={<NavigationBack />}
        pointer={true}
        onClick={() => {
          navigate(-1);
        }}
      />
    </div>
  );
};
