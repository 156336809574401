import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDateRangeFilter } from '../../redux/newsFilter/actions';
import { GenericSelect } from '../GenericSelect';
import { getDateAsString } from '../../utils/dateUtils';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { newsFilterSelector } from '../../redux/newsFilter/selectors';
import Modal from '../Modal';
import { FilterBoxChildrenProps } from '../FilterBox';
import { Calendar } from '../../svg';
import {
  removeDateRange,
  setWorkingAreaFilter,
} from '../../redux/workingAreas/actions';
import { utilsSelector } from '../../redux/utils/selectors';
import { Text } from '../Text';

export const DateFilter: FC<FilterBoxChildrenProps> = ({ isDisabled }) => {
  const dispatch = useDispatch();
  const { isMobile } = useSelector(utilsSelector);
  //diretta oggetto nullo
  //ieri: ieri a mezzanotte to oggi a mezzanotte
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  let tonight = new Date();
  tonight.setHours(23, 59, 0, 0);
  let yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  let lastWeek = new Date(today);
  lastWeek.setDate(today.getDate() - 7);
  let lastMonth = new Date(today);
  lastMonth.setDate(1);
  let lastYear = new Date(lastMonth);
  lastYear.setMonth(0);
  let fullYear = new Date();
  fullYear.setFullYear(today.getFullYear() - 1);

  const [currentValue, setCurrentValue] = useState<any>(null);
  const [showDatepicker, setShowDatepicker] = useState(false);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const onChangeDatepicker = (dates: [start: Date, end: Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      end.setHours(23, 59, 59, 999);
      let time: any = { from: start.getTime(), to: end.getTime() };
      dispatch(setDateRangeFilter(time));
      let value: any = {
        value: JSON.stringify(time),
        label: `${getDateAsString(start.getTime())} - ${getDateAsString(
          end.getTime()
        )}`,
      };
      dispatch(
        setWorkingAreaFilter({
          ...newsFilter,
          dateRange: time,
        })
      );
      setCurrentValue(value);
      setShowDatepicker(false);
    }
  };
  const values: any = {
    '0': {
      from: yesterday.getTime(),
      to: today.getTime(),
    },
    '1': {
      from: lastWeek.getTime(),
      to: tonight.getTime(),
    },
    '2': {
      from: lastMonth.getTime(),
      to: tonight.getTime(),
    },
    '3': {
      from: lastYear.getTime(),
      to: tonight.getTime(),
    },
  };
  const options = [
    {
      value: '0',
      label: 'Ieri',
    },
    {
      value: '1',
      label: 'Ultima settimana',
    },
    {
      value: '2',
      label: 'Mese corrente',
    },
    {
      value: '3',
      label: 'Anno corrente',
    },
  ];
  const newsFilter = useSelector(newsFilterSelector);
  useEffect(() => {
    if (newsFilter.dateRange && newsFilter.dateRange.from) {
      let ok = false;
      Object.entries(values).forEach(([key, value]: any) => {
        if (
          value.from === newsFilter.dateRange?.from &&
          value.to === newsFilter.dateRange?.to
        ) {
          ok = true;
          setCurrentValue(options[Number.parseInt(key)]);
        }
        if (!ok) {
          let time: any = {
            from: newsFilter.dateRange!.from,
            to: newsFilter.dateRange!.to,
          };
          let value: any = {
            value: JSON.stringify(time),
            label: `${getDateAsString(
              newsFilter.dateRange!.from as unknown as number
            )} - ${getDateAsString(
              newsFilter.dateRange!.to as unknown as number
            )}`,
          };
          setCurrentValue(value);
          setStartDate(newsFilter.dateRange!.from);
          setEndDate(newsFilter.dateRange!.to);
        }
      });
    } else {
      setCurrentValue(null);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsFilter.dateRange]);

  const onOggiClick = () => {
    setCurrentValue(null);
    dispatch(setDateRangeFilter(undefined));
    dispatch(
      setWorkingAreaFilter({
        ...newsFilter,
        dateRange: undefined,
      })
    );
    setShowDatepicker(false);
    dispatch(removeDateRange());
  };

  const onChange = (data: any) => {
    dispatch(setDateRangeFilter(values[data.value]));
    dispatch(
      setWorkingAreaFilter({
        ...newsFilter,
        dateRange: values[data.value],
      })
    );
    setCurrentValue(data);
  };
  return isMobile ? (
    <div className="flex flex-col justify-between items-center space-y-1">
      <div className="flex flex-row justify-between items-center space-x-1">
        <div
          onClick={onOggiClick}
          className="items-center px-2 py-1"
          style={{
            color: 'black',
            borderRadius: '0.3125rem',
            background:
              currentValue === null ? 'rgba(255, 223, 223, 0.50)' : '',
          }}
        >
          <Text text="Oggi" />
        </div>
        {options.map((option: any) => (
          <div
            key={option.value}
            onClick={() => onChange(option)}
            className="items-center px-2 py-1"
            style={{
              color: 'black',
              borderRadius: '0.3125rem',
              background:
                currentValue && currentValue.value === option.value
                  ? 'rgba(255, 223, 223, 0.50)'
                  : '',
            }}
          >
            <Text text={option.label} />
          </div>
        ))}
      </div>
      <div className="flex flex-row justify-between items-center space-x-1">
        <div className="items-center px-2 py-1">
          <Text text="Scegli date" className="font-semibold" />
        </div>
        <div className="items-center px-2 py-1">
          <DatePicker
            selected={startDate}
            onChange={onChangeDatepicker}
            startDate={startDate}
            endDate={endDate}
            minDate={fullYear}
            maxDate={today}
            selectsRange
            inline
          />
        </div>
      </div>
    </div>
  ) : (
    <>
      <GenericSelect
        id="Date"
        placeholder={'Scegli periodo'}
        onChange={(data: any) => onChange(data)}
        options={options}
        showSelectedOption={true}
        showSelectionCircle={false}
        showSelectedOptionLabel={true}
        showButtonControl={true}
        buttonControlLabel="Oggi"
        onButtonClick={() => onOggiClick()}
        actions={[
          {
            label: 'Scegli date',
            Icon: <Calendar />,
            value: '1',
            onClick: () => setShowDatepicker(true),
          },
        ]}
        value={currentValue}
        label={''}
        isDisabled={isDisabled}
      />
      {showDatepicker && (
        <Modal
          title={'Scegli date'}
          showCloseButton={true}
          onClickCloseButton={() => setShowDatepicker(false)}
        >
          <DatePicker
            selected={startDate}
            onChange={onChangeDatepicker}
            startDate={startDate}
            endDate={endDate}
            minDate={fullYear}
            maxDate={today}
            selectsRange
            inline
          />
        </Modal>
      )}
    </>
  );
};
