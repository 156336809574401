import { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetPinState } from '../../redux/pins/actions';
import { pinsSelector } from '../../redux/pins/selectors';
import { PinOff, PinOn } from '../../svg';
import { SvgIcon } from '../SvgIcon';

export const Pin: FC = () => {
  const dispatch = useDispatch();
  const { pinnedIds } = useSelector(pinsSelector);
  return (
    <div className="flex items-center">
      {pinnedIds.length === 0 ? (
        <SvgIcon svg={<PinOff />} />
      ) : (
        <SvgIcon
          svg={<PinOn />}
          pointer={true}
          onClick={() => {
            pinnedIds.forEach(
              (pin) =>
                document.getElementById(pin.newsId + pin.tabId) &&
                document
                  .getElementById(pin.newsId + pin.tabId)
                  ?.scrollIntoView()
            );
            dispatch(resetPinState());
          }}
        />
      )}
    </div>
  );
};
