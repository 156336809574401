import { createAction } from '@reduxjs/toolkit';
import {
  Environment,
  JSON,
  Keyword,
  WindowType,
  WorkingArea,
  WorkingAreaInput,
} from '@nwa/graphql';
import { NewsFilterStateInner } from '../newsFilter/reducers';

enum Actions {
  SET_WORKING_AREAS = 'SET_WORKING_AREAS',
  SET_SELECTED_WINDOW = 'SET_SELECTED_WINDOW',
  SET_CURRENT_WORKING_AREA_IS_MODIFIED = 'SET_CURRENT_WORKING_AREA_IS_MODIFIED',
  OPEN_NEW_WINDOW = 'OPEN_NEW_WINDOW',
  SET_DRAFT_WORKING_AREA = 'SET_DRAFT_WORKING_AREA',
  SET_ENVIRONMENT = 'SET_ENVIRONMENT',
  SET_WORKING_AREA_FILTER = 'SET_WORKING_AREA_FILTER',
  ADD_NEW_WINDOW = 'ADD_NEW_WINDOW',
  REMOVE_WINDOW = 'REMOVE_WINDOW',
  REMOVE_DATE_RANGE = 'REMOVE_DATE_RANGE',
  MOVE_WORKING_AREA_IN_DRAFT = 'MOVE_WORKING_AREA_IN_DRAFT',
  REMOVE_DRAFT_WORKING_AREA = 'REMOVE_DRAFT_WORKING_AREA',
  UPDATE_WORKING_AREA = 'UPDATE_WORKING_AREA',
  DELETE_WORKING_AREA = 'DELETE_WORKING_AREA',
  SET_ID_DRAFT_WORKING_AREA = 'SET_ID_DRAFT_WORKING_AREA',
  SET_NAME_DRAFT_WORKING_AREA = 'SET_NAME_DRAFT_WORKING_AREA',
  SET_FIRST_WORKING_AREA_LOADED = 'SET_FIRST_WORKING_AREA_LOADED',
  SET_WINDOW_COUNT = 'SET_WINDOW_COUNT',
  BACKUP_WORKING_AREA_FILTER_AND_SET_TYPE_BOOKMARK = 'BACKUP_WORKING_AREA_FILTER_AND_SET_TYPE_BOOKMARK',
  REMOVE_BACKUP_WORKING_AREA_FILTER = 'REMOVE_BACKUP_WORKING_AREA_FILTER',
  RESTORE_BACKUP_WORKING_AREA_FILTER = 'RESTORE_BACKUP_WORKING_AREA_FILTER',
  FILTER_WORKING_AREAS = 'FILTER_WORKING_AREAS',
}

export const setWorkingAreas = createAction<WorkingArea[]>(
  Actions.SET_WORKING_AREAS
);

export const filterWorkingAreas = createAction<Keyword[]>(
  Actions.FILTER_WORKING_AREAS
);

export const setSelectedWindow = createAction<string | undefined>(
  Actions.SET_SELECTED_WINDOW
);

export const setCurrentWorkingAreaIsModified = createAction<boolean>(
  Actions.SET_CURRENT_WORKING_AREA_IS_MODIFIED
);

export const openNewWindow = createAction<WindowType | undefined>(
  Actions.OPEN_NEW_WINDOW
);

export const setConfigDraftWorkingArea = createAction<{ config: JSON }>(
  Actions.SET_DRAFT_WORKING_AREA
);

export const moveWorkingAreaInDraft = createAction<string>(
  Actions.MOVE_WORKING_AREA_IN_DRAFT
);

export const setEnvironment = createAction<{
  environment: Environment;
}>(Actions.SET_ENVIRONMENT);

export const setWorkingAreaFilter = createAction<NewsFilterStateInner>(
  Actions.SET_WORKING_AREA_FILTER
);

export const setWindowCount = createAction<{ id: string; count: number }>(
  Actions.SET_WINDOW_COUNT
);

export const addNewWindow = createAction<{
  id: string;
}>(Actions.ADD_NEW_WINDOW);

export const removeWindow = createAction<{
  id: string;
}>(Actions.REMOVE_WINDOW);

export const removeDraftWorkingArea = createAction(
  Actions.REMOVE_DRAFT_WORKING_AREA
);

export const removeDateRange = createAction(Actions.REMOVE_DATE_RANGE);

export const updateWorkingArea = createAction<{
  id: string;
  workingArea: WorkingAreaInput;
}>(Actions.UPDATE_WORKING_AREA);

export const deleteWorkingArea = createAction(Actions.DELETE_WORKING_AREA);

export const setIdDraftWorkingArea = createAction<string>(
  Actions.SET_ID_DRAFT_WORKING_AREA
);

export const setNameDraftWorkingArea = createAction<string>(
  Actions.SET_NAME_DRAFT_WORKING_AREA
);

export const setFirstWorkingAreaLoaded = createAction<boolean>(
  Actions.SET_FIRST_WORKING_AREA_LOADED
);

export const backupWorkingAreaFilterAndSetTypeBookmark = createAction<string>(
  Actions.BACKUP_WORKING_AREA_FILTER_AND_SET_TYPE_BOOKMARK
);

export const restoreBackupWorkingAreaFilter = createAction<string>(
  Actions.RESTORE_BACKUP_WORKING_AREA_FILTER
);

export const removeBackupWorkingAreaFilter = createAction<string>(
  Actions.REMOVE_BACKUP_WORKING_AREA_FILTER
);
