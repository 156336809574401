import { createReducer, current } from '@reduxjs/toolkit';
import { NotificationObj } from '../../components/Notifications/Notification';
import { addNotification, removeNotification } from './actions';

export interface NotificationState {
  notifications: NotificationObj[];
}

const defaultState: NotificationState = {
  notifications: [],
};

export const notificationReducer = createReducer<NotificationState>(
  defaultState,
  {
    [addNotification.type]: (state, action): NotificationState => ({
      ...state,
      notifications: [...current(state).notifications, action.payload],
    }),
    [removeNotification.type]: (state, action): NotificationState => ({
      ...state,
      notifications: current(state).notifications.filter(
        (notification) => notification.id !== action.payload
      ),
    }),
  }
);
