import { BookmarkColor, ContentType, Provider } from '@nwa/graphql';
import { ReactComponent as Video } from '../../svg/icons/Attachment/Video.svg';
import { ReactComponent as Audio } from '../../svg/icons/Attachment/Podcast.svg';
import { ReactComponent as Image } from '../../svg/icons/Attachment/Image.svg';
import { ReactComponent as TextOnly } from '../../svg/icons/Attachment/Text.svg';
import { ReactComponent as Orange } from '../../svg/icons/Bookmark/Orange.svg';
import { ReactComponent as Yellow } from '../../svg/icons/Bookmark/Yellow.svg';
import { ReactComponent as Green } from '../../svg/icons/Bookmark/Green.svg';
import { ReactComponent as Purple } from '../../svg/icons/Bookmark/Purple.svg';

export interface ContentTypeObject {
  value: string;
  label: string;
  Icon: any;
}

export const contentTypes: ContentTypeObject[] = [
  {
    label: 'Video',
    value: ContentType.VIDEO,
    Icon: <Video />,
  },
  {
    label: 'Audio',
    value: ContentType.AUDIO,
    Icon: <Audio />,
  },
  {
    label: 'Image',
    value: ContentType.IMAGE,
    Icon: <Image />,
  },
  {
    label: 'Solo testo',
    value: ContentType.TEXT_ONLY,
    Icon: <TextOnly />,
  },
];

export interface BookmarkObject {
  label: string;
  value: BookmarkColor;
  Icon: JSX.Element;
}

export const bookmarksList: BookmarkObject[] = [
  {
    label: 'Arancio',
    value: BookmarkColor.ORANGE,
    Icon: <Orange />,
  },
  {
    label: 'Giallo',
    value: BookmarkColor.YELLOW,
    Icon: <Yellow />,
  },
  {
    label: 'Verde',
    value: BookmarkColor.GREEN,
    Icon: <Green />,
  },
  {
    label: 'Viola',
    value: BookmarkColor.PURPLE,
    Icon: <Purple />,
  },
];

export const providers: ProviderObject[] = [
  {
    label: 'ADNKRONOS',
    value: Provider.ADNKRONOS,
    Icon: '',
  },
  {
    label: 'AGI',
    value: Provider.AGI,
    Icon: '',
  },
  {
    label: 'ANSA',
    value: Provider.ANSA,
    Icon: '',
  },
  {
    label: 'ASKANEWS',
    value: Provider.ASKANEWS,
    Icon: '',
  },
  {
    label: 'DIRE',
    value: Provider.DIRE,
    Icon: '',
  },
  {
    label: 'DOW JONES',
    value: Provider.DOW_JONES,
    Icon: '',
  },
  {
    label: 'MF-NW',
    value: Provider.MF_DOW_JONES,
    Icon: '',
  },
  {
    label: 'RADIOCOR',
    value: Provider.RADIOCOR,
    Icon: '',
  },
  {
    label: 'TELEBORSA',
    value: Provider.TELEBORSA,
    Icon: '',
  },
  {
    label: 'REUTERS',
    value: Provider.REUTERS,
    Icon: '',
  },
];

export interface ProviderObject {
  value: string;
  label: string;
  Icon: any;
}
