import { FC, useState } from 'react';
import { PressNewsDetail, Provider } from '@nwa/graphql';
import { Text } from '../../Text';
import { DraggableItemList } from './DraggableItemList';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { SvgIcon } from '../../SvgIcon';
import { Down, Hamburger, Remove, Right } from '../../../svg';
import { t } from 'i18next';

export interface NewsDraggableCardProps {
  currentPressDraggable?: PressNewsDetail[];
  category: string;
  onClickTitle: (id: string, category: string, provider: Provider) => void;
  onClickRemoveCategory: (id: string) => void;
  onClickRemoveNews: (id: string, category: string) => void;
  index: number;
}

export const NewsDraggableContainerCategory: FC<NewsDraggableCardProps> = ({
  currentPressDraggable,
  category,
  onClickRemoveCategory,
  onClickRemoveNews,
  onClickTitle,
  index,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Draggable
      draggableId={category ? category : 'Nessuna categoria'}
      index={index}
      key={'draggable_cat' + category ? category : 'Nessuna categoria'}
    >
      {(providedDrag) => (
        <div
          ref={providedDrag.innerRef}
          {...providedDrag.draggableProps}
          {...providedDrag.dragHandleProps}
          className="flex-col items-center justify-between rounded-lg mt-1 p-4 pl-1"
          style={{
            ...providedDrag.draggableProps.style,
            backgroundColor: '#FAFAFA',
            minHeight: collapsed ? '' : '120px',
          }}
        >
          <div className="flex flex-row w-full items-center justify-between">
            <div className="flex flex-row items-center">
              <SvgIcon
                svg={
                  collapsed ? (
                    <Right className="h-5 w-5" />
                  ) : (
                    <Down className="h-5 w-5" />
                  )
                }
                pointer={true}
                onClick={() => setCollapsed(!collapsed)}
              />
              <Text
                text={category}
                skipTranslation={true}
                className="font-semibold"
                style={{
                  fontSize: '1.125rem',
                  lineHeight: '1.75rem',
                }}
              />
              {category !== t('Nessuna categoria') && (
                <SvgIcon
                  svg={<Remove className="h-5 w-5 ml-2" />}
                  pointer={true}
                  onClick={() => onClickRemoveCategory(category)}
                />
              )}
            </div>
            <div>
              <SvgIcon svg={<Hamburger className="h-5 w-5" />} />
            </div>
          </div>
          <Droppable
            droppableId={category ? category : 'Nessuna categoria'}
            type="news"
          >
            {(providedDropNews) => (
              <div
                className="pl-4"
                ref={providedDropNews.innerRef}
                {...providedDropNews.droppableProps}
              >
                {!collapsed &&
                currentPressDraggable &&
                currentPressDraggable.length > 0
                  ? currentPressDraggable.map((news, i) => (
                      <DraggableItemList
                        key={news.id}
                        index={i}
                        id={news.id}
                        title={news.title}
                        newsId={news.newsId!}
                        onClickRemove={(id: string) =>
                          onClickRemoveNews(id, news.category || '')
                        }
                        onClickTitle={(id: string) =>
                          onClickTitle(id, news.category || '', news.provider)
                        }
                      />
                    ))
                  : !collapsed && (
                      <div className="flex justify-center items-center h-full">
                        <Text text="Trascina qui gli articoli" />
                      </div>
                    )}
                {providedDropNews.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  );
};
