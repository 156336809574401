import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { newsSelectionSelector } from '../../redux/newsSelection/selectors';
import { Copy, Eye, Lock, Translate } from '../../svg';
import { CategorySelector } from '../CategorySelector';
import { CategorySelectorIndex } from '../CategorySelectorIndex';
import { DivWithHtml } from '../DivWithHtml';
import { useNotificationBanner } from '../hooks/useNotification';
import { NewsBookmark } from '../NewsBookmark';
import { NewsLogoAndDate } from '../NewsLogoAndDate';
import { SvgIcon } from '../SvgIcon';
import { Text } from '../Text';
import './NewsDetailInnerComponent.scss';
import { SliderComponent } from './SliderComponent';
import ReactPlayer from 'react-player/file';
import Modal from '../Modal';
import { utilsSelector } from '../../redux/utils/selectors';
import { t } from 'i18next';
import { TRANSLATE_NEWS } from '../../graphql/news/queries';
import { useLazyQueryHook } from '../hooks/useLazyQueryHook';
import { setDetailNews } from '../../redux/newsSelection/actions';
import {
  addLoading,
  removeLoading,
  setTranslationsIds,
} from '../../redux/utils/actions';
import { NewsDetail } from '@nwa/graphql';

export interface NewsDetailProps {
  inPreviewWindow: boolean;
  inPressIndex?: boolean;
  onChange?: (newValue: string | undefined, id: string) => void;
  onTranslationClick?: (news: NewsDetail) => void;
}

export const NewsDetailInnerComponent: FC<NewsDetailProps> = ({
  inPreviewWindow,
  inPressIndex,
  onChange,
  onTranslationClick,
}) => {
  const dispatch = useDispatch();
  const { detailNews } = useSelector(newsSelectionSelector);
  const { dispatchNotificationBanner } = useNotificationBanner();
  const [showBlockchainModal, setShowBlockchainModal] = useState(false);

  const { isMobile, translationsIds } = useSelector(utilsSelector);
  const [translateNews] = useLazyQueryHook({ queryGql: TRANSLATE_NEWS });

  const translateNewsFn = () => {
    dispatch(addLoading());
    translateNews({
      variables: {
        id: detailNews?.summary.id,
      },
      fetchPolicy: 'no-cache',
    })
      .then((res: any) => {
        onTranslationClick && onTranslationClick(res.data.translateNews);
        dispatch(setDetailNews({ ...res.data.translateNews }));
        dispatch(
          setTranslationsIds(
            translationsIds.concat(res.data.translateNews.summary.id)
          )
        );
      })
      .finally(() => dispatch(removeLoading()));
  };

  const copyNews = () => {
    if ('clipboard' in navigator) {
      const copy = `<div style="display: flex; flex-direction: column; font-size: 10pt; font-family: Arial;">
      <div style="font-size: 12pt; font-weight: 700;">${
        detailNews?.summary.title
      }</div>
        ${
          detailNews?.summary.headline
            ? `<div style="font-weight: 600;">${detailNews?.summary.headline}</div>`
            : ``
        }
      <div style="">${detailNews?.content}</div>
    </div>`;
      var data = [
        new ClipboardItem({
          'text/html': Promise.resolve(new Blob([copy], { type: 'text/html' })),
          'text/plain': Promise.resolve(
            new Blob(
              [
                t(
                  "Questo editor non supporta l'HTML, utilizzare un editor differente"
                ),
              ],
              { type: 'text/plain' }
            )
          ),
        }),
      ];
      navigator.clipboard.write(data).then(() =>
        dispatchNotificationBanner({
          title: t('News copiata negli appunti'),
          ok: true,
        })
      );
    } else {
      dispatchNotificationBanner({
        title: t('Impossibile copiare negli appunti'),
        text: t(
          'Il browser in uso non supporta questa funzionalità. Si consiglia di utilizzare Google Chrome'
        ),
        ok: false,
      });
    }
  };

  return detailNews ? (
    <div className="flex bg-white w-full h-full flex-col px-2 py-1">
      {inPreviewWindow && (
        <div className="flex mb-2">
          <Text
            text="Dettaglio News"
            className="font-semibold"
            style={{
              fontSize: '1.125rem',
              lineHeight: '1.75rem',
            }}
          />
        </div>
      )}
      <div className="flex justify-between px-2 py-1 items-center">
        <div className="flex">
          <NewsLogoAndDate
            date={detailNews.summary.date}
            provider={detailNews.summary.provider}
          />
        </div>
        {!isMobile && (
          <div className="flex">
            {inPressIndex && (
              <CategorySelectorIndex
                newsId={detailNews.summary.id}
                currentValue={detailNews.summary.category?.name}
                menuPlacement="bottom"
                onChange={(newValue) => {
                  onChange && onChange(newValue, detailNews.summary.id);
                }}
              />
            )}
            {!inPressIndex && (
              <CategorySelector
                newsId={detailNews.summary.id}
                currentValue={detailNews.summary.category}
                menuPlacement="bottom"
              />
            )}
          </div>
        )}
      </div>
      <div className="flex justify-between px-2 py-1 items-center mt-2">
        <DivWithHtml
          className="font-bold pr-4"
          style={{
            fontSize: '1.25rem',
            lineHeight: '1.75rem',
          }}
          html={detailNews.summary.title}
        />
        <div className="flex items-center">
          {detailNews?.summary.blockchain && (
            <SvgIcon
              svg={<Lock className="h-6 w-6" />}
              pointer={true}
              onClick={() => setShowBlockchainModal(true)}
              className="mr-1"
              style={{ fill: '#BBBBBB' }}
            />
          )}
          <SvgIcon
            svg={<Translate />}
            pointer={true}
            onClick={translateNewsFn}
            className="mr-1"
          />
          <SvgIcon
            svg={<Copy />}
            pointer={true}
            onClick={copyNews}
            className="mr-1"
            style={{ fill: '#BBBBBB' }}
          />
          {!inPressIndex && !isMobile && (
            <NewsBookmark
              bookmark={detailNews.summary.bookmark}
              id={detailNews.summary.id}
            />
          )}
        </div>
      </div>
      {detailNews.summary.headline && (
        <div className="flex justify-between px-2 py-1 items-center mt-2">
          <DivWithHtml
            className="font-semibold italic pr-4"
            html={detailNews.summary.headline}
          />
        </div>
      )}
      <div
        className={`flex px-2 py-1 scrollbar overflow-y-auto ${
          inPreviewWindow || isMobile ? 'h-full flex-col' : 'max-h-80 flex-row'
        }`}
      >
        <DivWithHtml html={detailNews.content} />
        <div>
          {detailNews.imageLinks.length > 0 && (
            <div
              className={`${
                inPreviewWindow ? 'mt-10' : isMobile ? '' : 'w-96'
              }`}
            >
              <SliderComponent images={detailNews.imageLinks} />
            </div>
          )}
          {detailNews.audioLinks.length > 0 && (
            <div
              className={`${
                inPreviewWindow ? 'mt-10' : isMobile ? '' : 'w-96'
              }`}
            >
              {detailNews.audioLinks.map((audio) => (
                <ReactPlayer
                  url={audio}
                  key={audio}
                  controls={true}
                  width="100%"
                  height="32px"
                  config={{
                    forceAudio: true,
                  }}
                />
              ))}
            </div>
          )}
          {detailNews.videoLinks.length > 0 && (
            <div
              className={`${
                inPreviewWindow ? 'mt-10' : isMobile ? '' : 'w-96'
              }`}
            >
              {detailNews.videoLinks.map((video) => (
                <ReactPlayer
                  url={video}
                  key={video}
                  controls={true}
                  width="100%"
                  height="100%"
                />
              ))}
            </div>
          )}
        </div>
      </div>
      {showBlockchainModal && (
        <Modal
          title={'Dettagli blockchain'}
          showCloseButton={true}
          onClickCloseButton={() => setShowBlockchainModal(false)}
        >
          <div className="flex flex-col" style={{ width: '800px' }}>
            <div>
              <div className="flex mt-3">
                <Text text="Hash:" className="font-semibold" />
              </div>
              <div className="flex w-full">
                <Text text={detailNews?.summary.blockchain!.hashcode} />
              </div>
            </div>
            <div>
              <div className="flex mt-3">
                <Text text="Destinazione:" className="font-semibold" />
              </div>
              <div className="flex w-full">
                <Text text={detailNews?.summary.blockchain!.destination} />
              </div>
            </div>
            <div>
              <div className="flex mt-3">
                <Text text="Validazione:" className="font-semibold" />
              </div>
              <div className="flex w-full">
                <a
                  href={detailNews?.summary.blockchain!.validation}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text text="Clicca qui" />
                </a>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  ) : (
    <div className="w-full h-full flex items-center justify-center">
      <div className="flex w-auto h-auto items-center justify-center flex-col">
        <SvgIcon svg={<Eye className="w-36 h-auto fill-gray-400" />} />
        <Text
          text="Seleziona una news per vederla qui"
          className="mt-2 text-gray-400"
        />
      </div>
    </div>
  );
};
