import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { decreaseTextSize, increaseTextSize } from '../../redux/utils/actions';
import { utilsSelector } from '../../redux/utils/selectors';
import { TextMinus, TextPlus } from '../../svg';
import { SvgIcon } from '../SvgIcon';

export const TextSize: FC = () => {
  const dispatch = useDispatch();
  const { isTextSizeMax, isTextSizeMin } = useSelector(utilsSelector);
  return (
    <>
      <SvgIcon
        svg={
          <TextPlus
            className="w-7 h-7"
            style={{
              fill: isTextSizeMax ? '#ccc' : 'black',
            }}
          />
        }
        pointer={!isTextSizeMax}
        onClick={() => (isTextSizeMax ? {} : dispatch(increaseTextSize()))}
      />
      <SvgIcon
        svg={
          <TextMinus
            className="w-6 h-6"
            style={{
              fill: isTextSizeMin ? '#ccc' : 'black',
            }}
          />
        }
        pointer={!isTextSizeMin}
        onClick={() => (isTextSizeMin ? {} : dispatch(decreaseTextSize()))}
      />
    </>
  );
};
