import { useDispatch } from 'react-redux';
import {
  addNotification,
  removeNotification,
} from '../../redux/notification/actions';
import { v4 as uuidv4 } from 'uuid';
import { NotificationObjNoId } from '../Notifications/Notification';

export const useNotificationBanner = () => {
  const dispatch = useDispatch();
  const id = uuidv4();
  const dispatchNotificationBanner = (notification: NotificationObjNoId) => {
    dispatch(addNotification({ ...notification, id }));
    setTimeout(() => dispatch(removeNotification(id)), 5000);
  };
  return {
    dispatchNotificationBanner,
  };
};
