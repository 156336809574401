import type { Identifier, XYCoord } from 'dnd-core';
import type { FC } from 'react';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { DragTypes } from '../../types/DragTypes';
import chroma from 'chroma-js';
import { SvgIcon } from '../SvgIcon';
import { Text } from '../Text';
import { BellOff, BellOn, Divider, Edit, Hamburger, Remove } from '../../svg';

export interface KeywordCardProps {
  id: any;
  text: string;
  color: string;
  expression: string;
  notification: boolean;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  removeCard: (id: string) => void;
  editCard: (id: string) => void;
  notificationOnOff: (id: string) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const KeywordDraggableCard: FC<KeywordCardProps> = ({
  id,
  text,
  color,
  expression,
  notification,
  index,
  moveCard,
  editCard,
  removeCard,
  notificationOnOff,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: DragTypes.KEYWORD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: DragTypes.KEYWORD,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));
  return (
    <div
      className="flex items-center justify-between min-w-max"
      ref={preview}
      style={{ opacity }}
    >
      <div className="flex items-center min-h-[2.75rem]">
        <div
          className="flex w-0.5 h-6"
          style={{ backgroundColor: color }}
        ></div>
        <div className="flex ml-1 max-w-[330px]">{expression}</div>
        <div
          style={{
            backgroundColor: chroma(color).alpha(0.1).css(),
          }}
          className="flex m-1"
        >
          <div className="mx-1" style={{ color: color }}>
            <span>
              <Text
                text={text}
                skipTranslation={true}
                style={{
                  fontSize: '0.75rem',
                  lineHeight: '1rem',
                }}
              />
            </span>
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <SvgIcon
          svg={
            notification ? (
              <BellOn className="h-5 w-5" />
            ) : (
              <BellOff className="h-5 w-5" />
            )
          }
          pointer={true}
          onClick={() => notificationOnOff(id)}
          className="ml-20"
        />
        <SvgIcon
          svg={<Edit className="h-5 w-5 fill-black" />}
          pointer={true}
          onClick={() => editCard(id)}
          className="ml-2"
        />
        <SvgIcon
          svg={<Remove className="h-5 w-5" />}
          pointer={true}
          onClick={() => removeCard(id)}
          className="ml-2"
        />
        <SvgIcon svg={<Divider className="h-6" />} className="ml-4" />
        <div ref={ref} data-handler-id={handlerId} className="cursor-move ml-4">
          <SvgIcon svg={<Hamburger className="h-4 w-4" />} />
        </div>
      </div>
    </div>
  );
};
