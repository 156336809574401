import { Provider } from '@nwa/graphql';
import { FC } from 'react';
import { SvgIcon } from '../SvgIcon';
import { providers } from '../../utils/iconUtils';
import { Text } from '../Text';
import {
  getDateAsString,
  isToday,
  isYesterday,
  getHoursAndMinutesAsString,
} from '../../utils/dateUtils';

export interface NewsLogoAndDateProps {
  provider: Provider;
  date: Date;
}

export const NewsLogoAndDate: FC<NewsLogoAndDateProps> = ({
  provider,
  date,
}) => {
  return (
    <>
      <SvgIcon svg={providers[provider]} pointer={false} />
      &nbsp; &nbsp;
      {isToday(date as any) ? (
        <Text
          text={'Oggi'}
          style={{ fontSize: '0.875rem', lineHeight: '1.25rem' }}
        />
      ) : isYesterday(date as any) ? (
        <Text
          text={'Ieri'}
          style={{ fontSize: '0.875rem', lineHeight: '1.25rem' }}
        />
      ) : (
        <Text
          style={{ fontSize: '0.875rem', lineHeight: '1.25rem' }}
          skipTranslation={true}
          text={getDateAsString(date as any)}
        />
      )}
      &nbsp;
      <Text
        text={'alle'}
        style={{ fontSize: '0.875rem', lineHeight: '1.25rem' }}
      />
      &nbsp;
      <Text
        className="font-bold"
        style={{ fontSize: '0.875rem', lineHeight: '1.25rem' }}
        skipTranslation={true}
        text={getHoursAndMinutesAsString(date as any)}
      />
    </>
  );
};
