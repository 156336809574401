import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NewsPress } from './components/NewsPress';
import { WorkingArea } from './components/WorkingArea';
import { Highlights } from './components/Highlights';
import { ErrorPage } from './components/ErrorPage';
import styled from 'styled-components';
import { Header } from './components/Header';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Notifications } from './components/Notifications/Notifications';
import { Loading } from './components/Loading';
import { Monitor } from './components/Monitor';
import { utilsSelector } from './redux/utils/selectors';
import { useSelector } from 'react-redux';
import { WorkingAreaMobile } from './components/WorkingAreaMobile';

export const DesktopOrMobile: FC = () => {
  const { isMobile } = useSelector(utilsSelector);
  return (
    <Container>
      <DndProvider backend={HTML5Backend}>
        {!isMobile && <Header />}
        <Routes>
          <Route
            path="/"
            element={
              <Navigate
                to={{
                  pathname: '/newswire',
                }}
              />
            }
          />
          <Route
            path="/*"
            element={
              <Navigate
                to={{
                  pathname: '/newswire',
                }}
              />
            }
          />
          <Route
            path="/newswire/"
            element={isMobile ? <WorkingAreaMobile /> : <WorkingArea />}
          />
          <Route path="/newswire/:workingAreaId" element={<WorkingArea />} />
          <Route path="/newswire/monitor/*" element={<Monitor />} />
          <Route path="/newswire/newspress/*" element={<NewsPress />} />
          <Route path="/newswire/highlights/*" element={<Highlights />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <Notifications />
        <Loading />
      </DndProvider>
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100dvh;
  width: 100%;
  overflow: hidden;
`;
