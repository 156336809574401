export const gqlNewsKeywordShape = `
    id
    name
    color
`;

export const gqlNewsBlockchainShape = `
    hashcode
    algorithm
    destination
    validation
`;

export const gqlNewsSummaryShape = `
    id
    date
    title
    headline
    contentType
    provider
    bookmark
    keywords {
        ${gqlNewsKeywordShape}
    }
    inPressReview
    inHighlight
    category {
      id  
      name
    }
    read
    recomposed
    blockchain {
        ${gqlNewsBlockchainShape}
    }
`;

export const gqlPressSummaryShape = `
    id
    type
    title
    count
    draft
    draftDate
    savedDate
`;

export const gqlPressNewsDetailShape = `
    id
    title
    headline
    order
    newsId
    enrichedContent
    provider
    category
    images
`;

export const gqlPressDetailShape = `
    summary {
        ${gqlPressSummaryShape}
    }
    indexEnabled
    imagesEnabled
    previewVideoEnabled
    backgroundEnabled
    news {
        ${gqlPressNewsDetailShape}
    }
`;

export const gqlSavedSearchShape = `
  environment
  fullSearchText
  keywords
  providers
  contentTypes
`;

export const gqlWorkingAreaShape = `
    id
    name
    creationDate
    config
    windows {
        id
        value {
            type
            color
            search {
                ${gqlSavedSearchShape}
            }
        }
    }
`;
