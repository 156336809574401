import type { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { Divider, Hamburger, Remove } from '../../svg';
import { SvgIcon } from '../SvgIcon';

export interface TagCardProps {
  id: number;
  index: number;
  title: string;
  onClickRemove?: (id: number) => void;
  showRemove: boolean;
}

export const DraggableTagList: FC<TagCardProps> = ({
  id,
  index,
  title,
  onClickRemove,
  showRemove,
}) => (
  <Draggable draggableId={id.toString()} index={index}>
    {(provided) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className="flex w-full items-center justify-between rounded-lg mt-1 p-1 bg-white max-w-full min-w-0"
      >
        <div className="flex items-center bg-white">{title}</div>
        <div className="flex items-center justify-between">
          {showRemove && (
            <div className="flex items-center justify-between">
              <SvgIcon
                svg={<Remove className="h-5 w-5 mr-1" />}
                pointer={true}
                onClick={() => onClickRemove && onClickRemove(id)}
              />
              <SvgIcon svg={<Divider className="mr-1" />} />
            </div>
          )}
          <SvgIcon svg={<Hamburger className="h-4 w-4 mr-1" />} />
        </div>
      </div>
    )}
  </Draggable>
);
