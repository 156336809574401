import { PressNewsDetailInput } from '@nwa/graphql';
import React, { FC } from 'react';

import { NewNewsEditor } from './NewNewsEditor';

export interface EditableNewNewsProps {
  onSave: (press: PressNewsDetailInput) => void;
  onCancel: any;
}

export const EditableNewNews: FC<EditableNewNewsProps> = ({
  onSave,
  onCancel,
}) => {
  return (
    <div className="flex flex-col items-center min-w-full mt-1 w-full">
      <div className="flex w-full">
        <NewNewsEditor onSave={onSave} onCancel={onCancel} />
      </div>
    </div>
  );
};
