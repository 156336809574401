import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { utilsSelector } from '../redux/utils/selectors';

export const Loading: FunctionComponent = () => {
  const { loading } = useSelector(utilsSelector);
  return (
    <>
      {loading > 0 && (
        <div
          className="flex opacity-40 fixed inset-0 bg-black items-center justify-center"
          style={{ zIndex: 310 }}
        >
          <div className="flex items-center justify-center">
            <div className="w-24 h-24 border-l-4 border-red-500 rounded-full animate-twSpin animate-infinite"></div>
          </div>
        </div>
      )}
    </>
  );
};
