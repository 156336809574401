import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filterChoicesSelector } from '../../redux/filterChoices/selectors';
import { GenericMultiSelect } from '../GenericMultiSelect';
import { setContentTypesFilter } from '../../redux/newsFilter/actions';
import { newsFilterSelector } from '../../redux/newsFilter/selectors';
import { FilterBoxChildrenProps } from '../FilterBox';
import { setWorkingAreaFilter } from '../../redux/workingAreas/actions';
import { ContentType } from '@nwa/graphql';

export const ContentTypesFilter: FC<FilterBoxChildrenProps> = ({
  isDisabled,
}) => {
  const { contentTypes } = useSelector(filterChoicesSelector);
  const dispatch = useDispatch();
  const newsFilter = useSelector(newsFilterSelector);
  const [defaultValueIds, setDefaultValueIds] = useState<string[]>([]);
  useEffect(() => {
    let tmp: string[] = [];
    if (newsFilter.contentTypes && newsFilter.contentTypes.length > 0) {
      contentTypes.forEach((contentType) => {
        newsFilter.contentTypes?.forEach((contentTypeFilter) => {
          if (contentType.value === contentTypeFilter) {
            tmp.push(contentType.value);
          }
        });
      });
    }
    setDefaultValueIds(tmp);
  }, [newsFilter.contentTypes, contentTypes]);
  return (
    <GenericMultiSelect
      id="ContentTypes"
      showSelectedOption={true}
      onChange={(newValue) => {
        const contentTypesMap = newValue.map(
          (contentType) => contentType.value
        );
        dispatch(setContentTypesFilter(contentTypesMap));
        dispatch(
          setWorkingAreaFilter({
            ...newsFilter,
            contentTypes: contentTypesMap as ContentType[],
          })
        );
      }}
      options={contentTypes}
      placeholder={'Tutti i formati'}
      defaultValueIds={defaultValueIds}
      isDisabled={isDisabled}
    />
  );
};
