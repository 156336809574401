import { createAction } from '@reduxjs/toolkit';

enum Actions {
  ADD_LOADING = 'ADD_LOADING',
  REMOVE_LOADING = 'REMOVE_LOADING',
  SET_IN_WORKING_AREA = 'SET_IN_WORKING_AREA',
  SET_SHOW_NEWS_SEARCH_MODAL = 'SET_SHOW_NEWS_SEARCH_MODAL',
  SET_SHOW_FILTER_MODAL_MOBILE = 'SET_SHOW_FILTER_MODAL_MOBILE',
  INCREASE_TEXT_SIZE = 'INCREASE_TEXT_SIZE',
  DECREASE_TEXT_SIZE = 'DECREASE_TEXT_SIZE',
  SET_IS_A_WINDOW_MAXIMIZED = 'SET_IS_A_WINDOW_MAXIMIZED',
  SET_DOCK_SIZES = 'SET_DOCK_SIZES',
  SET_CLICK_AFTER_FORCE_UNFOCUSED = 'SET_CLICK_AFTER_FORCE_UNFOCUSED',
  SET_CURRENT_FILTER_FOCUSED = 'SET_CURRENT_FILTER_FOCUSED',
  SET_IS_MOBILE = 'SET_IS_MOBILE',
  SET_TRANSLATIONS_IDS = 'SET_TRANSLATIONS_IDS',
}

export const setTranslationsIds = createAction<string[]>(
  Actions.SET_TRANSLATIONS_IDS
);

export const setShowFilterModalMobile = createAction<boolean>(
  Actions.SET_SHOW_FILTER_MODAL_MOBILE
);

export const setIsMobile = createAction<undefined>(Actions.SET_IS_MOBILE);

export const addLoading = createAction<undefined>(Actions.ADD_LOADING);

export const removeLoading = createAction<undefined>(Actions.REMOVE_LOADING);

export const setCurrentFilterFocused = createAction<string | undefined>(
  Actions.SET_CURRENT_FILTER_FOCUSED
);

export const setClickAfterForceUnfocus = createAction<boolean>(
  Actions.SET_CLICK_AFTER_FORCE_UNFOCUSED
);

export const setInWorkingArea = createAction<boolean>(
  Actions.SET_IN_WORKING_AREA
);

export const setShowNewsSearchModal = createAction<boolean>(
  Actions.SET_SHOW_NEWS_SEARCH_MODAL
);

export const setIsAWindowMaximized = createAction<boolean>(
  Actions.SET_IS_A_WINDOW_MAXIMIZED
);

export const setDockSizes = createAction<{ [key: string]: number }>(
  Actions.SET_DOCK_SIZES
);

export const increaseTextSize = createAction(Actions.INCREASE_TEXT_SIZE);

export const decreaseTextSize = createAction(Actions.DECREASE_TEXT_SIZE);
