import { PressType } from '@nwa/graphql';
import { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ADD_NEWS_TO_CURRENT_PRESS } from '../../graphql/news/mutation';
import {
  addNewsInHighlight,
  addNewsInPress,
  forceRefresh,
  setNewsSelection,
  setNewsSelectionHighlight,
} from '../../redux/newsSelection/actions';
import { newsSelectionSelector } from '../../redux/newsSelection/selectors';
import { Divider } from '../../svg';
import { Button } from '../Button';
import { SvgIcon } from '../SvgIcon';
import { Text } from '../Text';
import { useMutationHook } from '../hooks/useMutationHook';
import { addLoading, removeLoading } from '../../redux/utils/actions';
import { useNavigate } from 'react-router-dom';

export interface ContextualMenuProps {
  inHighlightNewsList: boolean;
}

export const ContextualMenu: FC<ContextualMenuProps> = ({
  inHighlightNewsList,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { newsSelection, newsSelectionHighlight, currentHighlight } =
    useSelector(newsSelectionSelector);
  const [addNewsToCurrentPressMutation] = useMutationHook({
    queryGql: ADD_NEWS_TO_CURRENT_PRESS,
  });

  const onClickAdd = (pressType: PressType) => {
    dispatch(addLoading());
    const nS = inHighlightNewsList ? newsSelectionHighlight : newsSelection;
    addNewsToCurrentPressMutation({
      variables: {
        to: pressType,
        newsId: nS,
      },
    })
      .then(() => {
        if (pressType === PressType.RELEASE) dispatch(addNewsInPress(nS));
        else if (pressType === PressType.HIGHLIGHT)
          dispatch(addNewsInHighlight(nS));
        if (!inHighlightNewsList) dispatch(forceRefresh());
        inHighlightNewsList
          ? dispatch(setNewsSelectionHighlight([]))
          : dispatch(setNewsSelection([]));
      })
      .finally(() => dispatch(removeLoading()));
  };
  if (
    inHighlightNewsList &&
    newsSelectionHighlight.length === 0 &&
    currentHighlight?.news &&
    currentHighlight?.news?.length > 0
  )
    return (
      <>
        <div
          className="flex items-center fixed right-0 bottom-0 bg-white w-1/3 h-14 justify-between rounded-tl-lg"
          style={{
            boxShadow: '0px -6px 14px rgba(0, 0, 0, 0.12)',
            zIndex: 251,
          }}
        >
          <div className="flex content-start ml-4">
            <Text text="Ci sono" />
            &nbsp;
            <span className="font-bold">{currentHighlight?.news?.length}</span>
            &nbsp;
            <Text text="news" className="font-bold" />
            &nbsp;
            <Text text="nella rassegna" />
          </div>
          <div className="flex items-center justify-end mr-4">
            <div className="flex items-center justify-end space-x-3">
              <SvgIcon svg={<Divider />} />
              <Button
                color="primary"
                onClick={() =>
                  navigate(
                    '/newswire/highlights/' + currentHighlight?.summary.id
                  )
                }
              >
                <Text text="Vai alla rassegna" />
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  else
    return (
      <>
        {(inHighlightNewsList
          ? newsSelectionHighlight.length > 0
          : newsSelection.length > 0) && (
          <div
            className="flex items-center fixed left-0 right-0 bottom-0 bg-white h-14 justify-between"
            style={{
              boxShadow: '0px -6px 14px rgba(0, 0, 0, 0.12)',
              zIndex: 251,
            }}
          >
            <div className="flex content-start ml-4">
              <Text text="Hai selezionato" />
              &nbsp;
              <span className="font-bold">
                {inHighlightNewsList
                  ? newsSelectionHighlight.length
                  : newsSelection.length}
              </span>
              &nbsp;
              <Text text="news" className="font-bold" />
            </div>
            <div className="flex items-center justify-end mr-4">
              <div className="flex items-center justify-end space-x-3">
                <SvgIcon svg={<Divider />} />
                <Button
                  color={inHighlightNewsList ? 'primary' : 'secondary'}
                  onClick={() => onClickAdd(PressType.HIGHLIGHT)}
                >
                  <Text text="Aggiungi alla rassegna" />
                </Button>
                {!inHighlightNewsList && (
                  <Button
                    color="primary"
                    onClick={() => onClickAdd(PressType.RELEASE)}
                  >
                    <Text text="Aggiungi alla raccolta" />
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
};
