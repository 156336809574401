import { WindowType } from '@nwa/graphql';
import DockLayout, { DragDropDiv, LayoutData, PanelData } from 'rc-dock';
import 'rc-dock/dist/rc-dock.css';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { setNewsFilter } from '../../redux/newsFilter/actions';
import { setDetailNews } from '../../redux/newsSelection/actions';
import {
  setClickAfterForceUnfocus,
  setInWorkingArea,
} from '../../redux/utils/actions';
import { utilsSelector } from '../../redux/utils/selectors';
import {
  addNewWindow,
  openNewWindow,
  removeWindow,
  setConfigDraftWorkingArea,
  setSelectedWindow,
} from '../../redux/workingAreas/actions';
import { workingAreasSelector } from '../../redux/workingAreas/selectors';
import { ContextualMenu } from '../ContextualMenu';
import { FilterBox } from '../FilterBox';
import { useNotificationBanner } from '../hooks/useNotification';
import { NewsDetailWrapper } from '../NewsDetailWrapper';
import { Text } from '../Text';
import './index.css';
import { NewsSearchModal } from './NewsSearchModal';
import { Tab } from './Tab';
import { TitleTab } from './TitleTab';
import { Tooltips } from './Tooltips';

const id = uuidv4();

export const WorkingArea: FC = () => {
  const dispatch = useDispatch();
  const { isOpenedNewWindow, draftWorkingArea } =
    useSelector(workingAreasSelector);
  const { isAWindowMaximized, clickAfterForceUnfocus } =
    useSelector(utilsSelector);
  const [dockLayout, setDockLayout] = useState<DockLayout | undefined>(
    undefined
  );
  const [buttonRef, setButtonRef] = useState<any>(null);

  useEffect(() => {
    if (dockLayout && draftWorkingArea?.config) {
      //dockLayout.loadLayout(draftWorkingArea.config);
      setLayout(draftWorkingArea.config);
    }
  }, [dockLayout, draftWorkingArea]);

  useEffect(() => {
    if (!draftWorkingArea || draftWorkingArea.windows.length === 0) {
      dispatch(setSelectedWindow(id));
      dispatch(addNewWindow({ id }));
    }
    dispatch(setInWorkingArea(true));

    return () => {
      dispatch(setInWorkingArea(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRef = (r: DockLayout) => {
    setDockLayout(r);
    if (r && draftWorkingArea && !draftWorkingArea.config) {
      dispatch(setConfigDraftWorkingArea(r.saveLayout() as any));
    }
  };
  const getButtonRef = (r: DragDropDiv | null) => {
    setButtonRef(r);
  };
  const [layout, setLayout] = useState<LayoutData>({
    dockbox: {
      mode: 'horizontal',
      children: [
        {
          mode: 'vertical',
          children: [
            {
              tabs: [
                {
                  id: `${id}`,
                  title: <TitleTab id={id} dockLayout={dockLayout} />,
                  content: <Tab id={id} key={id} />,
                  group: `group-${id}`,
                  closable: true,
                },
              ],
            },
          ],
        },
      ],
    },
  });

  const onDragStart = (e: any) => {
    const tabId = uuidv4();
    e.setData(
      {
        tab: {
          id: `${tabId}`,
          title: <TitleTab id={tabId} dockLayout={dockLayout} />,
          content: <Tab id={tabId} key={id} />,
          group: `group-${tabId}`,
          closable: true,
        },
        panelSize: [400, 300],
        panelLock: {
          minWidth: 200,
          panelExtra: (panelData: PanelData) => (
            <button
              className="btn"
              onClick={() => dockLayout?.dockMove(panelData, null, 'maximize')}
            >
              MAX
            </button>
          ),
        },
      },
      dockLayout?.getDockId()
    );
    e.startDrag(buttonRef.element, buttonRef.element);
    dispatch(addNewWindow({ id: tabId }));

    dispatch(openNewWindow(undefined));
    dispatch(setSelectedWindow(tabId));
  };

  const loadTab = (savedTab: any) => {
    const id = savedTab.id;
    return {
      id: `${id}`,
      title: <TitleTab id={id} dockLayout={dockLayout} />,
      content: <Tab id={id} key={id} />,
      group: `group-${id}`,
      closable: true,
    };
  };

  const windowType = (tabId: string) =>
    draftWorkingArea?.windows.find((window) => window.id === tabId)?.value.type;

  const { dispatchNotificationBanner } = useNotificationBanner();

  const onClick = () => {
    if (clickAfterForceUnfocus) dispatch(setClickAfterForceUnfocus(false));
  };

  return (
    <div onClick={onClick}>
      <FilterBox />
      <DockLayout
        ref={getRef as any}
        loadTab={loadTab as any}
        //defaultLayout={box}
        style={{
          position: 'absolute',
          left: 10,
          top: 100,
          right: 10,
          bottom: 10,
        }}
        layout={layout}
        onLayoutChange={(layout: any, tabId, direction) => {
          //console.log('layout change', tabId, direction);
          if (tabId && direction === 'remove') {
            if (isAWindowMaximized) {
              dispatchNotificationBanner({
                title: 'Operazione non permessa',
                text: 'Impossibile rimuovere finestre mentre una finestra è massimizzata',
                ok: false,
              });
              return;
            }
            dispatch(removeWindow({ id: tabId }));
            if (windowType(tabId) === WindowType.PREVIEW)
              dispatch(setDetailNews(undefined));
            dispatch(
              setNewsFilter({
                keywords: [],
                providers: [],
                contentTypes: [],
                dateRange: undefined,
                expressions: [],
                fullSearchText: '',
                fullSearchTextError: false,
              })
            );
          }
          dispatch(setConfigDraftWorkingArea(layout));
          setLayout(layout);
        }}
      />
      <div className="top-panel space-y-1">
        <DragDropDiv ref={getButtonRef} onDragStartT={onDragStart}>
          {isOpenedNewWindow ? (
            <ModalShow className="btn">
              <Text
                text={'Trascina questo box per posizionare la nuova finestra'}
              />
            </ModalShow>
          ) : null}
        </DragDropDiv>
      </div>
      <NewsDetailWrapper />
      <NewsSearchModal />
      <ContextualMenu inHighlightNewsList={false} />
      <Tooltips />
    </div>
  );
};

const ModalShow = styled.div`
  position: absolute;
  top: 50%;
  width: 150px;
  height: 150px;
  left: calc(50% - 75px);
  border: 1px solid lightgray;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: lightgray;
  z-index: 2;
`;
