import { Token } from 'boon-js/lib/types';
import { parse } from 'boon-js';

export function mapBoonExprToType<Type>(
  boonExpr: string,
  handleToken: (token: Token, filters: Type[]) => void
): Type {
  const tokens: Token[] = parse(boonExpr);
  const filters: Type[] = [];

  for (const token of tokens) {
    handleToken(token, filters);
  }

  return filters.pop()!;
}

export function handleTokenAsPredicate(
  token: Token,
  filters: ((s: string) => boolean)[]
) {
  switch (token.name) {
    case 'IDENTIFIER':
      if (token.value) {
        filters.push((s: string) => {
          let v = token.value!.toLowerCase().trim();
          if (v.startsWith('"') && v.endsWith('"')) {
            v = v.substring(1, v.length - 1);
          }
          v = `(^|[^\\w])${v}([^\\w]|$)`;
          return s.toLowerCase().match(v) !== null;
        });
      }
      return;
    case 'OPERATOR':
      switch (token.value) {
        case 'AND': {
          const right = filters.pop();
          const left = filters.pop();
          filters.push((s: string) => left!(s) && right!(s));
          return;
        }
        case 'OR': {
          const right = filters.pop();
          const left = filters.pop();
          filters.push((s: string) => left!(s) || right!(s));
          return;
        }
        case 'NOT': {
          const filter = filters.pop();
          filters.push((s: string) => !filter!(s));
          return;
        }
      }
  }
}
