import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { GenericMultiSelect } from '../GenericMultiSelect';
import { filterChoicesSelector } from '../../redux/filterChoices/selectors';
import { Category } from '@nwa/graphql';
import { useInitialFillCategories } from '../hooks/useInitialFillTheFilterChoices';
import { Add, Gear } from '../../svg';
import { MenuPlacement } from 'react-select';
import { CategoryModalNew } from '../CategoryModalNew';
import { CategoryModalManage } from '../CategoryModalManage';

interface CategorySelectorPressProps {
  onChange: (newValue: any) => void;
  currentValue?: Category;
  showSelectedOption?: boolean;
  removeOption?: string[];
  menuPlacement?: MenuPlacement;
}

export const CategorySelectorPress: FC<CategorySelectorPressProps> = ({
  onChange,
  currentValue,
  showSelectedOption = true,
  removeOption = [],
  menuPlacement,
}) => {
  const { refetch } = useInitialFillCategories();

  const [showAddModal, setShowAddModal] = useState(false);
  const [showManageModal, setShowManageModal] = useState(false);

  const { categories } = useSelector(filterChoicesSelector);

  const categoriesMapped = useMemo<any[]>(
    () =>
      categories
        .filter((c) => removeOption.indexOf(c.name) === -1)
        .map((category) => {
          return {
            value: category.id,
            label: category.name,
          };
        }),

    [categories, removeOption]
  );

  const defaultValueIds = useMemo<any[]>(
    () => (currentValue ? [currentValue.id] : []),

    [currentValue]
  );

  return (
    <>
      <GenericMultiSelect
        id="CategorySelectorPress"
        menuPlacement={menuPlacement}
        showSelectedOption={showSelectedOption}
        allowOnlyOneValue={true}
        onMenuOpen={() => refetch()}
        onChange={onChange}
        /*inputs={[
        {
          label: 'Nuova categoria',
          Icon: <Add className="h-6 w-6" />,
          value: '1',
          onClick: (...args: any[]) => alert(args[0]),
        },
      ]}*/
        actions={[
          {
            label: 'Gestisci categorie',
            Icon: <Gear className="h-6 w-6 fill-black" />,
            value: '0',
            onClick: () => setShowManageModal(true),
          },
          {
            label: 'Nuova categoria',
            Icon: <Add className="h-6 w-6 fill-black" />,
            value: '1',
            onClick: () => setShowAddModal(true),
          },
        ]}
        options={categoriesMapped}
        placeholder={'Aggiungi categoria'}
        defaultValueIds={defaultValueIds}
        menuRight={true}
      />
      {showAddModal && <CategoryModalNew setShowAddModal={setShowAddModal} />}
      {showManageModal && (
        <CategoryModalManage setShowManageModal={setShowManageModal} />
      )}
    </>
  );
};
