import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { KeycloakService } from '../services/KeycloakService';

export default (async () => {
  await i18n
    .use(Backend)
    //.use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'it',
      interpolation: {
        escapeValue: false,
      },
      lng: KeycloakService.getLocale(),
    });
})();
