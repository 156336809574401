import * as React from 'react';
import { Text } from '../Text';
import { utilsSelector } from '../../redux/utils/selectors';
import { useSelector } from 'react-redux';

interface ModalProps {
  children: any;
  title: string;
  showCloseButton: boolean;
  onClickCloseButton?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  footer?: any;
  width?: number;
  maxHeight?: string;
}

export default function Modal({
  children,
  title,
  showCloseButton,
  onClickCloseButton,
  footer,
  width,
  maxHeight,
}: ModalProps) {
  const { isMobile } = useSelector(utilsSelector);
  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
        style={{ zIndex: 300 }}
        hidden
      >
        <div
          className={`flex ${
            isMobile ? 'w-full h-full' : !width ? 'max-w-3xl' : ''
          }`}
          style={!isMobile && width ? { width: width + '%' } : {}}
        >
          {/*content*/}
          <div
            className={`border-0 rounded-lg shadow-lg ${
              isMobile ? 'absolute min-h-full' : 'relative'
            } flex flex-col w-full bg-white outline-none focus:outline-none`}
          >
            {/*header*/}
            <div className="flex items-start justify-between p-4 border-b border-solid border-slate-200 rounded-t">
              <Text
                text={title}
                className="pt-1 pr-2 font-semibold"
                style={{
                  fontSize: '1.25rem',
                  lineHeight: '1.75rem',
                }}
              />
              {showCloseButton && (
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={(e) =>
                    onClickCloseButton ? onClickCloseButton(e) : undefined
                  }
                >
                  <span className="bg-transparent text-black opacity-50 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              )}
            </div>
            {/*body*/}
            {maxHeight && (
              <div
                className="relative p-6 flex-auto overflow-x-scroll"
                style={{ maxHeight: maxHeight }}
              >
                <span className="my-4 text-slate-500 leading-relaxed items-center">
                  {children}
                </span>
              </div>
            )}
            {!maxHeight && (
              <div className="relative p-6 flex-auto">
                <span className="my-4 text-slate-500 leading-relaxed items-center">
                  {children}
                </span>
              </div>
            )}
            {footer && (
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                {footer}
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="opacity-25 fixed inset-0 bg-black"
        style={{ zIndex: 290 }}
      ></div>
    </>
  );
}
